import axios from 'axios'
import Cookies from 'js-cookie';


let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

instance.interceptors.request.use(function (config) {
    const loggedCookie = Cookies.get('auth')
    if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
    	instance.defaults.headers.common['Authorization'] = `Bearer ${loggedCookie}`;
        config.headers.common['Authorization'] = `Bearer ${loggedCookie}`;	
    } else {
    	instance.defaults.headers.common['Authorization'] = null;
    }
    return config;
}, (err) => {
    return Promise.reject(err.response)
});

instance.interceptors.response.use(function (config) {
    const loggedCookie = Cookies.get('auth')
    if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
        instance.defaults.headers.common['Authorization'] = `Bearer ${loggedCookie}`; 
    } else {
        instance.defaults.headers.common['Authorization'] = null;
    }
    return config;
}, (err) => {
    return Promise.reject(err.response)
});

export default instance
