import React from 'react';
import {Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';
import styles from '../../styles/signUp.module.css'
import pafla from '../../api/pafla'
import { notification} from 'antd';
import { withRouter } from 'react-router'
import FormButton from './FormButton'


const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
};

const {Text} = Typography;

class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null
        }
    }

    onFinish = (values) => {
        this.setState({formLoading: true})
        pafla.post(`user/forgotpassword`, values)
        .then((response) => {
            if (!response.data.error) {
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }         
            this.setState({formLoading: false})
        })
    }
   
    render() {
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.reg_form}
                initialValues={{agree: false}}
                onFinish={this.onFinish}
            >
                <Form.Item
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        }
                    ]}
                    validateTrigger={"onBlur"}
                    name="email"
                >
                    <Input size="large" placeholder="Email Address"/>
                </Form.Item>

                <Form.Item>
                    <FormButton 
                        label="Update Password"
                        onClickAction={this.buttonClicked}
                        formLoading={this.state.formLoading}
                    />
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(LoginForm);
