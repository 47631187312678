import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel'
import styles from '../styles/event.module.css';
import EventTicketOrderForm from '../components/auth/event/placeOrder';
import { Helmet } from "react-helmet"
import OrderTotals from '../components/auth/event/OrderTotals'
import EventTicketPreview from '../components/auth/event/EventTicketPreview'
import pafla from '../api/pafla'
import { Collapse } from 'antd';
import Cookies from 'js-cookie';
import { IMAGE_URL } from '../helpers/constants';

const { Panel } = Collapse;

const MemberSignup = (props) => {

    const [ticket, setticket] = useState(null)

    useEffect(() => {

        if (!ticket) {
            getticket(props)
        }
    });

    const getticket = async (props) => {
        const { ticketId } = props.match.params
        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') { 
            if (ticketId) {
                await pafla.get(`user/tickets/${ticketId}`)
                    .then((response) => { 
                        setticket(response.data.data[0])
                    })
            }
        }
    }

    return (
        <div className={styles.sign_up}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Place Order - Pakistan Freelancers Association</title>
            </Helmet>
            <div className={styles.eventTickerHeader}>
                <img src={`${IMAGE_URL}logo.svg`}/>
            </div>
            <div className={styles.eventRegister}>
                <div className={styles.eventRegisterForm}>
                    <Collapse>
                        <Panel 
                            className={styles.ticketPreviewAccordion} 
                            header={(
                                <>
                                    <span>Ticket Details</span>
                                    <span className={styles.ticketPreviewButton}>Click here to preview your ticket</span>
                                </>
                            )}
                        >
                            {ticket && <EventTicketPreview selectedTicket={ticket}/>}
                        </Panel>
                    </Collapse>
                    
                    <EventTicketOrderForm selectedTicket={ticket}/>
                </div>
                <div className={styles.eventDetailsWidget}>
                    <OrderTotals selectedTicket={ticket}/>
                </div>
            </div>
        </div>
    );
};

export default MemberSignup;
