import React from 'react';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';
import pafla from '../../api/pafla'
import Cookies from 'js-cookie';
import { Tag, Row } from 'antd';
import { Comment, Avatar } from 'antd';
import Moment from 'react-moment';
import 'moment-timezone';
import CreateIssueForm from '../../components/auth/CreateIssueForm';


class Issue extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            issues: [],
            loading: false,
            hasMore: true,
        }
    }

    componentDidMount() {

        const { issueId } = this.props.match.params

        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
            pafla.get(`/user/issues/${issueId}`)
            .then((response) => {
                this.setState({issue: response.data.data})
            })
        } else {
            this.props.history.push('/')
        }
    }

    renderActions() {
        return(
            <span key="comment-basic-reply-to">Reply to</span>
        )
    } 


    renderListItem(issue) {
        return(
            <>
                {issue && 
                    <Comment
                        key={issue.id}
                        actions={this.renderActions()}
                        author={issue.user.name}
                        avatar={
                            <Avatar
                              src={issue.user.avatar}
                              alt={issue.user.name}
                            />
                        }
                        content={
                            <p>
                              {issue.description}
                            </p>
                        }
                        datetime={
                            <Moment tz='UTC' fromNow>{issue.created_at}</Moment>
                        }
                    >
                        {issue.status &&
                            <div className='issue-tag'>
                                <Tag>{issue.status.name}</Tag>
                            </div>
                        }
                    </Comment>
                }
            </>
        )        
    }


    render() {
        const { issue, loading } = this.state
        return (
            <Dashboard
                title="Create an Issue - Pakistan Freelancers Association"
                loading={loading}
                completeProfileCheck={true}
            >

                <Row>
                <SectionBody>
                    <h2>Create your issue</h2>
                    <CreateIssueForm/>
                </SectionBody>
                
                </Row>
            </Dashboard>
        );
    }
}

export default Issue;
        