import React from 'react';
import pafla from '../../api/pafla'
import Dashboard from '../../components/dashboard/Main';
import DiscountCard from '../../components/DiscountCard';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import { Row } from 'antd';
import styles from '../../styles/event.module.css'
import { Helmet } from "react-helmet"


class Discounts extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            discounts: null,
            loading: true,
        }
    }

    async componentDidMount() {
        
        await pafla.get('/discounts')
        .then((response) => {
            this.setState({loading: false})
            this.setState({discounts: response.data.data})
        })
    }


    render() {
        const { loading, discounts } = this.state
        return (
            <Dashboard loading={loading}>                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Discounts & Promotions - Pakistan Freelancers Association</title>
                </Helmet>
                <Row>   
                    <SectionBody>                        
                        <SectionHeader>
                            <h2>Discounts & Promotions</h2>
                        </SectionHeader>
                        <div className={`card ${styles.discounts} ${styles.card_items}`}>
                            {discounts && 
                                Object.entries(discounts).map(([key, discount], i) => {
                                    const link = `promotion/${discount.company_name}/${discount.id}`
                                    return (
                                        <DiscountCard
                                            classes={styles.discountCard}
                                            key={discount.id}
                                            title={discount.title}
                                            text={`${discount.description}`}
                                            link={link}
                                            image={`${process.env.REACT_APP_STORAGE_URL}${discount.company_logo}`}
                                            bodyClasses={styles.bodyclass}
                                        />
                                    )
                                })
                            }

                        </div>
                    </SectionBody>
                </Row>
            </Dashboard>
        );
    }
}

export default Discounts;
        