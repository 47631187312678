import React from "react";
import { Card } from "antd";
import styles from "../styles/blog.module.css";
import { Avatar, Typography } from "antd";
import Moment from "react-moment";
import "moment-timezone";

const { Paragraph, Text } = Typography;

const BlogAuthor = ({ author, createdAt }) => {
    return (
        <>
            {author && (
                <div className={styles.blogMeta}>
                    <Avatar
                        src={author.avatar}
                        size={{ xs: 40, md: 40, lg: 40, xl: 40, xxl: 40 }}
                    />
                    <div className={styles.blogMetaDetails}>
                        <span className={styles.blogAuthorName}>
                            {author.name}
                        </span>
                        <Text
                            type='p'
                            ellipsis={true}
                            className={styles.blogAuthorTitle}
                        >
                            {author.title}
                        </Text>
                    </div>
                    <div className={styles.blogPublishedDate}>
                        <span>Published</span>
                        <span>
                            <Moment tz='UTC' fromNow>
                                {createdAt}
                            </Moment>
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default BlogAuthor;
