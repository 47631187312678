import React, { createRef } from 'react';
import { Card } from 'antd';
import styles from '../styles/card.module.css';
import { Typography } from 'antd';
import { IMAGE_URL } from '../helpers/constants';
import { Zoom } from 'react-reveal';
import { Markup } from 'interweave';

const { Paragraph } = Typography;

const { Meta } = Card;

class BoardMemberCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ellipsis: true,
		};
		this.scrollDiv = createRef();
	}

	typoClose = () => {
		this.setState({ ellipsis: !this.state.ellipsis });
		if (!this.state.ellipsis) {
			this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	render() {
		const { image, name, bio, linkdln, position } = this.props;
		return (
			<div ref={this.scrollDiv} className={styles.boardMembers}>
				<Zoom center>
					<Card
						bordered={false}
						cover={
							<img
								alt={name}
								src={image}
								placeholderSrc={image}
								effect="black-and-white"
								width={318}
								height={300}
								className={styles.boardMembersImage}
							/>
						}
					>
						<Meta
							title={
								<div className={styles.header}>
									<div>
										<span>{name}</span>
										<span className={styles.memberPosition}>{position ? position : 'Board Member'}</span>
									</div>
									{linkdln && (
										<div>
											<a href={linkdln} target="_blank" rel="noreferrer" className={styles.footer_social_item}>
												<img src={`${IMAGE_URL}linkedin.png`} alt="" />
											</a>
										</div>
									)}
								</div>
							}
							description={
								<div className={styles.body}>
									{bio && (
										<>
											<Paragraph ellipsis={this.state.ellipsis ? { rows: 4 } : false}>
												<Markup content={bio} />
											</Paragraph>
											<span className={styles.typoExpand} onClick={this.typoClose}>
												{this.state.ellipsis ? 'More' : 'Less'}
											</span>
										</>
									)}
								</div>
							}
						/>
					</Card>
				</Zoom>
			</div>
		);
	}
}

export default BoardMemberCard;
