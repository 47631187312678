import React from 'react';
import {Form, Input, Typography, Select} from 'antd';
import {Link} from 'react-router-dom';
import styles from '../../styles/issues.module.css'
import {passwordRule} from '../../helpers/rules';
import pafla from '../../api/pafla'
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router'
import FormButton from './FormButton'


const layout = {
    wrapperCol: {
        lg: {span: 24, offset: 0},
        xs: {span: 24, offset: 0},
        md: {span: 24, offset: 0},
        sm: {span: 24, offset: 0}
    },
};

const {Text} = Typography;

class RegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null
        }
    }

    onFinish = (values) => {
        this.setState({formLoading: true})
        pafla.post(`user/issues`, values)
        .then((response) => {
            if (!response.data.error) {
                this.props.history.push('/dashboard/issues')
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }            
            this.setState({formLoading: false})
        })
    }
   
    render() {
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.issue_form}
                initialValues={{agree: false}}
                onFinish={this.onFinish}
                layout='vertical'
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your title',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="title"
                    label='Title'
                >
                    <Input size="large" placeholder="Tell us a bit more about your issue"/>
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please select your relevant topic',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="topic"
                    label='Topic'
                >
                    <Select showSearch className={styles.newIssueSelect} placeholder="Select your relevant topic">
                        <Select.Option className={styles.newIssueSelectItem} value="General Query">General Query</Select.Option>
                        <Select.Option className={styles.newIssueSelectItem} value="Banking">Banking</Select.Option>
                        <Select.Option className={styles.newIssueSelectItem} value="Company Formaton & Taxation">Company Formaton & Taxation</Select.Option>
                        <Select.Option className={styles.newIssueSelectItem} value="Event Request">Event Request</Select.Option>
                        <Select.Option className={styles.newIssueSelectItem} value="Discounts">Discounts</Select.Option>
                    </Select>

                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[{required: true, message: 'Please explain your problem further'}]}
                    label='Describe your issue'
                >
                    <Input.TextArea rows={10} placeholder="Tell us about the issue you're facing"/>
                </Form.Item>

                <Form.Item>
                    <FormButton 
                        label="Submit Issue"
                        onClickAction={this.buttonClicked}
                        formLoading={this.state.formLoading}
                        className={styles.new_issue_button}
                    />
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(RegisterForm);
