import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel'
import styles from '../styles/signUp.module.css';
import {Col,  Row, Typography} from 'antd';
import {HOME, IMAGE_URL} from '../helpers/constants';
import {Link} from 'react-router-dom';
import LoginForm from '../components/auth/LoginForm';
import LoginLeft from '../components/auth/LoginLeft';
import { Helmet } from "react-helmet"

const {Title, Text} = Typography;
const MemberSignup = (props) => {

    useEffect(() => {
        ReactPixel.pageView()
        setReferrer()
    });

    const setReferrer = () => {
        const { location } = props

        if (location) {
            if (location.state) {
                if (location.state.referrer) {
                    return location.state.referrer
                }
            }
        }
    }    

    return (
        <div className={styles.sign_up}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login - Pakistan Freelancers Association</title>
            </Helmet>
            <Row style={{width: '100%'}}>
                <LoginLeft/>
                <Col xs={{span: 24}} md={{span: 12}} className={styles.sign_up_left}>
                    <Link to={HOME}>
                        <img src={`${IMAGE_URL}logo.svg`} alt="" className={styles.auth_logo}/>
                    </Link>
                    <div className={styles.sign_up_form}>
                        <Title level={2} className={styles.heading}>LOGIN</Title>
                        <Text>Login to your PAFLA Freelancer Account</Text>
                        <br/>
                        <br/>
                        <Text strong>
                            Not a member?
                            <Link  
                                to={{
                                    pathname: "/register",
                                    state: { referrer: setReferrer() }
                                }}
                                className="text-green"
                            > Sign Up </Link>
                        </Text>
                        <LoginForm/>
                        <Text>
                            Forgot your password?
                            <Link to={'/forgot-password'} className="text-green"> Click here to reset </Link>
                        </Text>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default MemberSignup;
