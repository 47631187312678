import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';
import EnrollCourseForm from '../../components/dashboard/EnrollCourseForm';

const MemberSignup = (props) => {
	return (
		<Dashboard loading={false} completeProfileCheck={false}>
			<Row>
				<SectionBody width="24" heading="Submit your enrollment">
					<EnrollCourseForm />
				</SectionBody>
			</Row>
		</Dashboard>
	);
};

export default MemberSignup;
