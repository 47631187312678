import React from 'react';
import {Card} from 'antd';
import styles from '../styles/home.module.css'
import {Link} from 'react-router-dom';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const BlogCard = ({release}) => {
    return (
        <div className={`${styles.pr_item}`}>
            <div className={styles.pr_item_inner}>
                <div className={styles.cover}>
                    <img src={`${process.env.REACT_APP_STORAGE_URL}${release.featured_image}`} alt={release.name}/>
                </div>
                <div className={styles.pr_details}>
                    <h5>Press Release</h5>
                    <Paragraph ellipsis={{ rows: 1 }}>{release.title}</Paragraph> 
                    <Paragraph className={styles.Prtext} ellipsis={{ rows: 2 }}>{release.excerpt}</Paragraph>             
                    <Link to={`press-release/${release.slug}/${release.id}`}>Read More</Link>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
