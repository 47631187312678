import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import Layout from '../components/Layout';
import { Zoom } from 'react-reveal';
import pafla from '../api/pafla';
import PublicationCard from '../components/PublicationCard';
import { Col, Row } from 'antd';
import styles from '../styles/blog.module.css';
import { Helmet } from 'react-helmet';

class Publications extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			publications: null,
			imageURL: null,
		};
	}

	async componentDidMount() {
		await pafla.get('/publications').then((response) => {
			this.setState({ publications: response.data.data });
		});
	}

	render() {
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Publications - Pakistan Freelancers Association</title>
				</Helmet>
				<section id="pressrelease">
					<Row justify="space-between">
						<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
							<Zoom left>
								<div className={styles.card_items}>
									{this.state.publications &&
										Object.entries(this.state.publications).map(([key, publication], i) => {
											return <PublicationCard key={publication.id} publication={publication} />;
										})}
								</div>
							</Zoom>
						</Col>
					</Row>
				</section>
			</Layout>
		);
	}
}

export default Publications;
