import React, { useState } from "react";
import styles from "../../styles/dashboard.module.css";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import pafla from "../../api/pafla";

const SectionHeader = (props) => {
    const [loading, setLoading] = useState(false);
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 14, color: "#fc8900" }} spin />
    );

    const generateCertificate = () => {
        setLoading(true);

        pafla
            .post(`user/certificate/generate`)
            .then((response) => {
                if (!response.data.error) {
                    notification.success({
                        message: 'Success',
                        description: response.data.message                     
                    });

                    if (response.data.data.certificate_url) {
                        window.open(response.data.data.certificate_url, "_blank", "noreferrer");
                    }
                } else {
                    notification.error({
                        message: "Error",
                        description: response.data.message,
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.membershipStatus}>
            <div className={styles.membershipDetail}>
                <p className={styles.heading}>Membership ID</p>
                <p className={styles.detail}>{props.data.member_id}</p>
            </div>
            <div className={styles.membershipDetail}>
                <p className={styles.heading}>Status</p>
                <p className={styles.detail}>{props.data.membership_status}</p>
            </div>
            <div className={styles.membershipDetail}>
                <p className={styles.heading}>Package</p>
                <p className={styles.detail}>{props.data.membership_package}</p>
            </div>
            <div className={styles.membershipDetail}>
                <p className={styles.heading}>Billing</p>
                <p className={styles.detail}>{props.data.membership_billing}</p>
            </div>
            <div className={styles.membershipDetail}>
                <p className={styles.heading}>Membership Certificate</p>
                <p
                    className={`${styles.detail} ${styles.headline}`}
                    onClick={generateCertificate}
                >
                    Download your certificate
                    {loading && (
                        <Spin
                            indicator={antIcon}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </p>
            </div>
        </div>
    );
};

export default SectionHeader;
