import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

if (process.env.REACT_APP_GA_CODE) {
	ReactGA.initialize(process.env.REACT_APP_GA_CODE);
}

if (process.env.REACT_APP_PIXEL_CODE) {
	const options = {
	  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	  debug: false, // enable logs
	};
	ReactPixel.init(process.env.REACT_APP_PIXEL_CODE, {}, options)
}


ReactDOM.render(
	<Router />,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


