export const nav = [
	{
		id: '1',
		link: '#overview',
		name: 'Overview'
	},
	{
		id: '1',
		link: '#speakers',
		name: 'Speakers'
	},
	{
		id: '1',
		link: '#schedule',
		name: 'Schedule'
	},
	{
		id: '1',
		link: '#packages',
		name: 'Packages'
	},
	{
		id: '1',
		link: '#sponsors',
		name: 'Partners'
	},
	{
		id: '1',
		link: '#faqs',
		name: 'FAQs'
	}
]


export const agendaDayOne = [
	{
		startTime: '9:00 AM',
		endTime: '10:15 AM',
		topicTitle: 'Networking and stall visits',
	},
	{
		startTime: '10:15 AM',
		endTime: '10:20 AM',
		topicTitle: 'Tilawat Quran-e-Pak',
	},
	{
		startTime: '10:20 AM ',
		endTime: '10:30 AM',
		topicTitle: 'Welcome address by PAFLA Founders',
	},
	{
		startTime: '10:30 AM ',
		endTime: '10:40 AM',
		topicTitle: 'Keynote Speech by Imran Ali Dina',
	},
	{
		startTime: '10:40 AM ',
		endTime: '10:50 AM',
		topicTitle: 'Joining by Chief Guest',
	},
	{
		startTime: '10:50 AM',
		endTime: '11:00 AM',
		topicTitle: 'National Anthem',
	},
	{
		startTime: '11:00 AM',
		endTime: '11:05 AM',
		topicTitle: 'Tilawat e Quran e Pak',
	},
	{
		startTime: '11:05 AM ',
		endTime: '11:10 AM',
		topicTitle: 'Welcome Address',
	},
	{
		startTime: '11:10 AM',
		endTime: '11:15 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:15 AM',
		endTime: '11:20 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:20 AM',
		endTime: '11:25 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:20 AM',
		endTime: '11:25 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:25 AM',
		endTime: '11:30 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:30 AM',
		endTime: '11:45 AM',
		topicTitle: 'Keynote Speech',
	},
	{
		startTime: '11:45 AM',
		endTime: '11:50 AM',
		topicTitle: 'Group Photo',
	},
	{
		startTime: '11:50 AM',
		endTime: '12:20 AM',
		topicTitle: 'Supporting the Financial Lifecycle of a Contingent Workforce ',
	},
	{
		startTime: '12:20 PM',
		endTime: '12:50 PM',
		topicTitle: 'The Legal, Policy and Regulatory Future of the Gig Economy',
	},
	{
		startTime: '01:00 PM',
		endTime: '02:00 PM',
		topicTitle: 'Lunch Break',
	},
	{
		startTime: '02:00 PM',
		endTime: '02:30 PM',
		topicTitle: 'A Unique Digital Product Proposition for Freelancers',
	},
	{
		startTime: '02:30 PM',
		endTime: '02:50 PM',
		topicTitle: 'Freelancer today, Founder tomorrow',
	},
	{
		startTime: '02:50 PM',
		endTime: '03:10 PM',
		topicTitle: 'Freelancers’ Perspective to Leading in a New World of Work ',
	},
	{
		startTime: '03:10 PM',
		endTime: '03:30 PM',
		topicTitle: 'Reinventing the Talent Supply Chain for Online-Enabled Work ',
	},
	{
		startTime: '03:10 PM',
		endTime: '03:25 PM',
		topicTitle: 'Networking break',
	},
	{
		startTime: '03:25 PM',
		endTime: '03:45 PM',
		topicTitle: 'Optimizing Talent Management for the Networked Age ',
	},
	{
		startTime: '03:45 PM',
		endTime: '04:05 PM',
		topicTitle: 'How freelancing enables women to build their careers from home',
	},
	{
		startTime: '04:05 PM',
		endTime: '04:25 PM',
		topicTitle: 'A Safety-net for the Independent Workforce',
	},
	{
		startTime: '04:25 PM',
		endTime: '05:00 PM',
		topicTitle: 'Closing Ceremoney',
	},
]

export const agendaDayTwo = [
	{
		startTime: '9:00 AM',
		endTime: '10:15 AM',
		topicTitle: 'Networking and stall visits',
	},
	{
		startTime: '10:15 AM',
		endTime: '10:20 AM',
		topicTitle: 'Tilawat Quran-e-Pak',
	},
	{
		startTime: '10:20 AM ',
		endTime: '10:30 AM',
		topicTitle: 'Welcome address',
	},
	{
		startTime: '10:30 AM ',
		endTime: '10:40 AM',
		topicTitle: 'Keynote speech',
	},
	{
		startTime: '10:40 AM ',
		endTime: '10:50 AM',
		topicTitle: 'Keynote speech',
	},
	{
		startTime: '10:50 AM',
		endTime: '11:00 AM',
		topicTitle: 'Keynote speech',
	},
	{
		startTime: '11:00 AM',
		endTime: '11:05 AM',
		topicTitle: 'Tilawat e Quran e Pak',
	},
	{
		startTime: '11:05 AM ',
		endTime: '11:10 AM',
		topicTitle: 'Welcome Address',
	},
	{
		startTime: '11:20 AM',
		endTime: '11:40 AM',
		topicTitle: 'The Future of Global E-Commerce',
	},
	{
		startTime: '11:40 PM',
		endTime: '12:00 PM',
		topicTitle: 'Choosing the Right Freelancing Platform',
	},
	{
		startTime: '12:00 PM',
		endTime: '12:15 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '12:15 PM',
		endTime: '12:30 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '12:30 PM',
		endTime: '12:45 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '12:45 PM',
		endTime: '01:00 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '01:00 PM',
		endTime: '02:00 PM',
		topicTitle: 'Networking Break',
	},
	{
		startTime: '02:00 PM',
		endTime: '02:20 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '02:20 PM',
		endTime: '02:40 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '02:40 PM',
		endTime: '03:00 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '03:00 PM',
		endTime: '03:20 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '03:20 PM',
		endTime: '03:40 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '03:40 PM',
		endTime: '04:00 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '04:40 PM',
		endTime: '04:25 PM',
		topicTitle: 'Individual Talk',
	},
	{
		startTime: '04:25 PM',
		endTime: '05:00 PM',
		topicTitle: 'Closing Ceremoney',
	},
]

export const workshopAgendaDayOne = [
	{
		startTime: '9:00 AM ',
		endTime: '10:45 AM',
		topicTitle: 'Coming Soon',
	},
]

export const workshopAgendaDayTwo = [
	{
		startTime: '09:00am',
		endTime: '10:30am',
		topicTitle: 'Effective Time Management Techniques for Freelancers',
	},
	{
		startTime: '09:00am',
		endTime: '10:30am',
		topicTitle: 'Effectively writing meaningful content for publications',
	},
	{
		startTime: '09:00am',
		endTime: '10:30am',
		topicTitle: 'Effectively writing meaningful content for publications',
	},
	{
		startTime: '10:30am',
		endTime: '12:00pm',
		topicTitle: 'Setup high performance cloud hosting for clients with LiteSpeed & CyberPanel',
	},
	{
		startTime: '10:30am',
		endTime: '12:00pm',
		topicTitle: 'Run Google Ad Campaigns that Convert',
	},
	{
		startTime: '10:30am',
		endTime: '12:00pm',
		topicTitle: 'Effectively writing meaningful content for publications',
	},
	{
		startTime: '12:00pm',
		endTime: '01:30pm',
		topicTitle: 'Rewriting your brand story',
	},
	{
		startTime: '12:00pm',
		endTime: '01:30pm',
		topicTitle: 'Front-end Development and Freelancing',
	},
	{
		startTime: '12:00pm',
		endTime: '01:30pm',
		topicTitle: 'The Dos & Donts of Creative Design',
	},
	{
		startTime: '02:30pm',
		endTime: '04:00pm',
		topicTitle: 'Lead Generation',
	},
	{
		startTime: '02:30pm',
		endTime: '04:00pm',
		topicTitle: 'Setup & run an eBay Store',
	},
	{
		startTime: '04:00pm',
		endTime: '05:30pm',
		topicTitle: 'The Future of SEO',
	},
	{
		startTime: '04:00pm',
		endTime: '05:30pm',
		topicTitle: 'How to start business with no big Investment',
	},
	{
		startTime: '04:00pm',
		endTime: '05:30pm',
		topicTitle: 'Cultural Intelligence (CQ) for Freelancers ',
	},
]

export const packages = [
	{
		name: 'Individual',
		memberPrice: 4500,
		memberPriceDiscounted: 1500,
		discountText: '* Exclusive Discount ending soon',
		buttonText: 'Registrations Closed',
		buttonLink: '#',
		description: "Includes access to two days of talks across the conference day and a lunch",
		features: [
			{
				name: 'Full conference Access'
			},
			{
				name: 'High Tea & Lunch'
			},
			{
				name: 'Conference Swags'
			},
			{
				name: '1 on 1 Networking'
			}
		]
	},
	{
		name: 'Corporate',
		memberPrice: 5000,
		buttonText: 'Registrations Closed',
		buttonLink: '#',
		description: "Includes access to two days of talks across the conference day and a lunch",
		features: [
			{
				name: 'Full conference Access'
			},
			{
				name: 'High Tea & Lunch'
			},
			{
				name: 'Conference Swags'
			},
			{
				name: '1 on 1 Networking'
			}
		]
	},
	{
		name: 'Enterprise',
		buttonText: 'Registrations Closed',
		buttonLink: '#',
		buttonType: 'link',
		description: "Includes access to two days of talks across the conference day and a lunch",
		features: [
			{
				name: 'Dedicated Table'
			},
			{
				name: 'Affiliate Branding & Promotions'
			},
			{
				name: 'Exhibition Stalls'
			},
			{
				name: 'Speakers Lounge'
			}
		]
	},
]

export const faqs = [
	{
		id: 1,
		question: 'What is Freelance Fest 2022',
		answer: 'FreelanceFest is the largest conference where the world’s 4th fastest growing gig-economy gathers to connect with industry leaders and mentors'
	},
	{
		id: 2,
		question: 'Who can attend the conference?',
		answer: 'The conference is for anyone who considers themselves to be an individual creator, future leader, or risk-taker. Whether you’re interested in learning more about the gig-economy ecosystem in Pakistan, you are a freelancer, or have your own startup, you’re welcome to attend!'
	},
	{
		id: 3,
		question: 'How can I apply to attend the conference?',
		answer: 'You can attend the conference by buying the tickets from above.'
	},
	{
		id: 4,
		question: 'Do I need a pass to attend?',
		answer: 'Yes, you need to buy a ticket beforehand to attend the conference.'
	}
]

export const cities = [
	{ title: 'Karachi' },
	{ title: 'Lahore' },
	{ title: 'Faisalabad' },
	{ title: 'Rawalpindi' },
	{ title: 'Gujranwala' },
	{ title: 'Peshawar' },
	{ title: 'Multan' },
	{ title: 'Saidu Sharif' },
	{ title: 'Hyderabad City' },
	{ title: 'Islamabad' },
	{ title: 'Quetta' },
	{ title: 'Bahawalpur' },
	{ title: 'Sargodha' },
	{ title: 'Sialkot City' },
	{ title: 'Sukkur' },
	{ title: 'Larkana' },
	{ title: 'Chiniot' },
	{ title: 'Shekhupura' },
	{ title: 'Jhang City' },
	{ title: 'Dera Ghazi Khan' },
	{ title: 'Gujrat' },
	{ title: 'Rahimyar Khan' },
	{ title: 'Kasur' },
	{ title: 'Mardan' },
	{ title: 'Mingaora' },
	{ title: 'Nawabshah' },
	{ title: 'Sahiwal' },
	{ title: 'Mirpur Khas' },
	{ title: 'Okara' },
	{ title: 'Mandi Burewala' },
	{ title: 'Jacobabad' },
	{ title: 'Saddiqabad' },
	{ title: 'Kohat' },
	{ title: 'Muridke' },
	{ title: 'Muzaffargarh' },
	{ title: 'Khanpur' },
	{ title: 'Gojra' },
	{ title: 'Mandi Bahauddin' },
	{ title: 'Abbottabad' },
	{ title: 'Turbat' },
	{ title: 'Dadu' },
	{ title: 'Bahawalnagar' },
	{ title: 'Khuzdar' },
	{ title: 'Pakpattan' },
	{ title: 'Tando Allahyar' },
	{ title: 'Ahmadpur East' },
	{ title: 'Vihari' },
	{ title: 'Jaranwala' },
	{ title: 'New Mirpur' },
	{ title: 'Kamalia' },
	{ title: 'Kot Addu' },
	{ title: 'Nowshera' },
	{ title: 'Swabi' },
	{ title: 'Khushab' },
	{ title: 'Dera Ismail Khan' },
	{ title: 'Chaman' },
	{ title: 'Charsadda' },
	{ title: 'Kandhkot' },
	{ title: 'Chishtian' },
	{ title: 'Hasilpur' },
	{ title: 'Attock Khurd' },
	{ title: 'Muzaffarabad' },
	{ title: 'Mianwali' },
	{ title: 'Jalalpur Jattan' },
	{ title: 'Bhakkar' },
	{ title: 'Zhob' },
	{ title: 'Dipalpur' },
	{ title: 'Kharian' },
	{ title: 'Mian Channun' },
	{ title: 'Bhalwal' },
	{ title: 'Jamshoro' },
	{ title: 'Pattoki' },
	{ title: 'Harunabad' },
	{ title: 'Kahror Pakka' },
	{ title: 'Toba Tek Singh' },
	{ title: 'Samundri' },
	{ title: 'Shakargarh' },
	{ title: 'Sambrial' },
	{ title: 'Shujaabad' },
	{ title: 'Hujra Shah Muqim' },
	{ title: 'Kabirwala' },
	{ title: 'Mansehra' },
	{ title: 'Lala Musa' },
	{ title: 'Chunian' },
	{ title: 'Nankana Sahib' },
	{ title: 'Bannu' },
	{ title: 'Pasrur' },
	{ title: 'Timargara' },
	{ title: 'Parachinar' },
	{ title: 'Chenab Nagar' },
	{ title: 'Gwadar' },
	{ title: 'Abdul Hakim' },
	{ title: 'Hassan Abdal' },
	{ title: 'Tank' },
	{ title: 'Hangu' },
	{ title: 'Risalpur Cantonment' },
	{ title: 'Karak' },
	{ title: 'Kundian' },
	{ title: 'Umarkot' },
	{ title: 'Chitral' },
	{ title: 'Dainyor' },
	{ title: 'Kulachi' },
	{ title: 'Kalat' },
	{ title: 'Kotli' },
	{ title: 'Gilgit' },
	{ title: 'Narowal' },
	{ title: 'Khairpur Mir’s' },
	{ title: 'Khanewal' },
	{ title: 'Jhelum' },
	{ title: 'Haripur' },
	{ title: 'Shikarpur' },
	{ title: 'Rawala Kot' },
	{ title: 'Hafizabad' },
	{ title: 'Lodhran' },
	{ title: 'Malakand' },
	{ title: 'Attock City' },
	{ title: 'Batgram' },
	{ title: 'Matiari' },
	{ title: 'Ghotki' },
	{ title: 'Naushahro Firoz' },
	{ title: 'Alpurai' },
	{ title: 'Bagh' },
	{ title: 'Daggar' },
	{ title: 'Leiah' },
	{ title: 'Tando Muhammad Khan' },
	{ title: 'Chakwal' },
	{ title: 'Badin' },
	{ title: 'Lakki' },
	{ title: 'Rajanpur' },
	{ title: 'Dera Allahyar' },
	{ title: 'Shahdad Kot' },
	{ title: 'Pishin' },
	{ title: 'Sanghar' },
	{ title: 'Upper Dir' },
	{ title: 'Thatta' },
	{ title: 'Dera Murad Jamali' },
	{ title: 'Kohlu' },
	{ title: 'Mastung' },
	{ title: 'Dasu' },
	{ title: 'Athmuqam' },
	{ title: 'Loralai' },
	{ title: 'Barkhan' },
	{ title: 'Musa Khel Bazar' },
	{ title: 'Ziarat' },
	{ title: 'Gandava' },
	{ title: 'Sibi' },
	{ title: 'Dera Bugti' },
	{ title: 'Eidgah' },
	{ title: 'Uthal' },
	{ title: 'Khuzdar' },
	{ title: 'Chilas' },
	{ title: 'Panjgur' },
	{ title: 'Gakuch' },
	{ title: 'Qila Saifullah' },
	{ title: 'Kharan' },
	{ title: 'Aliabad' },
	{ title: 'Awaran' },
	{ title: 'Dalbandin' }
]

export const states = [
	{ title: 'Sindh' },
	{ title: 'Balochistan' },
	{ title: 'Punjab' },
	{ title: 'Khyber Pakhtunkhuwa' },
	{ title: 'Gilgit Baltistan' },
	{ title: 'Azad and Jammu Kashmir' }
]


export const speakers = [
	{
		name: 'Dr. Arif Alvi',
		designation: 'President',
		company: 'Islamic Republic of Pakistan',
		image: 'Arif Alvi.JPG',
		type: 'Chief Guest'
	},
	{
		name: 'Muhammad Aurangzeb',
		designation: 'President and CEO',
		company: 'Habib Bank Limited',
		image: 'Muhammad Aurangzed-01.png',
		linkdln: 'https://www.linkedin.com/in/muhammad-aurangzeb-67a51047/',
		type: 'Keynote Speaker'
	},
	{
		name: 'Atif Bajwa',
		designation: 'President and CEO',
		company: 'Bank Alfalah',
		image: 'Atif Bajwa.png',
		linkdln: 'https://www.linkedin.com/in/atif-bajwa-a82303100/',
		type: 'Speaker'
	},
	{
		name: 'Yousaf Hussain',
		designation: 'President and CEO',
		company: 'Faysal Bank Limited',
		image: 'Yousaf Hussain.jpg',
		linkdln: 'https://www.linkedin.com/in/yousafhussain/',
		type: 'Keynote Speaker'
	},
	{
		name: 'Kazi Rahat Ali',
		designation: 'Secretary General',
		company: 'Presidential Initiative for Artificial Intelligence & Computing',
		image: 'Kazi Rahat Ali.png',
		linkdln: 'https://www.linkedin.com/in/kazi-rahat-ali-264785197/',
		type: 'Speaker'
	},
	{
		name: 'Basir Shamsie',
		designation: 'President and CEO',
		company: 'JS Bank',
		image: 'Basir Shamsi.jpg',
		linkdln: 'https://www.linkedin.com/in/basir-shamsie-4b028a3/',
		type: 'Speaker'
	},
	{
		name: 'Ibrahim Amin',
		designation: 'Executive Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'ibrahim-amin-2.jpg',
		linkdln: 'https://www.linkedin.com/in/ibrahim-amin-26760284/',
		type: 'Speaker'
	},
	{
		name: 'Fahad Sheikh',
		designation: 'Senior Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'fahad-sheikh.jpg',
		linkdln: 'https://www.linkedin.com/in/fahaddsheikh/',
		type: 'Speaker'
	},
	{
		name: 'Haroon Q. Raja',
		designation: 'Senior Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'Haroon Q. Raja.jpg',
		linkdln: 'https://www.linkedin.com/in/hqraja/',
		type: 'Speaker'
	},
	{
		name: 'Faraz Anwer',
		designation: 'Partner, Business & Risk Consulting',
		company: 'A. F. Ferguson & Co. PWC',
		image: 'syed-faraz-anwer.jpg',
		linkdln: 'https://www.linkedin.com/in/syed-faraz-anwer-31532aa/',
		type: 'Speaker'
	},
	{
		name: 'Amer Ahmed Hashmi',
		designation: 'Chairman',
		company: 'Special Technology Zones Authority',
		image: 'Amer Hashmi.jpeg',
		linkdln: 'https://www.linkedin.com/in/amer-hashmi-144b49/',
		type: 'Speaker'
	},
	{
		name: 'Imran Ali Dina',
		designation: 'Founder',
		company: 'GFXMentor',
		image: 'Imran Ali Dina.jpg',
		linkdln: 'https://www.linkedin.com/in/iadina/',
		type: 'Speaker'
	},
	{
		name: 'Rehan Allahwala',
		designation: 'Founder & CEO',
		company: 'Insan Party',
		image: 'Rehan Allahwala.jpg',
		linkdln: 'https://www.linkedin.com/in/rehanallahwala/',
		type: 'Speaker'
	},
	{
		name: 'Najeeb Agrawalla',
		designation: 'CEO',
		company: '1Link Pvt Ltd',
		image: 'najeeb-agrawalla.jpg',
		linkdln: 'https://www.linkedin.com/in/najeeb-agrawalla-309249a5/',
		type: 'Speaker'
	},
	{
		name: 'Naeem Zamindar',
		designation: 'Founder and Managing Partner',
		company: 'Neem Exponential',
		image: 'Naeem Zamindar.jpeg',
		linkdln: 'https://www.linkedin.com/in/naeem-zamindar-62b486/',
		type: 'Speaker'
	},
	{
		name: 'Abdul Rahim Ahmed',
		designation: 'Director General & CIO',
		company: 'Special Technology Zones Authority (STZA)',
		image: 'Abdul-Rahim-Ahmad.png',
		linkdln: 'https://www.linkedin.com/in/rahim82/',
		type: 'Speaker'
	},
	{
		name: 'Shaheryar Saeed Khan',
		designation: 'Chief Digital Officer',
		company: 'Faysal Bank Ltd',
		image: 'Shaheryar Saeed Khan.png',
		linkdln: 'https://www.linkedin.com/in/shaheryar-saeed-khan/',
		type: 'Speaker'
	},
	{
		name: 'Javaid Iqbal',
		designation: 'Chief Commercial Officer',
		company: 'Special Technology Zones Authority',
		image: 'Javaid Iqbal.jpg',
		linkdln: 'https://www.linkedin.com/in/jiqbal/',
		type: 'Speaker'
	},
	{
		name: 'Adnan Nasir',
		designation: 'Head of Digital Payments',
		company: 'Bank Alfalah Limited',
		image: 'Adnan Nasir.JPG',
		linkdln: 'https://www.linkedin.com/in/adnan-nasir-0464881/',
		type: 'Speaker'
	},
	{
		name: 'Adnan Ali',
		designation: 'CEO & Director',
		company: 'PayFast',
		image: 'adnan-ali.jpg',
		linkdln: 'https://www.linkedin.com/in/adnan-ali-5934426/',
		type: 'Speaker'
	},
	{
		name: 'Vali Uddin',
		designation: 'Vice Chancellor',
		company: 'Sir Syed University',
		image: 'Prof. Dr. Vali Uddin.jpg',
		linkdln: 'https://www.linkedin.com/in/vali-uddin-0114044a/',
		type: 'Speaker'
	},
	{
		name: 'Arzish Azam',
		designation: 'Founder & CEO',
		company: 'Ejad Labs',
		image: 'Arzish Azam.jpg',
		linkdln: 'https://www.linkedin.com/in/arzishazam/',
		type: 'Speaker'
	},
	{
		name: 'Dua Sukhera',
		designation: 'Chief Executive Officer',
		company: 'The Institute of Entrepreneurship',
		image: 'Dua Sukhera.jpg',
		linkdln: 'https://www.linkedin.com/in/theduasukhera/',
		type: 'Speaker'
	},
	{
		name: 'Asif Lakhani',
		designation: 'Chief Operating Officer',
		company: 'Dellsons Group of Companies',
		image: 'Asif Lakhani.jpg',
		linkdln: 'https://www.linkedin.com/in/asif-lakhani-chrp-52780927/',
		type: 'Speaker'
	},
	{
		name: 'Ahad Khurshid',
		designation: 'CEO',
		company: 'Sahal Verifai',
		image: 'Ahad Khurshid.jpg',
		linkdln: 'https://www.linkedin.com/in/abdul-ahad-55558b197/',
		type: 'Speaker'
	},
	{
		name: 'Hussein Hassanali',
		designation: 'GM IT Audit',
		company: 'Habib Bank Limited',
		image: 'Hussein Hassanali.jpg',
		linkdln: 'https://www.linkedin.com/in/hussein-hassanali/',
		type: 'Speaker'
	},
/*	{
		name: 'Imran H. Shaikh',
		designation: 'Chief Operating Officer',
		company: 'JS Bank',
		image: 'Imran Haleem Sheikh.jpg',
		linkdln: 'https://www.linkedin.com/in/imranhaleemshaikh/',
		type: 'Speaker'
	},*/
]