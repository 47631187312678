import React from "react";
import styles from "../styles/blog.module.css";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import BlogAuthorArchive from "../components/BlogAuthorArchive";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";

const { Paragraph, Title } = Typography;

const BlogCard = ({ image, title, text, link, author, created_at }) => {
    return (
        <div className={`${styles.blog_item}`}>
            <div className={styles.pr_item_inner}>
                <div className={styles.cover}>
                    <Link to={link}>
                        {" "}
                        <img src={image} alt={title} />{" "}
                    </Link>
                </div>
                <div className={styles.pr_details}>
                    <Link to={link} style={{ textDecoration: "none", color: 'inherit' }}>
                        <BlogAuthorArchive
                            author={author}
                            createdAt={created_at}
                        />
                        <Title level={2} ellipsis={{ rows: 1 }} title={title}>
                            {title}
                        </Title>
                        <Paragraph
                            className={styles.Prtext}
                            ellipsis={{ rows: 2 }}
                        >
                            {text}
                        </Paragraph>
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
