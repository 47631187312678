import React, { useState } from 'react';
import styles from '../styles/home.module.css';
import pafla from '../api/pafla';
import { Typography, Modal, Button, Form, Input } from 'antd';
import FormButton from './auth/FormButton';
import { CheckOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const PublicationCard = ({ publication }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [hideForm, setHideForm] = useState(false);

	const onFinish = (values) => {
		setFormLoading(true);
		console.log(values);
		values.publication_id = publication.id;
		pafla
			.post('access-publication', values)
			.then((response) => {
				setFormLoading(false);
				setHideForm(true);
			})
			.catch((error) => {
				setFormLoading(false);
				console.log(error);
			});
	};

	return (
		<div className={`${styles.pr_item}`}>
			<div className={styles.pr_item_inner}>
				<div className={styles.cover}>
					<img style={{ objectFit: 'contain' }} src={`${process.env.REACT_APP_STORAGE_URL}${publication.featured_image}`} alt={publication.name} />
				</div>
				<div className={styles.pr_details}>
					<h5>Publication</h5>
					<Paragraph ellipsis={{ rows: 1 }}>{publication.title}</Paragraph>
					<Paragraph className={styles.Prtext} ellipsis={{ rows: 2 }}>
						{publication.excerpt}
					</Paragraph>
					<Button type="primary" onClick={() => setModalOpen(true)}>
						Download
					</Button>
					<Modal
						title="Fill in your details"
						centered
						open={modalOpen}
						onOk={() => setModalOpen(false)}
						onCancel={() => setModalOpen(false)}
						footer={null}
					>
						{hideForm && (
							<div style={{ textAlign: 'center' }}>
								<CheckOutlined
									style={{ backgroundColor: '#00c087', color: '#fff', borderRadius: '50%', padding: '10px', marginBottom: '15px', fontSize: '22px' }}
								/>
								<h2 style={{ textAlign: 'center' }}>Your report was emailed to you successfully!</h2>
							</div>
						)}
						{!hideForm && (
							<>
								<h3 style={{ textAlign: 'center', fontSize: '20px', maxWidth: '90%', margin: '20px auto 25px auto' }}>
									Fill in your details below to get access to <i>{publication.title}</i> now
								</h3>
								<Form name="publication_download" className={styles.reg_form} initialValues={{ agree: false }} onFinish={onFinish} layout="vertical">
									<Form.Item
										label="Full name"
										rules={[
											{
												required: true,
												message: 'Please enter your full name',
											},
										]}
										validateTrigger={'onBlur'}
										name="name"
									>
										<Input size="large" placeholder="Full Name" autocomplete="name" />
									</Form.Item>
									<Form.Item
										label="Email"
										rules={[
											{
												type: 'email',
												message: 'The input is not valid email',
											},
											{
												required: true,
												message: 'Please input your email',
											},
										]}
										validateTrigger={'onBlur'}
										name="email"
									>
										<Input size="large" placeholder="Email Address" autocomplete="email" />
									</Form.Item>

									<Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input your contact number' }]}>
										<Input size="large" placeholder="Contact Number" autocomplete="tel" />
									</Form.Item>
									<Form.Item
										label="Organization"
										rules={[
											{
												required: true,
												message: 'Please enter your Organization',
											},
										]}
										validateTrigger={'onBlur'}
										name="organization"
									>
										<Input size="large" placeholder="Organization" autocomplete="organization" />
									</Form.Item>
									<Form.Item
										label="Designation"
										rules={[
											{
												required: true,
												message: 'Please enter your Designation',
											},
										]}
										validateTrigger={'onBlur'}
										name="designation"
									>
										<Input size="large" placeholder="Designation" autocomplete="organization-title" />
									</Form.Item>
									<Form.Item>
										<FormButton label="Get my report" formLoading={formLoading} />
									</Form.Item>
								</Form>
							</>
						)}
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default PublicationCard;
