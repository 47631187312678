import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import { Link } from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import RegisterButton from './RegisterButton'
import Bookmark from './Bookmark'

const {Title, Paragraph} = Typography;

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={styles.section}>
            <Row className='contained-row' justify="space-between">
                <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                    <Row>
                        <Col className={styles.desktopHide} xs={{span: 24, offset: 0}} md={{span: 11, offset: 1}} lg={{span: 9, offset: 2}}>
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/logo-black.png`} alt=""/>
                           </div>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <div className={styles.about_text}>
                                <div className={styles.card_item}>
                                    <Slide bottom>
                                        <h1>Overview</h1>
                                    </Slide>
                                    
                                    <Bookmark/>
                                    <Slide bottom>
                                        <Paragraph className="section-text">
                                    With monumental shifts around where and how work is being done and how talent is being engaged, FreelanceFest explores the role technology plays in the sweeping and ongoing change and keeps the ecosystem informed on the trends, market insights and innovations that drive growth in the competitive global landscape. 
                                    <Divider style={{visibility: 'hidden', margin: 15}}/>
                                    The event addresses the transformations around talent, the new operating models that are emerging and the digital technologies that are paving the way for the evolution of work.
                                </Paragraph>
                                    </Slide>
                                    {/*<Fade right>
                                        <RegisterButton/>
                                    </Fade>*/}
                                </div>
                            </div>
                        </Col>
                        <Col className={styles.desktopShow} xs={{span: 24, offset: 0}} md={{span: 11, offset: 1}} lg={{span: 9, offset: 2}}>
                           
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/logo-black.png`} alt=""/>
                           </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default HomeAbout;
