import React from 'react';
import styles from '../styles/subscribe.module.css';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { IMAGE_URL } from '../helpers/constants';
import { passwordRule } from '../helpers/rules';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import pafla from '../api/pafla';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { SIGN_UP } from '../helpers/constants';
import { withRouter } from 'react-router';

const { Title, Text, Paragraph } = Typography;

const layout = {
	wrapperCol: {
		md: { span: 23 },
		xs: { span: 24 },
	},
};

class Subscribe extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formLoading: null,
		};
	}

	onFinish = (values) => {
		this.setState({ formLoading: true });
		pafla.post(`user/register`, values).then((response) => {
			if (!response.data.error) {
				Cookies.set('auth', response.data.data.api_token, { path: '/' });
				this.props.history.push('/');
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
			} else {
				notification.error({
					message: 'Error',
					description: response.data.message,
				});
			}
			this.setState({ formLoading: false });
		});
	};

	render() {
		return (
			<div className={styles.subscribe}>
				<Row>
					<Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }} md={{ span: 16, offset: 4 }} style={{ margin: '0 auto' }}>
						<Title style={{ textAlign: 'center' }} level={2} className={styles.subscribe_title}>
							Join us for an exiciting future!
						</Title>
						<Paragraph className={styles.subscribe_text}>
							Get access to exclusive discounts, valuable resources, and the largest community of freelancers on earth.
						</Paragraph>
					</Col>
					<Col
						xs={{ span: 22, offset: 1 }}
						lg={{ span: 24, offset: 4 }}
						md={{ span: 24, offset: 2 }}
						style={{ margin: '20px auto', display: 'block' }}
					>
						<Button type="primary" className="ant-btn-orange">
							<Link to={SIGN_UP}>Become a member</Link>
						</Button>
						<br />
						<br />
						<Text className={styles.subscribe_sign}>
							{' '}
							Already a member? <Link to={'/login'}>Sign In</Link>
						</Text>
					</Col>
					<Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} style={{ margin: '0 auto' }}>
						<div className={styles.subscribe_footer}>
							<Fade left>
								<img src={`${IMAGE_URL}office.png`} alt="" style={{ width: '55%' }} />
							</Fade>
							<Fade right>
								<img src={`${IMAGE_URL}stuff.png`} alt="" style={{ width: '32%' }} />
							</Fade>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withRouter(Subscribe);
