import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import Dashboard from '../../components/dashboard/Main'
import SectionBody from '../../components/dashboard/SectionBody'
import SectionSidebar from '../../components/dashboard/SectionSidebar'
import ContributeSidebar from './ContributeSidebar'
import ContributeForm from '../../components/dashboard/ContributeForm'

const ContribueAmbassador = (props) => {

    return (
        <Dashboard loading={false} completeProfileCheck={true}>
            <Row>
                <SectionSidebar title="Contribute" width="6">
                    <ContributeSidebar/>
                </SectionSidebar>
                <SectionBody width="18" heading="Contribute as a Unversity Ambassador"> 
                    <ContributeForm type="ambassador"/>
                </SectionBody>
            </Row>
        </Dashboard>
    );
};

export default ContribueAmbassador;
