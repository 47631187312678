import React from 'react';
import styles from '../../styles/dashboard.module.css';
import {Divider} from 'antd';

const SectionHeader = (props) => {

    return (
        <>
            <div className={styles.dashboardSectionHeader}>
                {props.children}
            </div>        
            <Divider/>
        </>
    );
};

export default SectionHeader;
