import React from 'react';
import { Col, Row, Image, Button, Tabs, Tag } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import EventSpeaker from '../../components/EventSpeaker';
import pafla from '../../api/pafla'
import ReactHtmlParser from 'react-html-parser'
import styles from '../../styles/event.module.css'
import { Helmet } from "react-helmet"
import EventRegisterButton from '../../components/EventRegisterButton'
import { CalendarOutlined, PushpinOutlined, ApartmentOutlined, MoneyCollectOutlined, GlobalOutlined, SendOutlined } from '@ant-design/icons';

class Event extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        event: null,
	        loading: true,
	        ticketURL: null
	    }
	}

	async componentDidMount() {
		const { eventId } = this.props.match.params

        await pafla.get(`/events/${eventId}`)
        .then((response) => {
        	this.setState({loading: false})
            this.setState({event: response.data.data})
        })

        await pafla.get('/event/user/ticket-url', {params: {event_id: eventId}})
        .then((response) => {
        	this.setState({loading: false})
        	if (!response.data.error) {
        		this.setState({ticketURL: response.data.data})
        	}
        })
    }

    changeTicketURL = (newURL) => {
    	this.setState({ticketURL: newURL})
    }

    render() {
    	const { event, loading } = this.state
    	const sd = (event && event.start_date) && new Date(event.start_date)
    	const sdf = (event && event.start_date) && new Intl.DateTimeFormat('en', { 
    		year: 'numeric', 
    		month: 'long', 
    		day: '2-digit', 
    		hour: "numeric",
    		minute: "numeric"
    	}).format(sd);            		    	
    	const ed = (event && event.end_date) && new Date(event.end_date);
    	const edf = (event && event.end_date) && new Intl.DateTimeFormat('en', { 
    		year: 'numeric', 
    		month: 'long', 
    		day: '2-digit', 
    		hour: "numeric",
    		minute: "numeric"
    	}).format(ed);
    	const gallery = (event && event.gallery) && JSON.parse(event.gallery)
        return (
    		<Dashboard loading={loading}>
	            <div className={styles.card_items}>
	                {event &&
			            <div className='contained-row event'>    		            	
			            	<Helmet>
			            	    <meta charSet="utf-8" />
			            	    <title>{event.title} - Pakistan Freelancers Association</title>
			            	</Helmet>
			            	<Row gutter={{ xs: 0, md: 24 }}>
			            		<Col xs={{span: 24}} md={{span: 16}}>
			            			<div className={styles.eventData}>
			            				{event.image &&
			            					<img className='featured-image' 
			            						src={`${process.env.REACT_APP_STORAGE_URL}/${event.image}`}
			            						alt={event.title.rendered}
			            					/>
			            				}
			            				<div className={styles.eventContent}>
			            					<h2>{event.title}</h2>
	    		            				{ReactHtmlParser(event.content)}
			            				</div>
			            			</div>
			            			<div className={styles.eventTabs}>
			            				<Tabs defaultActiveKey="1">
			            					{event.speakers && (Object.keys(event.speakers).length > 0 ) && 
	    		            				    <Tabs.TabPane  className={styles.eventTab} tab="Speakers" key="1">
	    		            				    	<div className={styles.eventSpeakers}>
	    		            				    		{event.speakers.map(speaker => {
	    		            				    			return (
	    		            				    			    <EventSpeaker
	    		            				    			        name={speaker.name}
	    		            				    			        designation={speaker.position}
	    		            				    			        company={speaker.company}
	    		            				    			        image={speaker.picture}
	    		            				    			        linkdln={speaker.linkdln}
	    		            				    			    />
	    		            				    			)
	    		            				    		})}
	    		            				    	</div>
	    		            				    </Tabs.TabPane>
			            					}
			            				    {/*
			            				    <Tabs.TabPane  className={styles.eventTab} tab="Agenda" key="2">
			            				     	Agenda
			            				    </Tabs.TabPane>
			            				    <Tabs.TabPane  className={styles.eventTab} tab="FAQ" key="3">
			            				    	FAQ
			            				    </Tabs.TabPane>
			            				    <Tabs.TabPane  className={styles.eventTab} tab="Map" key="4">

			            				    </Tabs.TabPane>*/}
			            				  </Tabs>
			            			</div>
			            			{gallery && (Object.keys(gallery).length > 0 ) && 
			            				<div className={styles.eventGallery}>
			            					<div className={styles.sectionHeader}>
			            						Photos
			            					</div>
			            					<Image.PreviewGroup>
			            						{Object.keys(gallery).map((key, index) => {
			            							return <Image width={250} src={`${process.env.REACT_APP_STORAGE_URL}/${gallery[key]}`} />
			            						})}
			            					</Image.PreviewGroup>
			            				</div>
			            			}    		            			
			            		</Col>
			            		<Col xs={{span: 24}} md={{span: 8}}>
			            			<div className={styles.eventSidebar}>
			            				<div className={styles.sectionHeader}>
			            					Event Details
			            				</div>
			            				{event.start_date &&
			            					<div className={styles.eventDetail}>
			            						<div className={styles.icon}>
			            							<CalendarOutlined />
			            						</div>
			            						<div className={styles.data}>
			            							<div className={styles.header}>
			            								Start Date
			            							</div>
			            							<div className={styles.detail}>
			            								{`${sdf}`}
			            							</div>
			            						</div>
			            					</div>
			            				}
			            				{event.end_date &&
			            					<div className={styles.eventDetail}>
			            						<div className={styles.icon}>
			            							<CalendarOutlined />
			            						</div>
			            						<div className={styles.data}>
			            							<div className={styles.header}>
			            								End Date
			            							</div>
			            							<div className={styles.detail}>
			            								{`${edf}`}
			            							</div>
			            						</div>
			            					</div>
			            				}
			            				{event.venue &&
			            					<div className={styles.eventDetail}>
			            						<div className={styles.icon}>
			            							<PushpinOutlined />
			            						</div>
			            						<div className={styles.data}>
			            							<div className={styles.header}>
			            								Venue
			            							</div>
			            							<div className={styles.detail}>
			            								{`${event.venue}`}
			            							</div>
			            						</div>
			            					</div>
			            				}
			            				{event.city &&
			            					<div className={styles.eventDetail}>
			            						<div className={styles.icon}>
			            							<GlobalOutlined />
			            						</div>
			            						<div className={styles.data}>
			            							<div className={styles.header}>
			            								City
			            							</div>
			            							<div className={styles.detail}>
			            								{`${event.city}`}
			            							</div>
			            						</div>
			            					</div>
			            				}
			            				{event.organizer &&
			            					<div className={styles.eventDetail}>
			            						<div className={styles.icon}>
			            							<ApartmentOutlined />
			            						</div>
			            						<div className={styles.data}>
			            							<div className={styles.header}>
			            								Organizer
			            							</div>
			            							<div className={styles.detail}>
			            								{`${event.organizer}`}
			            							</div>
			            						</div>
			            					</div>
			            				}
		            					{/*<div className={styles.eventDetail}>
		            						<div className={styles.icon}>
		            							<SendOutlined />
		            						</div>
		            						<div className={styles.data}>
		            							<div className={styles.header}>
		            								Entrance
		            							</div>
		            							<div className={styles.detail}>
		            								<Tag color="#e42f">Members Only</Tag>
		            							</div>
		            						</div>
		            					</div>  */} 
		            					{event.free &&        		            				
	    		            				<div className={styles.eventDetail}>
	    		            					<div className={styles.icon}>
	    		            						<MoneyCollectOutlined />
	    		            					</div>
	    		            					<div className={styles.data}>
	    		            						<div className={styles.header}>
	    		            							Price
	    		            						</div>
	    		            						<div className={styles.detail}>
	    		            							Free
	    		            						</div>
	    		            					</div>
	    		            				</div>
			            				}
			            				{!event.registrations_closed &&
			            					<div className={styles.eventDetail}>
	    		            					<EventRegisterButton 
	    		            						event={event} 
	    		            						changeTicketURL={this.changeTicketURL} 
	    		            						ticketURL={this.state.ticketURL} 
	    		            						eventLoading={loading}
	    		            					/>
	    		            				</div>
			            				}            		            				
			            			</div>
			            			{event.sponsors && (Object.keys(event.sponsors).length > 0 ) && 
	    		            			<div className={styles.eventSidebar}> 
	    		            				<div className={styles.sectionHeader}>
	    		            					Sponsors
	    		            				</div>
	    		            				<div className={styles.eventDetail}>
	    		            					<Image.PreviewGroup>
		    		            					{event.sponsors.map(sponsor => {
		    		            						return <Image width={150} src={`${process.env.REACT_APP_STORAGE_URL}/${sponsor.logo}`} />
		    		            					})}
	    		            					</Image.PreviewGroup>
	    		            				</div>
	    		            			</div>
			            			}
			            		</Col>
			            	</Row>   	
			            </div>
	                }
	            </div>
            </Dashboard>
        );
    }
}

export default Event;
        