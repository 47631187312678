import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel'
import CompleteProfileForm from '../../components/auth/CompleteProfileForm';
import pafla from '../../api/pafla'
import Dashboard from '../../components/dashboard/Main'
import SectionBody from '../../components/dashboard/SectionBody'
import SectionHeader from '../../components/dashboard/SectionHeader'
import { Row } from 'antd';

const MemberSignup = (props) => {
    return (
        <Dashboard
            loading={false}
        >
            <Row>
                <SectionBody>                    
                    <SectionHeader>
                        <h2>Your Profile</h2>
                    </SectionHeader>
                    <CompleteProfileForm/>
                </SectionBody>
            </Row>
        </Dashboard>
    );
};

export default MemberSignup;
