import React from 'react';
import {Card, Typography} from 'antd';
import styles from '../styles/card.module.css'
import {Link} from 'react-router-dom';
import { CalendarOutlined, PushpinOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { Markup } from 'interweave';

const { Paragraph } = Typography;

const Event = ({image,title,text,link, date, classes, bodyClasses, venue}) => {
    return (
        <Card
            className={`${styles.discount_card} ${classes}`}
            bordered={false}
            cover={<img alt={title} src={image} />}
        >
            <div className={bodyClasses}>
                <h3 title={title}>{title}</h3>
                <Paragraph ellipsis={{ rows: 3 }}><Markup content={text}/></Paragraph> 
                <Link to={link} className={styles.event_link}>View Details</Link>
            </div>
        </Card>
    );
};

export default Event;
