import React, { useEffect, useState } from 'react';
import styles from '../../styles/dashboard.module.css';
import Dashboard from '../../components/dashboard/Main'
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import Loader from '../../components/Loader'
import MembershipOrderForm from '../../components/dashboard/MembershipOrderForm';
import { Helmet } from "react-helmet"
import OrderTotals from '../../components/dashboard/MembershipTotals'
import pafla from '../../api/pafla'
import Cookies from 'js-cookie';
import { Row } from 'antd';

const MemberSignup = (props) => {

    const [pack, setPack] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!pack) {
            getpack(props)
        }
    });

    const getpack = async (props) => {
        const { packageName } = props.match.params
        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') { 
            if (packageName) {
                await pafla.get(`packages/${packageName}`)
                    .then((response) => { 
                        setPack(response.data.data)
                        setLoading(false)
                    })
            }
        }
    }

    return (
        <Dashboard
            loading={loading}
            completeProfileCheck={true}
        >
            <SectionHeader><h2>Purchase</h2></SectionHeader>
            <Row>
                <SectionBody width="24">            
                        {pack &&
                            <div className={styles.membershipRegister}>
                                <div className={styles.eventRegisterForm}>                    
                                    <MembershipOrderForm selectedPackage={pack}/>
                                </div>
                                <div className={styles.eventDetailsWidget}>
                                    <OrderTotals selectedPackage={pack}/>
                                </div>
                            </div>
                        }
                </SectionBody>
            </Row>
        </Dashboard>
    );
};

export default MemberSignup;
