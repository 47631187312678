import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import RestrictedRoute from './RestrictedRouter';
import Home from './pages/Home';
import Loans from './pages/Loans';
import Insurance from './pages/Insurance';
import MemberSignup from './pages/MemberSignup';
import MemberLogin from './pages/MemberLogin';
import CompleteProfile from './pages/dashboard/CompleteProfile';
import EditProfile from './pages/dashboard/CompleteProfile';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import Help from './pages/Help';
import OurVoice from './pages/OurVoice';
import AboutUs from './pages/AboutUs';
import Events from './pages/Events';
import Profile from './pages/Profile';
import MembershipJoin from './pages/MembershipJoin';
import MemberUnsubscribe from './pages/MemberUnsubscribe';
import MemberProfile from './pages/dashboard/MemberProfile';
import Event from './pages/Event';
import Logout from './pages/Logout';
import history from './history';
import PressReleases from './pages/PressReleases';
import PressRelease from './pages/PressRelease';
import MemberForgotPassword from './pages/MemberForgotPassword';
import MemberResetPassword from './pages/MemberResetPassword';
import MediaMentions from './pages/MediaMentions';
import Board from './pages/Board';
import Issues from './pages/dashboard/Issues';
import MyOrders from './pages/dashboard/MyOrders';
import Membership from './pages/dashboard/Membership';
import BuyMembership from './pages/dashboard/BuyMembership';
import Issue from './pages/dashboard/Issue';
import NewIssue from './pages/dashboard/NewIssue';
import FreelanceFest from './pages/FreelanceFest';
import FreelanceFestIslamabad from './pages/FreelanceFestIslamabad';
import EventRegister from './pages/EventRegister';
import EventTicketOrder from './pages/EventTicketOrder';
import OrderThankyou from './pages/dashboard/OrderThankyou';
import OrderOneBill from './pages/dashboard/OrderOneBill';
import Peers from './pages/dashboard/Peers';
import DashboardEvents from './pages/dashboard/DashboardEvents';
import DashboardEvent from './pages/dashboard/DashboardEvent';
import ComingSoon from './pages/dashboard/ComingSoon';
import FreelanceFestGilgit from './pages/FreelanceFestGilgit';
import ReactPixel from 'react-facebook-pixel';
import ContributeAmbassador from './pages/dashboard/ContributeAmbassador';
import ContributeChapter from './pages/dashboard/ContributeChapter';
import ContributeMeetups from './pages/dashboard/ContributeMeetups';
import ContributeSpeaker from './pages/dashboard/ContributeSpeaker';
import Discounts from './pages/dashboard/Discounts';
import Discount from './pages/dashboard/Discount';
import LandingGoogleCareerCertificates from './pages/LandingGoogleCareerCertificates';
import LandingGoogleSoftSkills from './pages/LandingGoogleSoftSkills';
import GoogleCareerCertificates from './pages/dashboard/GoogleCareerCertificates';
import EnrollCourse from './pages/dashboard/EnrollCourse';
import EnrollThankyou from './pages/dashboard/EnrollThankyou';
import DashboardCourse from './pages/dashboard/DashboardCourse';
import CourseCertify from './pages/dashboard/CourseCertify';
import StartQuiz from './pages/dashboard/StartQuiz';
import QuizResult from './pages/dashboard/QuizResult';
import Publications from './pages/Publications';

import ReactGA from 'react-ga';
import {
	ABOUT,
	HELP,
	HOME,
	LOANS,
	SIGN_UP,
	LOG_IN,
	COMPLETE_PROFILE,
	EDIT_PROFILE,
	BLOGS,
	BLOG,
	EVENT,
	EVENTS,
	OUR_VOICE,
	PROFILE,
	LOGOUT,
	PRESSRELEASES,
	PRESSRELEASE,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	UNSUBSCRIBE,
	ISSUES,
	ORDERS,
	ISSUE,
	NEW_ISSUE,
	MEDIA_MENTIONS,
	FREELANCE_FEST,
	FREELANCE_FEST_ISLAMABAD,
	FREELANCE_FEST_ISLAMABAD_TWO,
	FREELANCE_FEST_GILGIT,
	BOARD,
	EVENT_REGISTER,
	TICKET_ORDER,
	ORDER_THANKYOU,
	ORDER_ONEBILL,
	MEMBER_PROFILE,
	MEMBERSHIP,
	MEMBERSHIP_BUY,
	JOBS,
	PEERS,
	MENTORSHIP,
	TRAINING,
	TESTS,
	FILINGS,
	INSURANCE,
	CHAPTER_CONTRIBUTOR,
	UNIVERSITY_AMBASSADOR,
	HOST_YOUR_MEETUP,
	APPLY_AS_A_SPEAKER,
	DASHBOARD_EVENTS,
	DASHBOARD_EVENT,
	MEMBERSHIP_JOIN,
	PROMOTIONS,
	PROMOTION,
	DASHBOARD_COURSE,
	GCC_LANDING,
	GCC_DASHBOARD_LANDING,
	GCC_ENROLL,
	GCC_THANKYOU,
	SOFTSKILLS_LANDING,
	COURSE_CERTIFY,
	START_QUIZ,
	QUIZ_RESULT,
	PUBLICATIONS,
} from './helpers/constants';

function App() {
	useEffect(() => {
		ReactPixel.pageView();
		ReactGA.pageview(window.location.pathname);
	});
	return (
		<div className="App">
			<Router history={history}>
				<Switch>
					<Route exact strict path={HOME} component={Home} />
					<Route path={LOANS} component={Loans} />
					<Route path={HELP} component={Help} />
					<Route path={ABOUT} component={AboutUs} />
					<Route path={SIGN_UP} component={MemberSignup} />
					<Route path={LOG_IN} component={MemberLogin} />
					<Route path={FORGOT_PASSWORD} component={MemberForgotPassword} />
					<Route path={RESET_PASSWORD} component={MemberResetPassword} />
					<Route path={UNSUBSCRIBE} component={MemberUnsubscribe} />
					<Route exact strict path={BLOGS} component={Blogs} />
					<Route exact strict path={BLOG} component={Blog} />
					<Route path={EVENTS} component={Events} />
					<Route path={EVENT} component={Event} />
					<Route path={PRESSRELEASES} component={PressReleases} />
					<Route path={PRESSRELEASE} component={PressRelease} />
					<Route path={PUBLICATIONS} component={Publications} />
					<Route path={MEDIA_MENTIONS} component={MediaMentions} />
					<Route path={BOARD} component={Board} />
					<Route path={MEMBERSHIP_JOIN} component={MembershipJoin} />
					<Route path={GCC_LANDING} component={LandingGoogleCareerCertificates} />
					<Route path={SOFTSKILLS_LANDING} component={LandingGoogleSoftSkills} />
					<Route path={FREELANCE_FEST} component={FreelanceFest} />
					<Route path={FREELANCE_FEST_ISLAMABAD} component={FreelanceFestIslamabad} />
					<Route path={FREELANCE_FEST_ISLAMABAD_TWO} component={FreelanceFestIslamabad} />
					<Route path={FREELANCE_FEST_GILGIT} component={FreelanceFestGilgit} />
					<Route path={OUR_VOICE} component={OurVoice} />
					<RestrictedRoute path={PROFILE} component={Profile} />
					<RestrictedRoute register={true} exact strict path={COMPLETE_PROFILE} component={CompleteProfile} />
					<RestrictedRoute exact strict path={NEW_ISSUE} component={NewIssue} />
					<RestrictedRoute exact strict path={ISSUES} component={Issues} />
					<RestrictedRoute exact strict path={ORDERS} component={MyOrders} />
					<RestrictedRoute exact strict path={MEMBERSHIP} component={Membership} />
					<RestrictedRoute strict path={ISSUE} component={Issue} />
					<RestrictedRoute path={LOGOUT} component={Logout} />
					<RestrictedRoute register={true} exact strict path={EVENT_REGISTER} component={EventRegister} />
					<RestrictedRoute exact strict path={TICKET_ORDER} component={EventTicketOrder} />
					<RestrictedRoute register={true} exact strict path={ORDER_THANKYOU} component={OrderThankyou} />
					<RestrictedRoute register={true} exact strict path={ORDER_ONEBILL} component={OrderOneBill} />
					<RestrictedRoute register={true} exact strict path={MEMBERSHIP_BUY} component={BuyMembership} />
					<RestrictedRoute register={true} exact strict path={MEMBER_PROFILE} component={MemberProfile} />
					<RestrictedRoute register={true} exact strict path={JOBS} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={PEERS} component={Peers} />
					<RestrictedRoute register={true} exact strict path={DASHBOARD_EVENTS} component={DashboardEvents} />
					<RestrictedRoute register={true} exact strict path={DASHBOARD_EVENT} component={DashboardEvent} />
					<RestrictedRoute register={true} exact strict path={MENTORSHIP} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={TRAINING} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={TESTS} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={FILINGS} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={INSURANCE} component={ComingSoon} />
					<RestrictedRoute register={true} exact strict path={EDIT_PROFILE} component={EditProfile} />
					<RestrictedRoute register={true} exact strict path={CHAPTER_CONTRIBUTOR} component={ContributeChapter} />
					<RestrictedRoute register={true} exact strict path={UNIVERSITY_AMBASSADOR} component={ContributeAmbassador} />
					<RestrictedRoute register={true} exact strict path={HOST_YOUR_MEETUP} component={ContributeMeetups} />
					<RestrictedRoute register={true} exact strict path={APPLY_AS_A_SPEAKER} component={ContributeSpeaker} />
					<RestrictedRoute register={true} exact strict path={PROMOTIONS} component={Discounts} />
					<RestrictedRoute register={true} exact strict path={PROMOTION} component={Discount} />
					<RestrictedRoute register={true} exact strict path={GCC_DASHBOARD_LANDING} component={GoogleCareerCertificates} />
					<RestrictedRoute register={true} exact strict path={DASHBOARD_COURSE} component={DashboardCourse} />
					<RestrictedRoute register={true} exact strict path={GCC_ENROLL} component={EnrollCourse} />
					<RestrictedRoute register={true} exact strict path={GCC_THANKYOU} component={EnrollThankyou} />
					<RestrictedRoute register={true} exact strict path={COURSE_CERTIFY} component={CourseCertify} />
					<RestrictedRoute register={true} exact strict path={START_QUIZ} component={StartQuiz} />
					<RestrictedRoute register={true} exact strict path={QUIZ_RESULT} component={QuizResult} />
				</Switch>
			</Router>
		</div>
	);
}
export default App;
