import React, { useState, useRef, useEffect } from "react";
import { Divider, Tag, Typography, Button } from "antd";
import { Markup } from "interweave";
import styles from "../styles/member.module.css";
import { isMobile } from "react-device-detect";
import { IMAGE_URL } from "../helpers/constants";

const { Paragraph, Text } = Typography;

const MemberProfile = (props) => {
    const [ellipsis, setEllipsis] = useState(true);
    const scrollDiv = useRef();
    const { website, linkedin, platforms } = props;

    const typoClose = () => {
        setEllipsis(!ellipsis);
        if (!ellipsis) {
            scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <div ref={scrollDiv} className={styles.memberProfileOverviewPublic}>
                <div className={styles.profileLinks}>
                    {platforms &&
                        Object.keys(platforms).map((name, i) => {
                            return (
                                <a rel='noreferrer' href='#'>
                                    <img
                                        alt={name}
                                        src={`${IMAGE_URL}profile-icons/${name}.png`}
                                    />
                                </a>
                            );
                        })}
                </div>
                <div className={styles.skills}>
                    {props.skills &&
                        props.skills.map((skill) => {
                            return (
                                <Tag
                                    style={{
                                        backround: "lightgrey",
                                        cursor: "pointer",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <Text
                                        type='p'
                                        ellipsis={true}
                                        className={styles.skill}
                                        title={skill.name}
                                    >
                                        {skill.name}
                                    </Text>
                                </Tag>
                            );
                        })}
                </div>
                <Paragraph ellipsis={ellipsis ? { rows: 3 } : false}>
                    <Markup className={styles.overview} content={props.bio} />
                </Paragraph>
                <span className={styles.typoExpand} onClick={typoClose}>
                    {ellipsis ? "More" : "Less"}
                </span>
            </div>
            {isMobile && <Divider className={styles.mobileDivdier} />}
        </>
    );
};

export default MemberProfile;
