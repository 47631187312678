import React, { useState, useEffect, useRef } from "react";
import SectionBody from "../../components/dashboard/SectionBody";
import MemberHeader from "../../components/dashboard/MemberHeader";
import MemberOverview from "../../components/dashboard/MemberOverview";
import MemberEducation from "../../components/dashboard/MemberEducation";
import Dashboard from "../../components/dashboard/Main";
import MemberAvailability from "../../components/dashboard/MemberAvailability";
import MemberExperience from "../../components/dashboard/MemberExperience";
import MemberLevel from "../../components/dashboard/MemberLevel";
import MemberExplore from "../../components/dashboard/MemberExplore";
import pafla from "../../api/pafla";
import { Row, Col } from "antd";
import styles from "../../styles/member.module.css";

const MemberProfile = (props) => {
    const [profileData, setProfileData] = useState([]);
    const [loading, setLoading] = useState(true);
    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;

        if (profileData && Object.keys(profileData).length > 0) {
            return;
        }

        getProfileData();

        return () => (mounted.current = false);
    }, [profileData]);

    const getProfileData = async () => {
        const { memberId } = props.match.params;
        await pafla.get(`/member/${memberId}`).then((response) => {
            setProfileData(response.data.data);
            setLoading(false);
        });
    };

    return (
        <Dashboard
            title='Profile - Pakistan Freelancers Association'
            loading={loading}
            completeProfileCheck={true}
        >
            <SectionBody>
                <Row>
                    <Row gutter={0}>
                        <MemberHeader
                            avatar={profileData.avatar}
                            name={profileData.name}
                            title={profileData.title}
                            city={profileData.city}
                            country={profileData.country}
                            rate={profileData.hourly_rate}
                        />
                    </Row>
                    <Row gutter={0}>
                        <Col className={styles.sidebar} xs={24} md={6}>
                            <MemberExplore
                                website={profileData.website}
                                linkedin={profileData.linkedin}
                                profiles={profileData.profile_links}
                            />
                            <MemberAvailability
                                available={profileData.availability}
                            />
                            <MemberExperience
                                experience={profileData.years_experience}
                            />
                            <MemberLevel level={profileData.experience_level} />
                        </Col>
                        <Col xs={24} md={17}>
                            <MemberOverview
                                bio={profileData.bio}
                                skills={profileData.skills}
                            />
                            <MemberEducation
                                institute={profileData.institute}
                                degree={profileData.degree}
                                field_of_study={profileData.field_of_study}
                            />
                        </Col>
                    </Row>
                </Row>
            </SectionBody>
        </Dashboard>
    );
};

export default MemberProfile;
