import React from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import pafla from "../api/pafla";
import styles from "../styles/event.module.css";
import { Spin } from "antd";
import { notification } from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    CheckCircleFilled,
} from "@ant-design/icons";
import { withRouter } from "react-router";
import ReactPixel from 'react-facebook-pixel'

const { confirm } = Modal;

class Event extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            loading: true,
            profileCompleted: false,
            showConfirmation: false,
        };
    }

    async componentDidMount() {
        const { loggedOut } = this.props;

        if (!loggedOut) {
            await pafla.get("/user/profile-completed").then((response) => {
                this.setState({ loading: false });
                this.setState({ profileCompleted: response.data.data });
            });
        } else {
            this.setState({ loading: false });
        }
    }

    register = async () => {
        const { profileCompleted } = this.state;
        const { event } = this.props;

        if (!profileCompleted) {
            this.showConfirm(
                "Please complete your profile",
                <CloseOutlined />,
                "We've noticed you haven't completed your profile. Please complete your profile before continuing",
                () => this.redirectCompleteProfile()
            );
        } else {
            if (event.free) {
                this.showConfirm(
                    "Confirm your registration",
                    <CheckOutlined />,
                    `You're about to register for ${event.title}. Please confirm your registration.`,
                    () => this.enrollUser()
                );
            } else {
                console.log("Paid Event");
            }
        }
    };

    redirectCompleteProfile = () => {   
        this.props.history.push({
            pathname: '/dashboard/complete-profile',
            state: { referrer: window.location.pathname }
        });
    }

    enrollUser = async () => {
        const { profileCompleted } = this.state;
        const { event, history } = this.props;

        if (!profileCompleted) {
            history.push("/dashboard/complete-profile");
        } else {
            this.setState({ loading: true });
            await pafla
                .post(`user/event/${event.id}/enroll`)
                .then((response) => {
                    this.setState({ loading: false });
                    if (!response.data.error) {
                        this.setState({ showConfirmation: true });
                        ReactPixel.track('SubmitApplication');
                        notification.success({
                            message: "Success",
                            description: response.data.message,
                        });
                        this.props.changeTicketURL(
                            response.data.data.confirmed_pdf_path
                        );
                    } else {
                        notification.error({
                            message: "Error",
                            description: response.data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    notification.error({
                        message: "Error",
                        description: "An error occurred while making the request. Please try again later.",
                    });
                });
                
        }
    };

    showConfirm = (title, icon, content, onOk, onCancel) => {
        confirm({
            title: title,
            icon: icon,
            content: content,
            className: "mystyle",
            centered: true,
            onOk: onOk,
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    render() {
        const { event, ticketURL, eventLoading, loggedOut } = this.props;
        const { loading, showConfirmation } = this.state;
        console.log(ticketURL)
        return (
            <>
                {!loggedOut && !ticketURL && (
                    <Button
                        type='primary'
                        {...(!ticketURL &&
                            !loggedOut && { onClick: this.register })}
                        className={`btn_default ${styles.event_register_btn}`}
                    >
                        {loading || eventLoading ? (
                            <Spin style={{ marginTop: "-10px" }} />
                        ) : (
                            <>{!ticketURL && !loggedOut && "Register"}</>
                        )}
                    </Button>
                )}

                {!loggedOut && ticketURL && (
                    <a
                        className={`btn_default ${styles.event_register_btn}`}
                        href={ticketURL}
                        rel='noreferrer'
                        target='_blank'
                    >
                        Download Ticket
                    </a>
                )}

                {loggedOut && !ticketURL && (
                    <Link
                        className={`btn_default ${styles.event_register_btn}`}
                        to={`/dashboard/event/${event.title}/${event.id}`}
                    >
                        Register Now
                    </Link>
                )}

                <Modal
                    title='Registration Confirmed'
                    centered
                    open={showConfirmation}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConfirmation: false })}
                    footer={null}
                >
                    <div style={{ textAlign: "center" }}>
                        <p>
                            <CheckCircleFilled
                                style={{ fontSize: "40px", color: "#00c087" }}
                            />
                        </p>
                        <h2 style={{ textAlign: "center" }}>
                            Registration Successfull
                        </h2>
                        <p>
                            Thankyou for registering for {event.title}. Your
                            registration is now confirmed. Please download the
                            ticket by clicking the button below.
                        </p>
                        <Button
                            type='primary'
                            className={`btn_default ${styles.event_register_btn}`}
                        >
                            <a
                                href={ticketURL}
                                download
                                rel='noreferrer'
                                target='_blank'
                            >
                                Download your ticket
                            </a>
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(Event);
