import React, { useState } from "react";
import styles from "../styles/blog.module.css";
import { Avatar, Typography } from "antd";
import "moment-timezone";
import { IMAGE_URL } from "../helpers/constants";
import {Link} from 'react-router-dom'

const { Paragraph, Text } = Typography;

const BlogAuthor = ({ author, createdAt }) => {
    const [ellipsis, setEllipsis] = useState(true);
    return (
        <>
            {author && (
                <Link className={styles.blogMetaLarge} to={`/member/${author.member_id}`}>
                    <Avatar
                        src={author.avatar}
                        size={{ xs: 100, md: 100, lg: 100, xl: 100, xxl: 100 }}
                    />
                    <div className={styles.blogMetaLargeDetails}>
                        <h2 className={styles.blogAuthorName}>
                            Written by {author.name}
                        </h2>
                        <div className={styles.blogMetaLargeSubTitle}>
                            <Text
                                type='p'
                                ellipsis={true}
                                className={styles.blogAuthorTitle}
                            >
                                {author.title}
                            </Text>
                            <span> - </span>
                            <div className={styles.profileLinks}>
                                {author.platforms &&
                                    Object.keys(author.platforms).map(
                                        (name, i) => {
                                            return (
                                                <img
                                                    alt={name}
                                                    src={`${IMAGE_URL}profile-icons/${name}.png`}
                                                />
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>

                    <Paragraph
                        className={styles.blogAuthorBio}
                        ellipsis={ellipsis ? { rows: 2 } : false}
                    >
                        {author.bio}
                    </Paragraph>
                </Link>
            )}
        </>
    );
};

export default BlogAuthor;
