import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import styles from '../../styles/dashboard.module.css'
import pafla from '../../api/pafla'
import Loader from '../Loader'

const MemberSignup = (props) => {

    const [primarySkills, setPrimarySkills] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (primarySkills?.length === 0) {
            getPrimarySkills()
        }
    });

    const getPrimarySkills = async () => {
        await pafla.get('/primary-skills')
            .then((response) => {
                response.data.data.map((skill) => {
                    setPrimarySkills((primarySkills) => [...primarySkills, skill.name])
                    setLoading(false)
                    return null
                })        
            })
    }

    const onChange = async (e: CheckboxChangeEvent) => {
        props.setPeersbySkills(e.target.checked, e.target.value)       
    };

    return (
        <div className={styles.filter}>
            <h3>Category</h3>
            {loading && <Loader size="20px" margin="0px"/>}
            {!loading && primarySkills?.map((skill) => {
                return <Checkbox className={styles.filterItem} onChange={onChange} value={skill}>{skill}</Checkbox>
            })}
        </div>
    );
};

export default MemberSignup;
