import React from 'react';
import { Col, Row, Image, Button, Tabs, Tag, notification, Spin } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import pafla from '../../api/pafla';
import ReactHtmlParser from 'react-html-parser';
import styles from '../../styles/event.module.css';
import quizStyles from '../../styles/quizStyles.module.css';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { CalendarOutlined, PushpinOutlined, ApartmentOutlined, MoneyCollectOutlined, GlobalOutlined, SendOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

class Course extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			event: null,
			loading: true,
			is_certified: false,
			certificateLoading: false,
			attempt_id: false,
		};
	}

	async componentDidMount() {
		const { courseId } = this.props.match.params;

		await pafla.get(`/courses/${courseId}`).then((response) => {
			this.setState({ loading: false });
			this.setState({ course: response.data.data });
			this.userIsCertified(courseId);
		});
	}

	checkQuizEligibility = async () => {
		const { course } = this.state;
		this.setState({ loading: true });
		await pafla.get(`/courses/${course.id}/check-quiz-eligibility`).then((response) => {
			this.setState({ loading: false });
			if (response.data.data.eligible) {
				this.props.history.push(`/dashboard/get-certified/${course.id}`);
			} else {
				notification.error({
					message: 'Error',
					description: response.data.message,
					duration: null,
				});
			}
		});
	};

	userIsCertified = async (courseId) => {
		this.setState({ loading: true });
		await pafla.get(`/user-is-certified/${courseId}`).then((response) => {
			this.setState({ loading: false });
			if (!response.data.error) {
				this.setState({ is_certified: true });
				this.setState({ attempt_id: response.data.data.id });
			}
		});
	};

	downloadCertificate = async () => {
		this.setState({ certificateLoading: true });
		try {
			const { attempt_id } = this.state;

			await pafla.get(`get-quiz-certificate/${attempt_id}`).then((response) => {
				this.setState({ certificateLoading: false });
				if (response.data.data.certificate_url) {
					window.open(response.data.data.certificate_url, '_blank', 'noreferrer');
				}
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			this.setState({ certificateLoading: false });
		}
	};

	render() {
		const { course, loading, certificateLoading, is_certified } = this.state;
		const sd = course && course.start_date && new Date(course.start_date);
		const sdf =
			course &&
			course.start_date &&
			new Intl.DateTimeFormat('en', {
				year: 'numeric',
				month: 'long',
				day: '2-digit',
				hour: 'numeric',
				minute: 'numeric',
			}).format(sd);
		const ed = course && course.end_date && new Date(course.end_date);
		const edf =
			course &&
			course.end_date &&
			new Intl.DateTimeFormat('en', {
				year: 'numeric',
				month: 'long',
				day: '2-digit',
				hour: 'numeric',
				minute: 'numeric',
			}).format(ed);
		const gallery = course && course.gallery && JSON.parse(course.gallery);
		return (
			<Dashboard loading={loading}>
				<div className={styles.card_items}>
					{course && (
						<div className="contained-row event">
							<Helmet>
								<meta charSet="utf-8" />
								<title>{course.title} - Pakistan Freelancers Association</title>
							</Helmet>
							<Row gutter={{ xs: 0, md: 24 }}>
								<Col xs={{ span: 24 }} md={{ span: 16 }}>
									<div className={styles.eventData}>
										{course.image && (
											<img className="featured-image" src={`${process.env.REACT_APP_STORAGE_URL}/${course.image}`} alt={course.title} />
										)}
										<div className={styles.eventContent}>
											<h2>{course.title}</h2>
											{ReactHtmlParser(course.description)}
										</div>
									</div>
								</Col>
								<Col xs={{ span: 24 }} md={{ span: 8 }}>
									<div className={styles.eventSidebar}>
										<div className={styles.sectionHeader}>Event Details</div>
										{course.start_date && (
											<div className={styles.eventDetail}>
												<div className={styles.icon}>
													<CalendarOutlined />
												</div>
												<div className={styles.data}>
													<div className={styles.header}>Registration Start Date</div>
													<div className={styles.detail}>{`${sdf}`}</div>
												</div>
											</div>
										)}
										{course.end_date && (
											<div className={styles.eventDetail}>
												<div className={styles.icon}>
													<CalendarOutlined />
												</div>
												<div className={styles.data}>
													<div className={styles.header}>Registration End Date</div>
													<div className={styles.detail}>{`${edf}`}</div>
												</div>
											</div>
										)}
										{course.program && (
											<div className={styles.eventDetail}>
												<div className={styles.icon}>
													<ApartmentOutlined />
												</div>
												<div className={styles.data}>
													<div className={styles.header}>Program</div>
													<div className={styles.detail}>{`${course.program.title}`}</div>
												</div>
											</div>
										)}
										{/*<div className={styles.eventDetail}>
		            						<div className={styles.icon}>
		            							<SendOutlined />
		            						</div>
		            						<div className={styles.data}>
		            							<div className={styles.header}>
		            								Entrance
		            							</div>
		            							<div className={styles.detail}>
		            								<Tag color="#e42f">Members Only</Tag>
		            							</div>
		            						</div>
		            					</div>  */}
										<div className={styles.eventDetail}>
											<div className={styles.icon}>
												<MoneyCollectOutlined />
											</div>
											<div className={styles.data}>
												<div className={styles.header}>Price</div>
												<div className={styles.detail}>Free</div>
											</div>
										</div>
										{!course.registrations_closed && (
											<div className={`${styles.eventDetail} ${styles.noBorder}`}>
												<Link
													className={`btn_default ${styles.event_register_btn}`}
													to={`/dashboard/${course.program.id}/enroll-course/${course.id}/${course.title}`}
												>
													Register Now
												</Link>
											</div>
										)}
										{course.certification && (
											<div className={styles.eventDetail}>
												<span
													style={{ marginBottom: '0px', cursor: 'pointer' }}
													className={`btn_default ${styles.event_register_btn_orange}`}
													onClick={this.checkQuizEligibility}
												>
													{!is_certified ? 'Get Certified' : 'Retake Quiz'}
												</span>
											</div>
										)}
										{course.certification && is_certified && (
											<div className={styles.eventDetail}>
												<span
													onClick={this.downloadCertificate}
													className={`btn_default ${quizStyles.quizResultBtn} ${quizStyles.certificateButton} ${quizStyles.courseCertificateButton}`}
												>
													{!certificateLoading && 'Download your Certificate'}
													{certificateLoading && (
														<Spin indicator={<LoadingOutlined style={{ fontSize: 14, color: '#fff' }} spin />} style={{ padding: '0px 80px' }} />
													)}
												</span>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</div>
					)}
				</div>
			</Dashboard>
		);
	}
}

export default withRouter(Course);
