import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Row, Col, Input, Select, Checkbox, Alert, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import { notification } from 'antd';
import { withRouter } from 'react-router';
import FormButton from '../auth/FormButton';
import { Radio } from 'antd';
import ReactPixel from 'react-facebook-pixel';
import { useForm } from 'antd/lib/form/Form';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
	wrapperCol: {
		lg: { span: 12, offset: 6 },
		xs: { span: 16, offset: 0 },
		md: { span: 16, offset: 4 },
		sm: { span: 16, offset: 4 },
	},
};

const CourseEnrollmentForm = (props) => {
	const [quizTitle, setQuizTitle] = useState(null);
	const [questionnaires, setQuestionnaires] = useState(null);
	const [questionnaireId, setQuestionnaireId] = useState(null);
	const [formLoading, setFormLoading] = useState(true);
	const { courseId } = useParams();
	const [currentCourseId, setCurrentCourseId] = useState(null);
	const [form] = useForm();
	const history = useHistory();

	useEffect(() => {
		const fetchQuiz = async () => {
			try {
				await pafla.get(`get-course-quiz/${courseId}`).then((response) => {
					setQuizTitle(response.data.data.quiz_questionnaire.title);
					setQuestionnaires(response.data.data.quiz_questionnaire);
					setQuestionnaireId(response.data.data.quiz_questionnaire.id);
					setFormLoading(false);
				});
			} catch (error) {
				console.error('Error fetching data:', error);
				setFormLoading(false);
			}
		};
		fetchQuiz();
	}, []);

	const handleQuestionnaireChange = (questionId, value) => {
		const updatedValues = {};
		updatedValues[`question_${questionId}`] = value;
		form.setFieldsValue(updatedValues);
	};

	const onFinish = async (values) => {
		setFormLoading(true);

		const formData = {};

		formData.course = parseInt(courseId);
		formData.questionnaire_id = parseInt(questionnaireId);
		formData.questionnaire = groupQuestionnaireData(values);

		try {
			await pafla.post(`course/submit-quiz`, formData).then((response) => {
				if (!response.data.error) {
					ReactPixel.track('QuizSubmitted');
					notification.success({
						message: 'Success',
						description: response.data.message,
					});
					history.push(`/dashboard/${courseId}/quiz-result/${response.data.data.id}`);
				} else {
					notification.error({
						message: 'Error',
						description: response.data.message,
					});
				}
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			setFormLoading(false);
		}
	};

	const groupQuestionnaireData = (formData) => {
		const questionData = [];

		// Loop through the keys of formData
		Object.keys(formData).forEach((key) => {
			// Check if the key starts with 'question_'
			if (key.startsWith('question_')) {
				// Extract the question number
				const questionNumber = key.replace('question_', '');

				// Push an object containing the question number and value to the questionData array
				questionData.push({
					questionNumber: questionNumber,
					value: formData[key].target.value,
				});
			}
		});

		return JSON.stringify(questionData);
	};

	return (
		<Form
			{...layout}
			form={form}
			name="normal_login"
			className={styles.quiz_form}
			initialValues={{
				course: currentCourseId,
			}}
			onFinish={onFinish}
			labelCol={{ span: 12 }}
			wrapperCol={{ span: 24 }}
			layout="vertical"
		>
			<Row>
				<Col xs={24} md={24}>
					<h2 style={{ marginBottom: '25px' }}>{quizTitle}</h2>
				</Col>
			</Row>
			<Row>
				{questionnaires && (
					<ol style={{ paddingLeft: '20px', fontSize: '16px' }}>
						{questionnaires?.questions.map((ques) => {
							const { question, type, choices } = ques;
							const questionId = ques.id;
							return (
								<li>
									<Col xs={24} md={24}>
										<Form.Item
											name={`question_${questionId}`}
											label={<div className={styles.question} dangerouslySetInnerHTML={{ __html: question }} />}
											rules={[
												{
													required: true,
													message: 'This question is required',
												},
											]}
											labelCol={24}
										>
											{type === 'mcq' && (
												<Radio.Group loading={formLoading} onChange={(value) => handleQuestionnaireChange(questionId, value)}>
													<Space direction="vertical">
														{choices.map((choice, index) => (
															<Radio key={index} className={styles.radioValue} value={choice.id}>
																{choice.answer}
															</Radio>
														))}
													</Space>
												</Radio.Group>
											)}
											{type === 'text' && (
												<TextArea rows={3} placeholder={question} onChange={(e) => handleQuestionnaireChange(questionId, e.target.value)} />
											)}
										</Form.Item>
									</Col>
								</li>
							);
						})}
					</ol>
				)}
			</Row>

			<Row gutter={0}>
				<Col xs={24} md={24}>
					<Form.Item>
						<FormButton label="Submit Quiz" formLoading={formLoading} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default withRouter(CourseEnrollmentForm);
