import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import { CheckOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';

const MemberSignup = (props) => {
	const search = useLocation().search;
	const orderId = new URLSearchParams(search).get('O');
	const [order, setOrder] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (orderId) {
			pafla.post(`order-paid`, { order_id: orderId }).then((response) => {
				if (!response.data.error) {
					notification.success({
						message: 'Success',
						description: response.data.message,
					});

					setOrder(response.data.data);
				} else {
					notification.error({
						message: 'Error',
						description: response.data.message,
					});
				}
				setLoading(false);
			});
		}
	}, [orderId]);

	return (
		<Dashboard title="Thankyou - Pakistan Freelancers Association" loading={loading} completeProfileCheck={true}>
			<SectionBody>
				<Row>
					<div className={styles.orderThankyou}>
						<Row gutter={0}>
							<Col xs={24}>
								<CheckOutlined />
								<h3>Your order was placed successfully!</h3>
							</Col>
						</Row>
						<Row gutter={0}>
							<Col xs={24} md={24}>
								<p>Thankyou, an email regarding your order has been sent to your email account</p>
							</Col>
						</Row>
						<a href="/dashboard/orders" target="_self" className={`btn_default`}>
							View all orders
						</a>
					</div>
				</Row>
			</SectionBody>
		</Dashboard>
	);
};

export default MemberSignup;
