import React from 'react';
import { Form, Row, Col, Input, Select, Checkbox } from 'antd';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import axios from 'axios';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';
import FormButton from '../auth/FormButton';
import { Radio, Space } from 'antd';
import ReactPixel from 'react-facebook-pixel';

const { TextArea } = Input;

const layout = {
	wrapperCol: {
		lg: { span: 12, offset: 6 },
		xs: { span: 16, offset: 0 },
		md: { span: 16, offset: 4 },
		sm: { span: 16, offset: 4 },
	},
};

class ContributeForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formLoading: null,
		};
	}

	onFinish = (values) => {
		const { type } = this.props;

		this.setState({ formLoading: true });

		if (type) {
			pafla
				.post(`user/volunteer`, { for: type, description: values.description })
				.then((response) => {
					if (!response.data.error) {
						notification.success({
							message: 'Success',
							description: response.data.message,
						});
					} else {
						notification.error({
							message: 'Error',
							description: response.data.message,
						});
					}
					this.setState({ formLoading: false });
				})
				.catch((error) => {
					this.setState({ formLoading: false });
				});
		}
	};

	render() {
		return (
			<Form
				{...layout}
				name="normal_login"
				className={styles.contribtue_form}
				onFinish={this.onFinish}
				labelCol={{ span: 12 }}
				wrapperCol={{ span: 24 }}
				layout="vertical"
			>
				<Row gutter={0}>
					<Col xs={24} md={24}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Please tell us a bit about yourself',
								},
							]}
							validateTrigger={'onBlur'}
							name="description"
						>
							<TextArea rows={10} placeholder="Tell us a bit about yourself and would you like to join us as a volunteer with us" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={0}>
					<Col xs={24} md={24}>
						<Form.Item>
							<FormButton label="Submit" formLoading={this.state.formLoading} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default withRouter(ContributeForm);
