import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import {Zoom} from 'react-reveal';
import Countdown from 'react-countdown';
import { Collapse } from 'antd';

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}


const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
        <>
            <div className={styles.countDown}>{days}<span className={styles.countType}>Days</span></div>
            <div className={styles.countDown}>{hours}<span className={styles.countType}>Hours</span></div>
            <div className={styles.countDown}>{minutes}<span className={styles.countType}>Minutes</span></div>
            <div className={styles.countDown}>{seconds}<span className={styles.countType}>Seconds</span></div>
        </>
    )
};


const HomeAbout = () => {
    return (
        <section className={`${styles.section} ${styles.timer}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}} className={styles.timerGrid}>
                        <div className={`${styles.section_title_container} ${styles.title}` }>
                            <h1>We're <br/>meeting soon</h1>
                            <h2>Join us at the year’s largest conference where the world’s 4th fastest growing gig-economy gathers to meet</h2>
                        </div>
                        <Zoom left>
                            <div className={styles.count}>
                                <div className={styles.countInner}>
                                    <Countdown renderer={renderer} date={new Date('August 16, 2022 09:00:00 GMT+05:00')} />
                                </div>
                            </div>
                        </Zoom>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
