import React from 'react';
import styles from '../../styles/freelance-fest/general.module.css'
import { CalendarOutlined } from '@ant-design/icons';

const eventDate = ({children}) => {
    return (
        <div className={styles.venueDate}>
            <a 
                target="_blank"
                href="https://calendar.google.com/calendar/r/eventedit?text=Freelance+Fest+2022&dates=20220325T040000/20220327T130000&ctz=Etc/GMT+5&details=For+details,+link+here:+https://pafla.org.pk/freelancefest-2022-islamabad&location=Fatima+Jinnah+Park+(F-9 Park),+P22F+P4P,+F-9,+Islamabad,+Islamabad+Capital+Territory,+Punjab,+Pakistan">
                <CalendarOutlined />
                <span className={styles.date}>14<sup>th</sup>, 15<sup>th</sup>, 16<sup>th</sup>May 2022</span>
                <span className={styles.date}>Fatima Jinnah Park (F-9), Islamabad</span>
            </a>
        </div>
    )
}

export default eventDate;
