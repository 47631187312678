import React from 'react';
import { Col, Row, Typography } from 'antd';
import styles from '../styles/footer.module.css';
import { IMAGE_URL } from '../helpers/constants';
import { Link } from 'react-router-dom';
import { FacebookFilled, LinkedinFilled, InstagramFilled, YoutubeFilled, WhatsAppOutlined } from '@ant-design/icons';

const { Text } = Typography;
const Footer = () => {
	return (
		<footer className={styles.footer}>
			<Row>
				<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }} className={styles.footer_wrapper}>
					<Row>
						<Col sm={{ span: 24 }} md={{ span: 8 }} className={styles.footer_left}>
							<Text className={styles.text_top}>Pakistan Freelancers Association (PAFLA) </Text>
							<Text className={styles.text_bottom}>
								A platform and support group to help Pakistani freelancers grow their career and overcome their challenges
							</Text>
							<a
								href="//www.dmca.com/Protection/Status.aspx?ID=8becd636-91e5-4399-b521-629225fdca58"
								title="DMCA.com Protection Status"
								class="dmca-badge"
								style={{ marginTop: '10px' }}
							>
								{' '}
								<img
									src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-04.png?ID=8becd636-91e5-4399-b521-629225fdca58"
									alt="DMCA.com Protection Status"
								/>
							</a>{' '}
							<script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
						</Col>
						<Col sm={{ span: 24 }} md={{ span: 8 }} className={styles.footer_logo_wrapper}>
							<img src={`${IMAGE_URL}logo.svg`} alt="" className={styles.footer_logo} />
						</Col>
						<Col sm={{ span: 24 }} md={{ span: 8 }} className={styles.footer_right}>
							<Text className={styles.footer_social}>
								<a href="https://www.facebook.com/pafla.org/" target="_blank" rel="noreferrer" className={styles.footer_social_item}>
									<FacebookFilled />
								</a>
								<a href="https://www.linkedin.com/company/pafla" target="_blank" rel="noreferrer" className={styles.footer_social_item}>
									<LinkedinFilled />
								</a>
								<a href="https://www.instagram.com/paflaorg/" target="_blank" className={styles.footer_social_item}>
									<InstagramFilled />
								</a>
								<a href="https://www.youtube.com/@paflapk" target="_blank" className={styles.footer_social_item}>
									<YoutubeFilled />
								</a>
								<a href="https://api.whatsapp.com/send?phone=923002083366" target="_blank" className={styles.footer_social_item}>
									<WhatsAppOutlined />
								</a>
							</Text>
							<Text className={styles.text_bottom}>
								<Link to={'/publications'}>Publications</Link>
								<Link to={'/media-mentions'}>Media Mentions</Link>
								<Link to={'/press-releases'}>Press Releases</Link>
							</Text>
							<Text className={styles.copyright}>
								© {new Date().getFullYear()} pafla.org.pk - Powered & Managed by{' '}
								<a target="_blank" style={{ color: '#000', textDecoration: 'underline' }} href="https://onx.digital/">
									ONX Digital
								</a>
							</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;
