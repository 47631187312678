import React, {useRef} from 'react';
import styles from '../../styles/dashboard.module.css';
import {Row, Col} from 'antd';

const SectionBody = ({width = 24, children, heading, description, innerRef, id}) => {
    return (
        
        <Col id={id} xs={{span: 24}} md={{span: width}} ref={innerRef}>
            <div className={styles.dashboardSectionBody}>
                <h2>{heading}</h2>
                <h4>{description}</h4>
                {children}
            </div>
        </Col>
    );
};

export default SectionBody;
