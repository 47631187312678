import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel'
import styles from '../styles/signUp.module.css';
import {Col, Row, Typography} from 'antd';
import {HOME, IMAGE_URL} from '../helpers/constants';
import {Link} from 'react-router-dom';
import PasswordResetConfirmationForm from '../components/auth/PasswordResetConfirmationForm';
import LoginLeft from '../components/auth/LoginLeft';


const { Text} = Typography;
const MemberSignup = () => {

    useEffect(() => {
        ReactPixel.pageView()
    });
    
    return (
        <div className={styles.sign_up}>
            <Row style={{width: '100%'}}>
                <LoginLeft/>
                <Col xs={{span: 24}} md={{span: 12}} className={styles.sign_up_left}>
                    <Link to={HOME}>
                        <img src={`${IMAGE_URL}logo.svg`} alt="" className={styles.auth_logo}/>
                    </Link>
                    <div className={styles.sign_up_form}>
                        <Text>Enter the email associated with your account</Text>
                        <PasswordResetConfirmationForm/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default MemberSignup;
