import React from 'react';
import {Col, Row, Typography} from 'antd';
import styles from '../../styles/footer.module.css';
import {IMAGE_URL} from '../../helpers/constants';
import {Link} from 'react-router-dom';


const {Text} = Typography;
const Footer = () => {
    return (
        <footer className={styles.footer}>
            <Row>
                <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}} className={styles.footer_wrapper}>
                    <Row >
                        <Col sm={{span: 24}} md={{span: 8}} className={styles.footer_left}>
                            <Text className={styles.text_top}>Pakistan Freelancers Association (PAFLA) </Text>
                            <Text className={styles.text_bottom}>A platform and support group to help Pakistani freelancers grow their career and overcome their challenges</Text>
                        </Col>
                        <Col sm={{span: 24}} md={{span: 8}} className={styles.footer_logo_wrapper}>
                            <img src={`${IMAGE_URL}logo.svg`} alt="" className={styles.footer_logo}/>
                        </Col>
                        <Col sm={{span: 24}} md={{span: 8}} className={styles.footer_right}>
                            <Text className={styles.footer_social}>
                                <a href="https://www.facebook.com/pafla.org/" target="_blank" rel="noreferrer" className={styles.footer_social_item}>
                                    <img src={`${IMAGE_URL}facebook.png`} alt=""/>
                                </a>
                                <a href="https://www.linkedin.com/company/pafla" target="_blank" rel="noreferrer" className={styles.footer_social_item}>
                                    <img src={`${IMAGE_URL}linkedin.png`} alt=""/>
                                </a>
                                <a href="https://www.instagram.com/paflaorg/" target="_blank" className={styles.footer_social_item}>
                                    <img src={`${IMAGE_URL}instagram.png`} alt=""/>
                                </a>
                            </Text>
                            <Text className={styles.text_bottom}>
                                {/*<Link to={'/'}>Terms and Conditions </Link> • <Link to={'/'}>Privacy Policy</Link>*/}
                                <Link to={'/press-releases'}>Press Releases</Link>
                            </Text>
                            <Text className={styles.copyright}>© {new Date().getFullYear()} pafla.org</Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
