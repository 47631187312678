import React from 'react';
import styles from '../styles/signUp.module.css';
import {Col, Row, Typography} from 'antd';
import {HOME, IMAGE_URL} from '../helpers/constants';
import {Link} from 'react-router-dom';
import ProfileForm from '../components/auth/ProfileForm';
import ChangePassword from '../components/auth/ChangePassword';
import { Divider } from 'antd';
import Layout from '../components/Layout';
import pafla from '../api/pafla'
import Cookies from 'js-cookie';
import { message } from 'antd';
import { Helmet } from "react-helmet"

const {Title, Text} = Typography;


class MemberSignup extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: false,
            profile: null
        }
    }

    componentDidMount() {
        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
            pafla.get('user/profile')
            .then((response) => {
                if (response.data.error) {
                    message.success(response.data.message)
                } else {
                    this.setState({profile: response.data.data})
                }
            })
        } else {
            this.props.history.push('/')
        }
    }

    render() {
        const { profile } = this.state
        return (
            <Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Your Profile - Pakistan Freelancers Association</title>
                </Helmet>
                <div className='single-blog'>
                    {this.state.profile && 
                        <>
                            <Title level={1} className={styles.banner_title}>
                                Welcome back, {profile.name}!
                            </Title>
                            <Row>
                                <Col span={12}>
                                    <Title level={5} className={styles.banner_title}>
                                        MEMBER ID: {profile.membership_id}
                                    </Title>
                                    {/*<Title level={2} className={styles.banner_title}>
                                        Member since January 5, 2021
                                    </Title>*/}
                                </Col>
                            </Row>
                            <Divider/>
                            <Title level={5} className={styles.banner_title}>
                                <span style={{textTransform: 'capitalize'}}>YOUR PAFLA PROFILE</span>
                            </Title>
                            <ProfileForm data={profile} />
                            <Divider/>
                            <Title level={5} className={styles.banner_title}>
                                Update Password
                            </Title>
                            <ChangePassword />
                        </>
                    }
                </div>
            </Layout>
        )
    }

}

export default MemberSignup;
