import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col, Input, Select, Checkbox, DatePicker } from 'antd';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import moment from 'moment';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { useLocation, useHistory } from 'react-router';
import FormButton from './FormButton';
import { cities, states } from '../FreelanceFest/constants';
import ImageUpload from './ImageUpload';
import ReactPixel from 'react-facebook-pixel';

const layout = {
	wrapperCol: {
		lg: { span: 12, offset: 6 },
		xs: { span: 16, offset: 0 },
		md: { span: 16, offset: 4 },
		sm: { span: 16, offset: 4 },
	},
};

const { Option } = Select;
const { TextArea } = Input;

const ProfileCompleteForm = (props) => {
	const [formLoading, setFormLoading] = useState(false);
	const [profileData, setProfileData] = useState([]);
	const [primarySkills, setPrimarySkills] = useState([]);
	const [primarySkill, setPrimarySkill] = useState();
	const [secondarySkills, setSecondarySkills] = useState([]);
	const [upwork, setUpwork] = useState(false);
	const [fiverr, setFiverr] = useState(false);
	const [freelancercom, setFreelancercom] = useState(false);
	const [guru, setGuru] = useState(false);
	const [toptal, setToptal] = useState(false);
	const [peopleperhour, setPeopleperhour] = useState(false);
	const [codeable, setCodeable] = useState(false);
	const [otherPlatform, setotherPlatform] = useState(false);
	const [avatar, setAvatar] = useState();

	const [form] = Form.useForm();
	const mounted = useRef(true);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		mounted.current = true;

		if (primarySkills && Object.keys(primarySkills).length > 0) {
			return;
		}

		getPrimarySkills();

		if (profileData && Object.keys(profileData).length > 0) {
			return;
		}

		getProfileData();

		return () => (mounted.current = false);
	}, [primarySkills, profileData]);

	const getPrimarySkills = async () => {
		await pafla.get('/primary-skills').then((response) => {
			setPrimarySkills(response.data.data);
		});
	};

	const getProfileData = async () => {
		await pafla.get('/user/profile').then((response) => {
			const { data } = response.data;
			const date = data.birth_date ? moment(data.birth_date, 'YYYY-MM-DD') : moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
			form.setFieldsValue({
				name: data.name,
				organisation: data.organisation,
				designation: data.designation,
				gender: data.gender,
				city: data.city,
				state: data.state,
				birthdate: date,
				degree: data.degree,
				field_of_study: data.field_of_study,
				institute: data.institute,
				title: data.title,
				website: data.website,
				linkedin: data.linkedin,
				years_experience: data.years_experience,
				hourly_rate: data.hourly_rate,
				availability: data.availability,
				experience_level: data.experience_level,
				bio: data.bio,
				primary_skill: getProfilePrimarySkill(data.skills),
				secondary_skill: getProfileSecondarySkill(data.skills),
			});

			if (data.is_profile_completed) {
				form.setFieldsValue({ avatar: data.avatar });
				setAvatar(response.data.data.avatar);
			}

			setProfileData(response.data);
			setProfileLinks(response.data.data.profile_links);
		});
	};

	const setProfileLinks = (profileLinks) => {
		const parsed = JSON.parse(profileLinks);
		if (parsed && Object.keys(parsed).length > 0 && Object.getPrototypeOf(parsed) === Object.prototype) {
			if (parsed.hasOwnProperty('upwork')) {
				onUpworkChange(true);
				form.setFieldsValue({
					upwork_link: parsed.upwork,
				});
			}

			if (parsed.hasOwnProperty('fiverr')) {
				onFiverrChange(true);
				form.setFieldsValue({
					fiverr_link: parsed.fiverr,
				});
			}

			if (parsed.hasOwnProperty('freelancercom')) {
				onFreelancercomChange(true);
				form.setFieldsValue({
					freelancercom_link: parsed.freelancercom,
				});
			}

			if (parsed.hasOwnProperty('guru')) {
				onGuruChange(true);
				form.setFieldsValue({
					guru_link: parsed.guru,
				});
			}

			if (parsed.hasOwnProperty('toptal')) {
				onToptalChange(true);
				form.setFieldsValue({
					toptal_link: parsed.toptal,
				});
			}

			if (parsed.hasOwnProperty('peopleperhour')) {
				onPeopleperhourChange(true);
				form.setFieldsValue({
					peopleperhour_link: parsed.peopleperhour,
				});
			}

			if (parsed.hasOwnProperty('codeable')) {
				onCodeableChange(true);
				form.setFieldsValue({
					codeable_link: parsed.codeable,
				});
			}

			if (parsed.hasOwnProperty('otherplatform')) {
				onOtherPlatformChange(true);
				form.setFieldsValue({
					otherplatform_name: parsed.otherplatform.name,
					otherplatform_link: parsed.otherplatform.link,
				});
			}
		}
	};

	const getProfilePrimarySkill = (skills) => {
		const selectedSkill = skills.find(function (skill) {
			return skill.type === 'Primary';
		});
		if (selectedSkill) {
			setPrimarySkill(selectedSkill.id);
			getSecondarySkills(selectedSkill.id);
			return selectedSkill.id;
		}
	};

	const getProfileSecondarySkill = (skills) => {
		const selectedSkill = skills.find(function (skill) {
			return skill.type === 'Secondary';
		});
		if (selectedSkill) {
			return selectedSkill.id;
		}
	};

	const userDetails = () => {
		let userDetails = Cookies.get('user_details');
		let userMeta = {};

		if (userDetails) {
			let userDetailsParsed = JSON.parse(userDetails);

			if (userDetailsParsed) {
				userMeta.phone = userDetailsParsed.phone;
			}
		}

		return userMeta;
	};

	const onFinish = (values) => {
		pafla.post(`user/complete-profile`, values).then((response) => {
			if (!response.data.error) {
				const { state } = location;
				Cookies.set('user_details', response.data.data, { path: '/' });

				ReactPixel.track('CompleteRegistration');
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
				if (state) {
					if (state.referrer) {
						history.push(state.referrer);
					}
				}
			} else {
				notification.error({
					message: 'Error',
					description: response.data.message,
				});
			}
			setFormLoading(false);
		});
	};

	const updateFilename = (filename) => {
		form.setFieldsValue({
			avatar: filename,
		});
	};

	const getSecondarySkills = async (primarySkill) => {
		form.setFieldsValue({
			secondary_skill: '',
		});

		var parent = primarySkill ? primarySkill : form.getFieldValue('primary_skill');

		await pafla.get('/secondary-skills', { params: { parent_id: parent } }).then((response) => {
			setPrimarySkill(form.getFieldValue('primary_skill'));
			setSecondarySkills(response.data.data);
		});
	};

	const onFiverrChange = (active) => {
		form.setFieldsValue({
			fiverr: active,
		});
		setFiverr(active);
	};

	const onUpworkChange = (active) => {
		form.setFieldsValue({
			upwork: active,
		});
		setUpwork(active);
	};

	const onFreelancercomChange = (active) => {
		form.setFieldsValue({
			freelancercom: active,
		});
		setFreelancercom(active);
	};

	const onGuruChange = (active) => {
		form.setFieldsValue({
			guru: active,
		});
		setGuru(active);
	};

	const onToptalChange = (active) => {
		form.setFieldsValue({
			toptal: active,
		});
		setToptal(active);
	};

	const onPeopleperhourChange = (active) => {
		form.setFieldsValue({
			peopleperhour: active,
		});
		setPeopleperhour(active);
	};

	const onCodeableChange = (active) => {
		form.setFieldsValue({
			codeable: active,
		});
		setCodeable(active);
	};

	const onOtherPlatformChange = (active) => {
		form.setFieldsValue({
			otherplatform: active,
		});
		setotherPlatform(active);
	};

	return (
		<>
			<Form
				{...layout}
				form={form}
				name="complete-profile"
				className={styles.event_register_form}
				initialValues={{
					phone_number: userDetails().phone,
					country: 'Pakistan',
				}}
				onFinish={onFinish}
				labelCol={{ span: 12 }}
				wrapperCol={{ span: 22 }}
				layout="vertical"
			>
				<div className={styles.ticketHead}>
					<h3>Personal Details</h3>
					<hr />
					<p>Tell us a bit about yourself</p>
				</div>
				<Row gutter={0}>
					<Col xs={24} md={24}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="avatar"
							label="Your Picture"
							rules={[
								{
									required: true,
									message: 'Your picture is required',
								},
							]}
						>
							<ImageUpload img={avatar} filename={updateFilename} />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={0}>
					<Col xs={24} md={24}>
						<Form.Item validateTrigger={'onBlur'} name="name" label="Full name">
							<Input size="large" placeholder="Please enter your Full name" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item validateTrigger={'onBlur'} name="organisation" label="Your Organisation">
							<Input size="large" placeholder="Please enter your Organisation" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item validateTrigger={'onBlur'} name="designation" label="Your Designation">
							<Input size="large" placeholder="Please enter your Designation" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name="gender"
							label="Gender"
							rules={[
								{
									required: true,
									message: 'Your gender is required',
								},
							]}
						>
							<Select placeholder="Gender" allowClear className={styles.dropdown}>
								<Option className={styles.dropdownValue} value="Male">
									Male
								</Option>
								<Option className={styles.dropdownValue} value="Female">
									Female
								</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name="city"
							label="City"
							rules={[
								{
									required: true,
									message: 'Your city is required',
								},
							]}
						>
							<Select placeholder="Select your city" allowClear className={styles.dropdown}>
								{cities.map((city) => {
									return (
										<Option className={styles.dropdownValue} value={city.title}>
											{city.title}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name="state"
							label="State"
							rules={[
								{
									required: true,
									message: 'Your city is required',
								},
							]}
						>
							<Select placeholder="Select your state" allowClear>
								{states.map((city) => {
									return <Option value={city.title}>{city.title}</Option>;
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name="country"
							label="Country"
							rules={[
								{
									required: true,
									message: 'Your country is required',
								},
							]}
						>
							<Select placeholder="Select your country">
								<Option value="pakistan">Pakistan</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="birthdate"
							label="Birthday"
							rules={[
								{
									required: true,
									message: 'Your birthday is required',
								},
							]}
						>
							<DatePicker placeholder="Please enter your birth date" style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>

				<div className={styles.ticketHead}>
					<h3>Education</h3>
					<hr />
					<p>Tell us a bit about your work experience so we can match you with potential opportunities.</p>
				</div>

				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							name="degree"
							label="Your Degree "
							rules={[
								{
									required: true,
									message: 'Your Degree is required',
								},
							]}
						>
							<Select placeholder="Bachelors" onSelect={getSecondarySkills}>
								<Option className={styles.dropdownValue} value="undergraduate">
									Undergraduate
								</Option>
								<Option className={styles.dropdownValue} value="graduate">
									Graduate
								</Option>
								<Option className={styles.dropdownValue} value="masters">
									Masters
								</Option>
								<Option className={styles.dropdownValue} value="doctorate">
									Doctorate
								</Option>
								<Option className={styles.dropdownValue} value="other">
									Other
								</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="field_of_study"
							label="Field of Study"
							rules={[
								{
									required: true,
									message: 'Your Field of study is required',
								},
							]}
						>
							<Input size="large" placeholder="Computer Science" />
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="institute"
							label="Your School/University"
							rules={[
								{
									required: true,
									message: 'Your School of study is required',
								},
							]}
						>
							<Input size="large" placeholder="Please tell us where you are studying" />
						</Form.Item>
					</Col>
				</Row>

				<div className={styles.ticketHead}>
					<h3>Social Details</h3>
					<hr />
					<p>How can people get in touch with you?</p>
				</div>

				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="title"
							label="Title"
							tooltip="Enter a single sentence description of your professional skills/experience (e.g. Expert Web Designer with Ajax experience)"
							rules={[
								{
									required: true,
									message: 'Your title is required',
								},
							]}
						>
							<Input size="large" placeholder="Please enter your title" />
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item validateTrigger={'onBlur'} name="website" label="Website">
							<Input size="large" placeholder="Please enter your website" />
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							validateTrigger={'onBlur'}
							name="linkedin"
							label="Linkedln URL"
							rules={[
								{
									required: true,
									message: 'Your linkedin URL is required',
								},
								{
									type: 'url',
									message: 'Your linkedin profile link must be a valid URL',
								},
							]}
						>
							<Input type="url" size="large" placeholder="Please enter your Linkedin URL" />
						</Form.Item>
					</Col>
				</Row>

				<div className={styles.ticketHead}>
					<h3>Work Details</h3>
					<hr />
					<p>Tell us a bit about your work experience so we can match you with potential opportunities.</p>
				</div>

				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							name="primary_skill"
							label="Your Primary Skill"
							rules={[
								{
									required: true,
									message: 'Your Primary Skill is a requirement',
								},
							]}
							tooltip="Please let us know your primary skill"
						>
							<Select placeholder="Please let us know your primary skill" onSelect={getSecondarySkills}>
								{primarySkills &&
									Object.keys(primarySkills).length > 0 &&
									Object.keys(primarySkills).map((key, index) => {
										return (
											<Option className={styles.dropdownValue} value={primarySkills[key].id}>
												{primarySkills[key].name}
											</Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>

					{primarySkill && (
						<Col xs={24} md={12}>
							<Form.Item
								name="secondary_skill"
								label="Your Secondary Skill"
								rules={[
									{
										required: true,
										message: 'Your Secondary Skill is a requirement',
									},
								]}
								tooltip="Please let us know your secondary skill"
							>
								<Select placeholder="Please enter your secondary skill">
									{secondarySkills &&
										Object.keys(secondarySkills).length > 0 &&
										Object.keys(secondarySkills).map((key, index) => {
											return (
												<Option className={styles.dropdownValue} value={secondarySkills[key].id}>
													{secondarySkills[key].name}
												</Option>
											);
										})}
								</Select>
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							name="years_experience"
							label="Professional Experience"
							rules={[
								{
									required: true,
									message: 'Your Professional Experience is a requirement',
								},
							]}
							tooltip="How many years have you been working professionally?"
						>
							<Select placeholder="Please enter your years of professional experience">
								<Option value="I haven't worked professionally before">I haven't worked professionally before</Option>
								<Option value="Between 1-10 years">Between 1-10 years</Option>
								<Option value="Between 10-20 years">Between 10-20 years</Option>
								<Option value="More than 20 years">More than 20 years</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							name="hourly_rate"
							label="Hourly Rate"
							rules={[
								{
									required: true,
									message: 'Your hourly rate is required',
								},
							]}
							tooltip="How much do you charge per hour on average in PKR."
						>
							<Input type="number" size="large" placeholder="1000 PKR" />
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							name="availability"
							label="Are you available to work?"
							rules={[
								{
									required: true,
									message: 'Your availability in hours is required',
								},
							]}
							tooltip="Let us know if you're available for a fulltime or a part time commitment"
						>
							<Select placeholder="Fulltime or Part time">
								<Option value="Full time">Full-time</Option>
								<Option value="Part time">Part-time</Option>
								<Option value="Not Available">Not Available</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col xs={24} md={12}>
						<Form.Item
							name="experience_level"
							label="Level of Experience"
							rules={[
								{
									required: true,
									message: 'Your level of experience is required',
								},
							]}
							tooltip="Let us know if you're relatively new, have substantial experience or have comprehensive and deep experience in these skills"
						>
							<Select placeholder="Please enter your years of professional experience">
								<Option value="beginner">Beginner</Option>
								<Option value="intermediate">Intermediate</Option>
								<Option value="expert">Expert</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={24} md={24}>
						<Form.Item
							name="bio"
							label="Tell us a bit about yourself and your expertise"
							rules={[
								{
									required: true,
									message: 'Your Overview is required',
								},
								{
									min: 100,
									message: 'Your overview is too small.',
								},
							]}
							tooltip="Use this space to show clients you have the skills and experience they're looking for."
						>
							<TextArea rows={8} maxLength={3000} />
						</Form.Item>
					</Col>
				</Row>

				<div className={styles.ticketHead}>
					<h3>Freelancing Profile</h3>
					<hr />
					<p>Provide us with your freelancing background so clients can see your work and reputation on these platforms.</p>
				</div>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="fiverr" style={{ marginBottom: '5px' }}>
							<Checkbox checked={fiverr} onChange={(e) => onFiverrChange(e.target.checked)}>
								I am a Freelancer on Fiverr
							</Checkbox>
						</Form.Item>
					</Col>

					{fiverr && (
						<Col xs={24} md={16}>
							<Form.Item
								name="fiverr_link"
								label="Fiverr Profile URL"
								rules={[
									{
										required: true,
										message: 'Your Fiverr profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
								style={{ marginBottom: '5px' }}
							>
								<Input type="url" size="large" placeholder="Please enter fiverr profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="upwork" style={{ marginBottom: '5px' }}>
							<Checkbox checked={upwork} onChange={(e) => onUpworkChange(e.target.checked)}>
								I am a Freelancer on Upwork
							</Checkbox>
						</Form.Item>
					</Col>

					{upwork && (
						<Col xs={24} md={16}>
							<Form.Item
								name="upwork_link"
								label="Upwork Profile URL"
								rules={[
									{
										required: true,
										message: 'Your Upwork profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
							>
								<Input type="url" size="large" placeholder="Please enter Upwork profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="freelancercom" style={{ marginBottom: '5px' }}>
							<Checkbox checked={freelancercom} onChange={(e) => onFreelancercomChange(e.target.checked)}>
								I am a Freelancer on Freelancer.com
							</Checkbox>
						</Form.Item>
					</Col>

					{freelancercom && (
						<Col xs={24} md={16}>
							<Form.Item name="freelancercom_link" label="Freelancer.com Profile URL" rules={[{ required: true }]}>
								<Input type="url" size="large" placeholder="Please enter Freelancer.com profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="guru" style={{ marginBottom: '5px' }}>
							<Checkbox checked={guru} onChange={(e) => onGuruChange(e.target.checked)}>
								I am a Freelancer on Guru
							</Checkbox>
						</Form.Item>
					</Col>

					{guru && (
						<Col xs={24} md={16}>
							<Form.Item
								name="guru_link"
								label="Guru Profile URL"
								rules={[
									{
										required: true,
										message: 'Your Guru profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
							>
								<Input type="url" size="large" placeholder="Please enter Guru profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="toptal" style={{ marginBottom: '5px' }}>
							<Checkbox checked={toptal} onChange={(e) => onToptalChange(e.target.checked)}>
								I am a Freelancer on Toptal
							</Checkbox>
						</Form.Item>
					</Col>

					{toptal && (
						<Col xs={24} md={16}>
							<Form.Item
								name="toptal_link"
								label="Toptal Profile URL"
								rules={[
									{
										required: true,
										message: 'Your Toptal profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
							>
								<Input type="url" size="large" placeholder="Please enter your Toptal profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="codeable" style={{ marginBottom: '5px' }}>
							<Checkbox checked={codeable} onChange={(e) => onCodeableChange(e.target.checked)}>
								I am a Freelancer on Codeable
							</Checkbox>
						</Form.Item>
					</Col>

					{codeable && (
						<Col xs={24} md={16}>
							<Form.Item
								name="codeable_link"
								label="Codeable Profile URL"
								rules={[
									{
										required: true,
										message: 'Your Codeable profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
							>
								<Input type="url" size="large" placeholder="Please enter your Codeable profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="peopleperhour" style={{ marginBottom: '5px' }}>
							<Checkbox checked={peopleperhour} onChange={(e) => onPeopleperhourChange(e.target.checked)}>
								I am a Freelancer on People Per Hour
							</Checkbox>
						</Form.Item>
					</Col>

					{peopleperhour && (
						<Col xs={24} md={16}>
							<Form.Item
								name="peopleperhour_link"
								label="People Per Hour Profile URL"
								rules={[
									{
										required: true,
										message: 'Your People Per Hour profile link is required',
									},
									{
										type: 'url',
										message: 'Your link must be a valid URL',
									},
								]}
							>
								<Input type="url" size="large" placeholder="Please enter your People Per Hour profile link" />
							</Form.Item>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={24} md={8}>
						<Form.Item name="otherplatform" style={{ marginBottom: '5px' }}>
							<Checkbox checked={otherPlatform} onChange={(e) => onOtherPlatformChange(e.target.checked)}>
								Other
							</Checkbox>
						</Form.Item>
					</Col>

					{otherPlatform && (
						<>
							<Row>
								<Col xs={24} md={12}>
									<Form.Item
										name="otherplatform_name"
										label="Name"
										rules={[
											{
												required: true,
												message: 'Your freelancing platform is required',
											},
										]}
									>
										<Input size="large" placeholder="Please enter name of the platform you use" />
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item
										name="otherplatform_link"
										label="Profile URL"
										rules={[
											{
												required: true,
												message: 'Your freelancing profile link is required',
											},
											{
												type: 'url',
												message: 'Your link must be a valid URL',
											},
										]}
									>
										<Input type="url" size="large" placeholder="Please enter link to the platform you use." />
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</Row>

				<Form.Item>
					<FormButton label="Submit" formLoading={formLoading} onFinish={onFinish} />
				</Form.Item>
			</Form>{' '}
		</>
	);
};

export default ProfileCompleteForm;
