import React from 'react';
import {Card} from 'antd';
import styles from '../../styles/freelance-fest/general.module.css'
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../helpers/constants';
const { Meta } = Card;

const BlogCard = ({name, designation, company, image, linkdln, type}) => {
    return (
        <div className={styles.speaker}>
            <div className={styles.speaker_inner}>
                <div className={styles.cover}>
                    {image ?
                        <img src={`${IMAGE_URL}freelance-fest/speakers/${image}`} alt=''/>
                    :
                        <img src={`${IMAGE_URL}freelance-fest/person.jpg`} alt=''/>
                    }
                </div>
                <div className={styles.details}>
                    <div className={styles.details_inner}>
                        <div className={styles.title_holder}>
                            {name ?
                                <div className={styles.speaker_name}>{name}</div>
                            :
                                <div className={styles.speaker_name}>TBA</div>
                            }
                            {type ?
                                <div className={styles.speaker_type}>{type}</div>
                            :
                                <div className={styles.speaker_type}>TBA</div>
                            }
                            {designation ?
                                <div className={styles.speaker_position}>{designation}</div>
                            :
                                <div className={styles.speaker_position}>Coming Soon</div>
                            }
                            {company ?
                                <div className={styles.speaker_company}>{company}</div>
                            :
                                <div className={styles.speaker_company}>Pakistan Freelancers Association</div>
                            }
                        </div>
                        {linkdln &&
                            <div className={styles.social_holder}>
                                <div className={styles.speaker_lnkdln}>
                                    <a href={linkdln} target="_blank">
                                        <img src="/img/linkedin.png" alt=""/>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;