import React from 'react'
import { Form, Row, Col, Input, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import { notification} from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities } from '../../FreelanceFest/constants'
import PriceFormatted from '../../PriceFormatted'

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;

const { Text } = Typography;

class LoginForm extends React.Component {
   
    render() {
        const { ticket } = this.props
        const isDiscounted = ticket && (ticket.discounted_price > 0) ? true : false
        return (
            <>
                {ticket &&
                    <div className={styles.shippingPreview}>
                        <div className={styles.shippingPreviewCol}>
                            <div className={styles.shippingPreviewDetailHead}>Shipping to</div>
                        </div>
                        <div className={styles.shippingPreviewCol}>
                            <div className={styles.shippingPreviewDetail}>
                                <span className={styles.shippingPreviewInfo}>{ticket && ticket.first_name} {ticket && ticket.last_name}</span>
                            </div>
                            <div className={styles.shippingPreviewDetail}>
                                <span className={styles.shippingPreviewInfo}>{ticket && ticket.email}</span>
                            </div>
                            <div className={styles.shippingPreviewDetail}>
                                <span className={styles.shippingPreviewInfo}>{ticket && ticket.phone_number}</span>
                            </div>
                            <div className={styles.shippingPreviewDetail}>
                                <span className={styles.shippingPreviewInfo}>{ticket && ticket.physical_address}, {ticket && ticket.city}, {ticket && ticket.state}, {ticket && ticket.country}</span>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}


export default withRouter(LoginForm);
