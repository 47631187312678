import React, { useEffect, useState } from 'react';
import { Row, Spin, Alert } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';
import quizStyles from '../../styles/quizStyles.module.css';
import pafla from '../../api/pafla';
import { Link, withRouter } from 'react-router-dom';
import { LoadingOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

class StartQuiz extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			course: null,
			loading: true,
			eligible: false,
			message: null,
			result: null,
			certificateLoading: false,
			post_program_questionnaire_link: null,
		};
	}

	async componentDidMount() {
		const fetchQuizResult = async () => {
			try {
				const { attemptId } = this.props.match.params;

				await pafla.get(`get-quiz-result/${attemptId}`).then((response) => {
					this.setState({ result: response.data.data });
					this.setState({ loading: false });
					this.setState({ post_program_questionnaire_link: response.data.data.post_program_questionnaire_link });
					console.log(response);
				});
			} catch (error) {
				console.error('Error fetching data:', error);
				this.setState({ loading: false });
			}
		};
		fetchQuizResult();
	}

	downloadCertificate = async () => {
		this.setState({ certificateLoading: true });
		try {
			const { attemptId } = this.props.match.params;

			await pafla.get(`get-quiz-certificate/${attemptId}`).then((response) => {
				this.setState({ certificateLoading: false });
				if (response.data.data.certificate_url) {
					window.open(response.data.data.certificate_url, '_blank', 'noreferrer');
				}
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			this.setState({ certificateLoading: false });
		}
	};

	openFeedbackForm = async () => {
		const { post_program_questionnaire_link } = this.state;
		if (post_program_questionnaire_link) {
			window.open(post_program_questionnaire_link, '_blank', 'noreferrer');
		}
	};

	render() {
		const { result, loading, certificateLoading, post_program_questionnaire_link } = this.state;
		return (
			<Dashboard loading={this.state.loading} completeProfileCheck={false}>
				<Row>
					<SectionBody width="24">
						{result && result?.quiz_cleared && (
							<>
								<h2 className={`${quizStyles.quizResultIcon}`}>
									<CheckCircleFilled />
								</h2>
								<h2 className={`${quizStyles.quizResultHeading}`}>Congratulations! You have passed the quiz for {result.course}!</h2>
								{result && post_program_questionnaire_link && (
									<Alert
										message="Your feedback is invaluable to us. Please take a moment to complete our quick questionnaire. It will help us make future sessions even better!"
										type="info"
										showIcon
										onClick={this.openFeedbackForm}
										className={`${quizStyles.quizResultFeedbackForm}`}
									/>
								)}
								<table className={`${quizStyles.quizTable} ${quizStyles.quizResultTable}`}>
									<tr>
										<th>Quiz Detail</th>
										<th>Information</th>
									</tr>
									<tr>
										<td>Attempt</td>
										<td>
											{result.attempts}/{result.allowed_attempts}
										</td>
									</tr>
									<tr>
										<td>Your Score</td>
										<td>
											{result.scored_points}/{result.total_points}
										</td>
									</tr>
									<tr>
										<td>Status</td>
										<td>{result.status}</td>
									</tr>
								</table>
								<div className={`${quizStyles.quizBtnContainer}`}>
									<span onClick={this.downloadCertificate} className={`btn_default ${quizStyles.quizResultBtn} ${quizStyles.certificateButton}`}>
										{!certificateLoading && 'Download your Certificate'}
										{certificateLoading && (
											<Spin indicator={<LoadingOutlined style={{ fontSize: 14, color: '#fff' }} spin />} style={{ padding: '0px 80px' }} />
										)}
									</span>
								</div>
							</>
						)}
						{result && !result?.quiz_cleared && (
							<>
								<h2 className={`${quizStyles.quizResultIcon} ${quizStyles.quizResultIconFailed}`}>
									<CloseCircleOutlined />
								</h2>
								<h2 className={`${quizStyles.quizResultHeading}`}>Uh oh! Unfortunately, you did not pass the quiz for the {result.course}!</h2>
								{result && post_program_questionnaire_link && result.attempts >= result.allowed_attempts && (
									<Alert
										message={
											<>
												Your feedback is invaluable. Please <u>click here</u> to complete our quick questionnaire to help us improve future sessions
												for you!
											</>
										}
										type="info"
										showIcon
										onClick={this.openFeedbackForm}
										className={`${quizStyles.quizResultFeedbackForm}`}
									/>
								)}

								<table className={`${quizStyles.quizTable} ${quizStyles.quizResultTable}`}>
									<tr>
										<th>Quiz Detail</th>
										<th>Information</th>
									</tr>
									<tr>
										<td>Attempt</td>
										<td>
											{result.attempts}/{result.allowed_attempts}
										</td>
									</tr>
									<tr>
										<td>Your Score</td>
										<td>
											{result.scored_points}/{result.total_points}
										</td>
									</tr>
									<tr>
										<td>Status</td>
										<td>{result.status}</td>
									</tr>
								</table>
								{result?.attempts_remaining && (
									<div className={`${quizStyles.quizBtnContainer}`}>
										<Link to={`/dashboard/get-certified/${result.course_id}`} className={`btn_default ${quizStyles.quizResultBtn}`}>
											Try Again
										</Link>
									</div>
								)}
							</>
						)}
						{!result && !loading && <h2 className={`${quizStyles.quizResultHeading}`}>No attempt found</h2>}
					</SectionBody>
				</Row>
			</Dashboard>
		);
	}
}

export default withRouter(StartQuiz);
