import React from 'react';
import { Col, Row, Typography } from 'antd';
import styles from '../../styles/statistics.module.css';
import StatisticsCard from '../StatisticsCard';
import { IMAGE_URL } from '../../helpers/constants';

const { Title, Paragraph } = Typography;

const Statistics = () => {
	return (
		<section className={styles.statistics} id="statistics">
			<Row>
				<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
					<Row justify="space-between" gutter={20}>
						<Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 7 }} className={styles.statistics_heading}>
							<Title level={2} className="section-title">
								Be part of a global economy
							</Title>
							<Paragraph className="section-text">
								Independents aren’t just shaping the future of work, they are the backbone of the economy.
							</Paragraph>
						</Col>
						<Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }} xl={{ span: 5, offset: 2 }} style={{ margin: '0 auto' }}>
							<StatisticsCard image={`${IMAGE_URL}community.png`} statistic="1 Million" title="Freelancers in Pakistan" />
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }} style={{ margin: '0 auto' }}>
							<StatisticsCard image={`${IMAGE_URL}sponsors.png`} statistic="10+" title="Billion Hours" />
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }} style={{ margin: '0 auto' }}>
							<StatisticsCard image={`${IMAGE_URL}loans.png`} statistic="$500M" title="Services provided annually" />
						</Col>
					</Row>
				</Col>
			</Row>
		</section>
	);
};

export default Statistics;
