import React from 'react';
import styles from '../../styles/dashboard.module.css';
import { WifiOutlined } from '@ant-design/icons';
import { IMAGE_URL } from '../../helpers/constants';
import {Row, Col} from 'antd';

const SectionHeader = (props) => {
    return (
        <div className={styles.membershipCard}>
            <div className={styles.membershipCardIcon}>
                <WifiOutlined />
            </div>
            <div className={styles.membershipId}>{props.data.member_id_formatted}</div>
            <div className={styles.membershipDetails}>
                <div className={styles.membershipDetail}>
                    <p className={styles.heading}>Holder</p>
                    <p className={styles.detail}>{props.data.member_name}</p>
                </div>
                <div className={styles.membershipDetail}>
                    <p className={styles.heading}>Since</p>
                    <p className={styles.detail}>{props.data.membership_start_date_formatted}</p>
                </div>
                <div className={styles.membershipDetail}>
                    <p className={styles.heading}>Expires</p>
                    <p className={styles.detail}>
                        {props.data.membership_end_date_formatted ? props.data.membership_end_date_formatted : "-" }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SectionHeader;
