import React from 'react';
import { Link } from 'react-router-dom'
import Dashboard from '../../components/dashboard/Main';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import Loader from '../../components/Loader';
import pafla from '../../api/pafla'
import styles from '../../styles/issues.module.css'
import Cookies from 'js-cookie';
import { List, Tag, Button, Typography, Row } from 'antd';
import { Helmet } from "react-helmet"
import { NEW_ISSUE } from '../../helpers/constants'
const { Paragraph } = Typography;


class Issues extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        issues: [],
            loading: false,
            hasMore: true,
	    }
	}

	componentDidMount() {

        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
            pafla.get('/user/issues')
            .then((response) => {
                this.setState({issues: response.data.data})
            })
        } else {
            this.props.history.push('/')
        }
    }

    renderListItem(issue) {
        return(
            <List.Item key={issue.id}>
                <List.Item.Meta
                    title={<Link to={`/dashboard/issues/${issue.id}`}>{issue.title}</Link>}
                    description={
                        <Paragraph ellipsis='rows: 2, expandable: false'>
                            {issue.description}
                        </Paragraph>
                    }
                />
                <Link to={`/dashboard/issues/${issue.id}`}>
                    {issue.status &&
                        <div className='issue-tag'>
                            <Tag>{issue.status.name}</Tag>
                        </div>
                    }
                </Link>
                
            </List.Item>
        )        
    }


    render() {
        const { loading } = this.state
        return (
        	<Dashboard                
                loading={loading}
                completeProfileCheck={true}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Issues - Pakistan Freelancers Association</title>
                </Helmet>
                <Row>   
                <SectionBody>
                    <SectionHeader>
                        <h2>Your Issues</h2>
                        <Button type="primary" className={`ant-btn-orange ${styles.new_issue_button}`}>
                            <Link to={NEW_ISSUE}>New Issue</Link>
                        </Button>
                    </SectionHeader>
                    <List
                        dataSource={this.state.issues}
                        renderItem={issue => this.renderListItem(issue)}
                      >
                        {this.state.loading && this.state.hasMore && (
                            <Loader/>
                        )}
                    </List>
                </SectionBody>
                </Row>
            </Dashboard>
        );
    }
}

export default Issues;
        