import React, { useState, useRef } from 'react';
import { Divider, Tag, Typography } from 'antd';
import { Markup } from 'interweave';
import styles from '../../styles/member.module.css'
import { isMobile } from 'react-device-detect'

const { Paragraph } = Typography;

const MemberProfile = (props) => {

    const [ellipsis, setEllipsis] = useState(true)
    const scrollDiv = useRef();

    const typoClose = () => {
        setEllipsis(!ellipsis)
        if (!ellipsis) {
            scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <>
            <div ref={scrollDiv} className={styles.memberProfileOverview}>
                <div className={styles.skills}>
                    {props.skills && props.skills.map((skill) => {
                        return (
                            <Tag
                                style={{
                                  backround: 'lightgrey',
                                  cursor: 'pointer',
                                  padding: '5px 10px',
                                  borderRadius: '5px'
                                }}
                            >
                                {skill.name}
                            </Tag>
                        )
                    })}
                </div>
                <Paragraph ellipsis={ellipsis ? { rows: 4 } : false}><Markup className={styles.overview} content={props.bio}/></Paragraph> 
                <span className={styles.typoExpand} onClick={typoClose}>{ellipsis ? 'More' : 'Less'}</span>
            </div>
            {isMobile && <Divider className={styles.mobileDivdier}/>}
        </>
    );
};

export default MemberProfile;
