import React from 'react';
import styles from '../../styles/community.module.css';
import {Col, Row, Typography} from 'antd';
import CommunityCard from '../CommunityCard';
import {IMAGE_URL} from '../../helpers/constants';
import {Rotate} from 'react-reveal';
import Title from '../Title';

const Community = () => {
    return (
        <section className={styles.community} id="community">
            <Row justify="space-between">
                <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                    <Title
                        title="Our Community"
                        subtitle="Get an insight on the awesome PAFLA community. Keep track on the upcoming events, workshops, bootcamps industry updates, skills and news through our awesome community."
                    />
                </Col>
                <Rotate bottom right>
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}} className={styles.card_wrapper}>

                        <CommunityCard
                            image={`${IMAGE_URL}pafla-events.jpg`}
                            title="PAFLA Events"
                            text="Meet freelancers in cities across the country to network, workshop your business, and build your freelance community."
                            color="blue"
                            link='/events'
                            linktext="All Events"
                        />
                        <CommunityCard 
                            image={`${IMAGE_URL}pafla-community.jpg`} 
                            color="green" 
                            title="Community Blog"
                            text="Make your voice heard and share your knowledge with your fellow freelancers by contributing to the #1 blog for freelancers. Or, read up and learn something new."
                            link='/blog'
                            linktext="All Blogs"
                        />
                    </Col>
                </Rotate>
            </Row>
        </section>
    );
};

export default Community;
