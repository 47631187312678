import { IMAGE_URL } from './constants';

export const courses = [
	{
		id: 1,
		imageSrc: `${IMAGE_URL}google-cybersecurity.png`,
		name: 'Cybersecurity',
		description: 'Cybersecurity analysts are responsible for monitoring and protecting information and systems. ',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Cybersecurity Impact Awareness',
				description: 'Understand the importance of cybersecurity practices and their impact for organizations.',
			},
			{
				name: 'Risk Identification & Mitigation',
				description: 'Identify common risks, threats, and vulnerabilities, as well as techniques to mitigate them.',
			},
			{
				name: 'Cyberattack Protection Strategies',
				description:
					'Protect networks, devices, people, and data from unauthorized access and cyberattacks using Security Information and Event Management (SIEM) tools.',
			},
			{
				name: 'Hands-On Cybersecurity Experience',
				description: 'Gain hands-on experience with Python, Linux, and SQL.',
			},
		],
	},
	{
		id: 2,
		imageSrc: `${IMAGE_URL}google-business_intelligence.png`,
		name: 'Business Intelligence',
		description:
			'Business intelligence professionals help organizations collect, structure, interpret, and report on business data to help stakeholders make informed decisions.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'BI Professional Roles Exploration',
				description: 'Explore the roles of business intelligence (BI) professionals within an organization.',
			},
			{
				name: 'Data Modeling & ETL Practices',
				description: 'Practice data modeling and extract, transform, load (ETL) processes that meet organizational goals.',
			},
			{
				name: 'Data Visualization Design',
				description: 'Design data visualizations that answer business questions',
			},
			{
				name: 'Dashboard Creation',
				description: 'Create dashboards that effectively communicate data insights to stakeholders',
			},
		],
	},
	{
		id: 3,
		imageSrc: `${IMAGE_URL}google-advanced_data_analytics.png`,
		name: 'Advanced Data Analytics',
		description:
			'Advanced data analysts and data scientists are responsible for collecting, analyzing, and interpreting extremely large amounts of data within organizations.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Data Professional Roles Exploration',
				description: 'Explore the roles of data professionals within an organization.',
			},
			{
				name: 'Data Visualization Creation',
				description: 'Create data visualizations and apply statistical methods to investigate data',
			},
			{
				name: 'Regression & ML Model Building',
				description: 'Build regression and machine learning models to analyze and interpret data',
			},
			{
				name: 'Insightful Data Communication',
				description: 'Communicate insights from data analysis to stakeholders',
			},
		],
	},
	{
		imageSrc: `${IMAGE_URL}google-data_analytics.png`,
		name: 'Data Analytics',
		description: 'Data analysts collect, transform, and organize data in order to help make informed business decisions.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Junior Data Analyst Practices',
				description:
					'Gain an immersive understanding of the practices and processes used by a junior or associate data analyst in their day-to-day job',
			},
			{
				name: 'Analytical Skills & Tools Mastery',
				description: 'Learn key analytical skills (data cleaning, analysis, & visualization) and tools (spreadsheets, SQL, R programming, Tableau)',
			},
			{
				name: 'Data Cleaning & Organization Techniques',
				description:
					'Understand how to clean and organize data for analysis, and complete analysis and calculations using spreadsheets, SQL and R programming',
			},
			{
				name: 'Data Visualization & Presentation Skills',
				description: 'Learn how to visualize and present data findings in dashboards, presentations and commonly used visualization platforms',
			},
		],
	},
	{
		imageSrc: `${IMAGE_URL}google-it_support.png`,
		name: 'IT Support',
		description: 'IT Specialists troubleshoot problems so computers and networks run correctly.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Entry-Level IT Skills',
				description: 'Gain skills required to succeed in an entry-level IT job.',
			},
			{
				name: 'IT Support Task Mastery',
				description:
					'Learn to perform day-to-day IT support tasks including computer assembly, wireless networking, installing programs, and customer service.',
			},
			{
				name: 'Customer Support Expertise',
				description: 'Learn how to provide end-to-end customer support, ranging from identifying problems to troubleshooting and debugging.',
			},
			{
				name: 'Proficiency in IT Systems',
				description: 'Learn to use systems including Linux, Domain Name Systems, Command-Line Interface, and Binary Code.',
			},
		],
	},
	{
		imageSrc: `${IMAGE_URL}google-it_automation.png`,
		name: 'IT Automation',
		description: 'Build on your IT foundations and learn in-demand skills including Python, Git, and IT automation.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Python Script Automation',
				description: 'Automate tasks by writing Python scripts.',
			},
			{
				name: 'Git Version Control',
				description: 'Use Git and GitHub for version control.',
			},
			{
				name: 'Scalable IT Resource Management',
				description: 'Manage IT resources at scale, both for physical machines and virtual machines in the cloud.',
			},
			{
				name: 'IT Strategy Analysis',
				description: 'Analyze real-world IT problems and implement the appropriate strategies to solve those problems.',
			},
		],
	},
	{
		imageSrc: `${IMAGE_URL}google-digital_marketing_ec.png`,
		name: 'Digital Marketing and E-Commerce',
		description: 'Digital marketers use online tools to reach customers. E-commerce specialists grow online sales.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Fundamentals of Digital Marketing',
				description: 'Learn the fundamentals of digital marketing and e-commerce to gain the skills needed to land an entry-level job.',
			},
			{
				name: 'Customer Attraction Techniques',
				description: 'Attract and engage customers through digital marketing channels like search and email.',
			},
			{
				name: 'Performance Measurement Analytics',
				description: 'Measure marketing performance through analytics and present insights.',
			},
			{
				name: 'E-commerce Store Building',
				description: 'Build e-commerce stores, analyze online performance, and grow customer loyalty.',
			},
		],
	},
	{
		imageSrc: `${IMAGE_URL}google-ux_design.png`,
		name: 'UX Design',
		description: 'UX designers make digital and physical products easier and more enjoyable to use.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Design Process Mastery',
				description:
					'Follow the design process: empathize with users, define pain points, ideate solutions, create wireframes and prototypes, test and iterate on designs.',
			},
			{
				name: 'UX Research Basics',
				description:
					'Understand the basics of UX research, like planning research studies, conducting interviews and usability studies, and synthesizing research results.',
			},
			{
				name: 'Foundational UX Concepts',
				description: 'Apply foundational UX concepts, like user-centered design, accessibility, and equity-focused design.',
			},
			{
				name: 'Risk Identification Techniques',
				description: 'Identify common risks, threats, and vulnerabilities, as well as techniques to mitigate them.',
			},
			{
				name: 'Professional UX Portfolio',
				description:
					'Create a professional UX portfolio that includes 3 end-to-end projects: a mobile app, a responsive website, and a cross-platform experience.',
			},
		],
	},

	{
		imageSrc: `${IMAGE_URL}google-project_management.png`,
		name: 'Project Management',
		description: 'Project managers ensure projects within an organization are managed and completed with maximum value.',
		button2Text: 'Registrations Closed',
		button2Link: '#',
		features: [
			{
				name: 'Entry-Level Project Management Mastery',
				description: 'Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role.',
			},
			{
				name: 'Effective Project Documentation Creation',
				description: 'Learn how to create effective project documentation and artifacts throughout the various phases of a project.',
			},
			{
				name: 'Agile Project Management Foundations',
				description:
					'Learn the foundations of Agile project management, with a focus on implementing Scrum events, building Scrum artifacts, and understanding Scrum roles.',
			},
			{
				name: 'Strategic Communication & Stakeholder Management Practice',
				description: 'Practice strategic communication, problem-solving, and stakeholder management through real-world scenarios.',
			},
		],
	},
];

export const caseStudies = [
	{
		id: 1,
		name: 'Afreen Rahat',
		designation: 'Freelancer',
		quote:
			'Google Career Certificates paved my path to success, fostering knowledge and self-belief. Through the program I signed up for Data Analytics and Digital Marketing courses, captivated by real-world scenarios and expertise of teachers. Today I am a thriving freelancer, and share expertise through a blog with others.  and dream of empowering women in entrepreneurship.',
		video_url: 'https://www.youtube.com/embed/SJP0-Vp9wg0',
	},
	{
		id: 4,
		name: 'Tooba Ahmed Alvi',
		designation: 'Programmer Analyst',
		quote:
			"The 'Data Analytics' course fueled my journey from being a student to landing my first ever job as a programmer analyst at a top university. Thanks to the program, I am now able to make meaningful contributions, and feel confident in my work. I can't wait to see where my career takes me!",
		video_url: 'https://www.youtube.com/embed/dR2l-XOk4P4',
	},
	{
		id: 3,
		name: 'Muhammad Sajid',
		designation: 'Digital Marketing Manager ',
		quote:
			"The program transformed my digital marketing career. The Digital Marketing & E-Commerce course boosted my confidence, leading to a promotion as Social Media Manager with a significant salary increase. I am now also consulting local companies, and I'm thrilled to apply my skills and advance further.",
		video_url: 'https://www.youtube.com/embed/GeRkiuQBlyg',
	},
	{
		id: 2,
		name: 'Arooba Shahid',
		designation: 'Digital Marketing Intern',
		quote:
			'This program was a game-changer for me. It helped me transform my career, and I was able to make a complete career switch away from physiotherapy. After the program, I found a job with an international company in the field of Digital Marketing and E-Commerce. I am truly excited about my future as a digital marketer!',
		video_url: 'https://www.youtube.com/embed/LUiJRqJWUDM',
	},
];

export const faqs = [
	{
		id: 1,
		question: 'How do I qualify for a scholarship with Google Career Certificates?',
		answer:
			'You can apply for our scholarship program by filling out the detailed form on the website. PAFLA will shortlist candidates who are the most deserving.',
	},
	{
		id: 2,
		question: 'How many scholarships would be awarded through this program?',
		answer: 'Google and PAFLA are giving away 500 scholarships through this program.',
	},
	{
		id: 3,
		question: 'Is a college degree necessary to apply for a scholarship?',
		answer: 'No, you do not need any prior degree to get enrolled in the Google Career Certificate program. ',
	},
	{
		id: 4,
		question: 'As an independent freelancer, how can I enroll in Google Career Certificate?',
		answer:
			'If you want to apply for the GCC scholarship program, you will have to become a PAFLA member first, then fill out the GCC Scholarship program here.',
	},
	{
		id: 5,
		question: 'What does the soft skills program entail, and where can I find more information?',
		answer:
			'During or after the completion of your professional certificate program, you as a scholar will be invited to join our soft skills program which focuses on building your communication, personal branding, time management, and critical thinking skills.',
	},
	{
		id: 6,
		question: 'What prerequisites are required to start the Google Career Certificates?',
		answer:
			'None. The courses are designed in a way to help any person who is fresh to the field, or someone who has some experience - a GCC would be helpful.',
	},
	{
		id: 7,
		question: 'Are Google Career Certificates entirely conducted online?',
		answer: 'Yes, you can attend the GCC from anywhere and anytime in the world, you do not need to show up to any classroom in person.',
	},
	{
		id: 8,
		question: 'Where can I find the courses for Google Career Certificates',
		answer:
			'Google Career Certificates are available globally in English on Coursera. But if you would like to avail a scholarship for these certificates, you should apply here',
	},
	{
		id: 9,
		question: 'What additional support is accessible post-completion of a Google Career Certificate?',
		answer: 'You can always get in touch with us through our social channels or email us at support@pafla.org.pk',
	},
];

export const checklist = [
	{
		name: 'Become as a PAFLA member and complete your profile.',
	},
	{
		name: 'Fill out our Scholarship form and the subject that interests you.',
	},
	{
		name: 'We will assess applications and select the top freelancers. Check selection criteria for scholarships <u>here</u>.',
		link: '#screening-criteria',
	},
	{
		name: 'Get selected as a top freelancer and earn a scholarship.',
	},
	{
		name: 'Complete the program at your own pace and earn a certificate within the next 6 months.',
	},
	{
		name: 'Elevate your soft skills (i.e. communication skills, personal branding) through our 4 weeks Soft Skills Program.',
	},
	{
		name: 'Strengthen your professional profile with a globally recognized Google Career Certificate.',
	},
];
