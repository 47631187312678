import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd';
import styles from '../../styles/home.module.css'
import pafla from '../../api/pafla'
import { Typography } from 'antd';

const { Paragraph } = Typography;

const FeaturedRelease = ({ release }) => {
    return(
        <div className={`${styles.pr_item}`}>
            <div className={styles.pr_item_inner}>
                <div className={styles.cover}>
                    <img src={`${process.env.REACT_APP_STORAGE_URL}${release.featured_image}`} alt={release.name}/>
                </div>
                <div className={styles.pr_details}>
                    <h5>Press Release</h5>
                    <Paragraph ellipsis={{ rows: 1 }}>{release.title}</Paragraph> 
                    <Paragraph className={styles.Prtext} ellipsis={{ rows: 2 }}>{release.excerpt}</Paragraph>             
                    <Link to={`press-release/${release.slug}/${release.id}`}>Read More</Link>
                </div>
            </div>
        </div>
    )
}

const InThePress = ({sectionId}) => {

    const [pr, setPr] = useState(null)

    useEffect(() => {
        if (!pr) {
            getFeaturedPrs()
        }
    });

    const getFeaturedPrs = async () => {

        await pafla.get('featured-pressreleases')
            .then((response) => {
                setPr(response.data)
            })
    }

    return (
        <section id={sectionId} className={`${styles.section} ${styles.sponsors}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>In the Press</h1>
                            <h2>An overview of the stories making the Pakistan Freelancers Association in headlines. We gratefully acknowledge the generosity of our media outlets for covering PAFLA.</h2>
                        </div>
                        <div className={styles.pr_items}>
                            {pr && 
                                Object.entries(pr).map(([key, pr], i) => {
                                    return <FeaturedRelease release={pr}/>
                                })
                            }
                        </div>
                        <Link className={styles.viewAll} to="/press-releases">View All</Link>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default InThePress;
