export const nav = [
	{
		id: '1',
		link: '#overview',
		name: 'Overview'
	},
	{
		id: '1',
		link: '#speakers',
		name: 'Speakers'
	},
	{
		id: '1',
		link: '#schedule',
		name: 'Schedule'
	},
	{
		id: '1',
		link: '#packages',
		name: 'Packages'
	},
	/*{
		id: '1',
		link: '#sponsors',
		name: 'Partners'
	},*/
	{
		id: '1',
		link: '#faqs',
		name: 'FAQs'
	}
]


export const agendaDayOne = [
	{
		startTime: '9:00 AM ',
		endTime: '10:55 AM',
		topicTitle: 'Networking, stall visits, etc.',
		topicDescription: 'Get to know the space'
	},
	{
		startTime: '11:00 AM ',
		endTime: '11:55 AM',
		topicTitle: 'Audience Settling',
		topicDescription: 'Ensuring the audience has taken their seats'
	},
	{
		startTime: '10:55 AM ',
		endTime: '11:05 AM',
		topicTitle: 'Speech by Delegatory',
		topicDescription: 'Keynote speach by our Delegatory'
	},
	{
		startTime: '11:05 AM ',
		endTime: '11:20 AM',
		topicTitle: 'Speech by Delegatory',
		topicDescription: 'Keynote speach by our Delegatory'
	},
	{
		startTime: '11:20 AM ',
		endTime: '11:35 AM',
		topicTitle: 'Speech by Chief Guest',
		topicDescription: 'Keynote speach by our Chief Guest'
	},
	{
		startTime: '11:35 AM',
		endTime: '11:45 AM',
		topicTitle: 'Tea Break',
		topicDescription: 'Tea break for our attendees with networking opportunities'
	},
	{
		startTime: '11:45 AM',
		endTime: '12:00 AM',
		topicTitle: 'Supporting the Financial Lifecycle of a Contingent Workforce ',
		topicDescription: 'As the gig economy grows and more individuals turn to more flexible ways of working, financial institutions have a unique opportunity to up their game and address the unique financial needs of this emerging segment. Our panel of seasoned bankers explain how a financial institution can be a trusted partner to a freelancer today, a startup tomorrow and a prosperous enterprise thereafter.'
	},
	{
		startTime: '12:00 PM',
		endTime: '12:30 PM',
		topicTitle: 'The Legal, Policy and Regulatory Future of the Gig Economy',
		topicDescription: 'The gig economy is increasingly in the crosshairs of policy debate about the benefits and challenges of more flexible ways of working. What changes in laws and regulations are needed to ensure that workers, enterprise organizations and the surrounding ecosystem can continue to thrive into the future? Our panel of legal and policy experts will debate the changes needed and discuss the next generation policy debate at the national, state, local and even global level.'
	},
	{
		startTime: '01:00 PM',
		endTime: '02:00 PM',
		topicTitle: 'Lunch Break',
		topicDescription: 'Lunch break for our attendees with networking opportunities'
	},
	{
		startTime: '02:00 PM',
		endTime: '02:30 PM',
		topicTitle: 'A Safety-net for the Independent Workforce',
		topicDescription: 'In this unique era of globalization through technology, the world is transforming into a global village that knows no border. As this erratic shift happens, the modern workforce finds itself to be facing a unique set of challenges around safety and benefits. What are the properties and why a safety net is important are some questions we take to this panel of experts. '
	},
	{
		startTime: '02:30 PM',
		endTime: '02:40 PM',
		topicTitle: 'Special Announcement',
		topicDescription: 'A special announcement by one of our partners exclusively for our attendees attending the event at the conference'
	},
	{
		startTime: '02:40 PM',
		endTime: '03:10 PM',
		topicTitle: 'Freelancers’ Perspective to Leading in a New World of Work ',
		topicDescription: 'The pandemic and the resulting shortage of labor that is widespread and showing no signs of ending is a reminder of the critical importance of freelancers, independent contractors and other gig economy talent in the successful contingent workforce. What do they want from the gig economy and contingent work? And how can you make sure that your organization delivers best in class services and opportunities for these critical workers? We will hear directly from a variety of freelancers, independent contractors and consultants on what works for them, what their major challenges are and how to ensure you get the best from them.'
	},
	{
		startTime: '03:10 PM',
		endTime: '03:40 PM',
		topicTitle: 'Freelancer today, Founder tomorrow',
		topicDescription: 'Amazing business ideas need a solid stage to get off the ground and rapidly scale. Learn from our panelists on the countless ways to succeed by leveraging the increasing number of platforms available today.'
	},
	{
		startTime: '03:40 PM',
		endTime: '04:10 PM',
		topicTitle: 'Optimizing Talent Management for the Networked Age ',
		topicDescription: 'As traditional economic and social pillars of the industrial age have come to an end, it is time for companies to rethink recruiting, compensation, training, collaboration, retention and the management of talent, as part of a new, networked model. As we navigate our way to a post-pandemic world, are hybrid cultures inevitable and what it takes to build and sustain remote cultures are some questions we take to our expert panelists'
	},
	{
		startTime: '04:10 PM',
		endTime: '04:40 PM',
		topicTitle: 'Reinventing the Talent Supply Chain for Online-Enabled Work ',
		topicDescription: 'As the world of work changes rapidly and the fast growth of technological platforms transforms how people view work, the innovation and disruption in the talent supply chain continues at rapid speed. As the era of globalization continues academic institutions face a great challenge to cater to the demands of a global market. Our panelists from academic institutions add the steps being taken to bridge demands and what challenges they face along the way.'
	},
	{
		startTime: '04:40 PM',
		endTime: '05:00 PM',
		topicTitle: 'Special Announcement',
		topicDescription: 'A special announcement by one of our partners exclusively for our attendees attending the event at the conference'

	},
]

export const agendaDayTwo = [
	{
		startTime: '9:00 AM ',
		endTime: '10:45 AM',
		topicTitle: 'Workshops',
		topicDescription: "Our second day's workshops will be announced soon"
	},
]

export const packages = [
	{
		name: 'Individual',
		memberPrice: "Free",
		memberPriceDiscounted: "",
		discountText: 'Entry is free!',
		buttonText: 'Register Now',
		buttonLink: '/enroll/event/5/package/09',
		description: "Includes access to two days of talks across the conference day",
		features: [
			{
				name: 'Full conference Access'
			},
			{
				name: 'Conference Swags'
			},
			{
				name: '1 on 1 Networking'
			},
			{
				name: 'Meet your mentors'
			}
		]
	},
	{
		name: 'Enterprise',
		buttonText: 'Get in touch',
		buttonLink: 'mailto:hello@pafla.org.pk',
		buttonType: 'link',
		description: "Includes access to two days of talks across the conference day and a lunch",
		features: [
			{
				name: 'Dedicated Table'
			},
			{
				name: 'Affiliate Branding & Promotions'
			},
			{
				name: 'Exhibition Stalls'
			},
			{
				name: 'Speakers Lounge'
			}
		]
	},
]

export const faqs = [
	{
		id: 1,
		question: 'What is Freelance Fest 2022',
		answer: 'FreelanceFest is the largest conference where the world’s 4th fastest growing gig-economy gathers to connect with industry leaders and mentors'
	},
	{
		id: 2,
		question: 'Who can attend the conference?',
		answer: 'The conference is for anyone who considers themselves to be an individual creator, future leader, or risk-taker. Whether you’re interested in learning more about the gig-economy ecosystem in Pakistan, you are a freelancer, or have your own startup, you’re welcome to attend!'
	},
	{
		id: 3,
		question: 'How can I apply to attend the conference?',
		answer: 'You can attend the conference by buying the tickets from above.'
	},
	{
		id: 4,
		question: 'Do I need a pass to attend?',
		answer: 'Yes, you need to buy a ticket beforehand to attend the conference.'
	}
]

export const cities = [
	{ title: 'Karachi' },
	{ title: 'Lahore' },
	{ title: 'Faisalabad' },
	{ title: 'Rawalpindi' },
	{ title: 'Gujranwala' },
	{ title: 'Peshawar' },
	{ title: 'Multan' },
	{ title: 'Saidu Sharif' },
	{ title: 'Hyderabad City' },
	{ title: 'Islamabad' },
	{ title: 'Quetta' },
	{ title: 'Bahawalpur' },
	{ title: 'Sargodha' },
	{ title: 'Sialkot City' },
	{ title: 'Sukkur' },
	{ title: 'Larkana' },
	{ title: 'Chiniot' },
	{ title: 'Shekhupura' },
	{ title: 'Jhang City' },
	{ title: 'Dera Ghazi Khan' },
	{ title: 'Gujrat' },
	{ title: 'Rahimyar Khan' },
	{ title: 'Kasur' },
	{ title: 'Mardan' },
	{ title: 'Mingaora' },
	{ title: 'Nawabshah' },
	{ title: 'Sahiwal' },
	{ title: 'Mirpur Khas' },
	{ title: 'Okara' },
	{ title: 'Mandi Burewala' },
	{ title: 'Jacobabad' },
	{ title: 'Saddiqabad' },
	{ title: 'Kohat' },
	{ title: 'Muridke' },
	{ title: 'Muzaffargarh' },
	{ title: 'Khanpur' },
	{ title: 'Gojra' },
	{ title: 'Mandi Bahauddin' },
	{ title: 'Abbottabad' },
	{ title: 'Turbat' },
	{ title: 'Dadu' },
	{ title: 'Bahawalnagar' },
	{ title: 'Khuzdar' },
	{ title: 'Pakpattan' },
	{ title: 'Tando Allahyar' },
	{ title: 'Ahmadpur East' },
	{ title: 'Vihari' },
	{ title: 'Jaranwala' },
	{ title: 'New Mirpur' },
	{ title: 'Kamalia' },
	{ title: 'Kot Addu' },
	{ title: 'Nowshera' },
	{ title: 'Swabi' },
	{ title: 'Khushab' },
	{ title: 'Dera Ismail Khan' },
	{ title: 'Chaman' },
	{ title: 'Charsadda' },
	{ title: 'Kandhkot' },
	{ title: 'Chishtian' },
	{ title: 'Hasilpur' },
	{ title: 'Attock Khurd' },
	{ title: 'Muzaffarabad' },
	{ title: 'Mianwali' },
	{ title: 'Jalalpur Jattan' },
	{ title: 'Bhakkar' },
	{ title: 'Zhob' },
	{ title: 'Dipalpur' },
	{ title: 'Kharian' },
	{ title: 'Mian Channun' },
	{ title: 'Bhalwal' },
	{ title: 'Jamshoro' },
	{ title: 'Pattoki' },
	{ title: 'Harunabad' },
	{ title: 'Kahror Pakka' },
	{ title: 'Toba Tek Singh' },
	{ title: 'Samundri' },
	{ title: 'Shakargarh' },
	{ title: 'Sambrial' },
	{ title: 'Shujaabad' },
	{ title: 'Hujra Shah Muqim' },
	{ title: 'Kabirwala' },
	{ title: 'Mansehra' },
	{ title: 'Lala Musa' },
	{ title: 'Chunian' },
	{ title: 'Nankana Sahib' },
	{ title: 'Bannu' },
	{ title: 'Pasrur' },
	{ title: 'Timargara' },
	{ title: 'Parachinar' },
	{ title: 'Chenab Nagar' },
	{ title: 'Gwadar' },
	{ title: 'Abdul Hakim' },
	{ title: 'Hassan Abdal' },
	{ title: 'Tank' },
	{ title: 'Hangu' },
	{ title: 'Risalpur Cantonment' },
	{ title: 'Karak' },
	{ title: 'Kundian' },
	{ title: 'Umarkot' },
	{ title: 'Chitral' },
	{ title: 'Dainyor' },
	{ title: 'Kulachi' },
	{ title: 'Kalat' },
	{ title: 'Kotli' },
	{ title: 'Gilgit' },
	{ title: 'Narowal' },
	{ title: 'Khairpur Mir’s' },
	{ title: 'Khanewal' },
	{ title: 'Jhelum' },
	{ title: 'Haripur' },
	{ title: 'Shikarpur' },
	{ title: 'Rawala Kot' },
	{ title: 'Hafizabad' },
	{ title: 'Lodhran' },
	{ title: 'Malakand' },
	{ title: 'Attock City' },
	{ title: 'Batgram' },
	{ title: 'Matiari' },
	{ title: 'Ghotki' },
	{ title: 'Naushahro Firoz' },
	{ title: 'Alpurai' },
	{ title: 'Bagh' },
	{ title: 'Daggar' },
	{ title: 'Leiah' },
	{ title: 'Tando Muhammad Khan' },
	{ title: 'Chakwal' },
	{ title: 'Badin' },
	{ title: 'Lakki' },
	{ title: 'Rajanpur' },
	{ title: 'Dera Allahyar' },
	{ title: 'Shahdad Kot' },
	{ title: 'Pishin' },
	{ title: 'Sanghar' },
	{ title: 'Upper Dir' },
	{ title: 'Thatta' },
	{ title: 'Dera Murad Jamali' },
	{ title: 'Kohlu' },
	{ title: 'Mastung' },
	{ title: 'Dasu' },
	{ title: 'Athmuqam' },
	{ title: 'Loralai' },
	{ title: 'Barkhan' },
	{ title: 'Musa Khel Bazar' },
	{ title: 'Ziarat' },
	{ title: 'Gandava' },
	{ title: 'Sibi' },
	{ title: 'Dera Bugti' },
	{ title: 'Eidgah' },
	{ title: 'Uthal' },
	{ title: 'Khuzdar' },
	{ title: 'Chilas' },
	{ title: 'Panjgur' },
	{ title: 'Gakuch' },
	{ title: 'Qila Saifullah' },
	{ title: 'Kharan' },
	{ title: 'Aliabad' },
	{ title: 'Awaran' },
	{ title: 'Dalbandin' }
]

export const states = [
	{ title: 'Sindh' },
	{ title: 'Balochistan' },
	{ title: 'Punjab' },
	{ title: 'Khyber Pakhtunkhuwa' },
	{ title: 'Gilgit Baltistan' },
	{ title: 'Azad and Jammu Kashmir' }
]


export const speakers = [
	{
		name: 'Khalid Khurshid',
		designation: 'Chief Minister',
		company: 'Gilgit Baltistan',
		image: 'khalid-khurshid.jpg',
		type: 'Chief Guest'
	},
	{
		name: 'Dr Ishrat Hussain',
		designation: 'Former Advisor to Prime Minister',
		company: 'Institutional Reforms and Austerity',
		image: 'ishrat-hussain.jpg',
		type: 'Guest of Honor'
	},
	{
		name: 'Syed Irfan Ali',
		designation: 'Managing Director',
		company: 'Deposit Protection Corporation',
		linkdln: 'https://www.linkedin.com/in/syed-irfan-ali-671157a/',
		image: 'syed-irfan-ali.jpeg',
		type: 'Guest of Honor'
	},
	/*{
		name: 'Muhammad Aurangzeb',
		designation: 'President and CEO',
		company: 'Habib Bank Limited',
		image: 'Muhammad Aurangzed-01.png',
		linkdln: 'https://www.linkedin.com/in/muhammad-aurangzeb-67a51047/',
		type: 'Keynote Speaker'
	},
	{
		name: 'Atif Bajwa',
		designation: 'President and CEO',
		company: 'Bank Alfalah',
		image: 'Atif Bajwa.png',
		linkdln: 'https://www.linkedin.com/in/atif-bajwa-a82303100/',
		type: 'Speaker'
	},
	{
		name: 'Yousaf Hussain',
		designation: 'President and CEO',
		company: 'Faysal Bank Limited',
		image: 'Yousaf Hussain.jpg',
		linkdln: 'https://www.linkedin.com/in/yousafhussain/',
		type: 'Keynote Speaker'
	},
	{
		name: 'Kazi Rahat Ali',
		designation: 'Secretary General',
		company: 'Presidential Initiative for Artificial Intelligence & Computing',
		image: 'Kazi Rahat Ali.png',
		linkdln: 'https://www.linkedin.com/in/kazi-rahat-ali-264785197/',
		type: 'Speaker'
	},
	{
		name: 'Basir Shamsie',
		designation: 'President and CEO',
		company: 'JS Bank',
		image: 'Basir Shamsi.jpg',
		linkdln: 'https://www.linkedin.com/in/basir-shamsie-4b028a3/',
		type: 'Speaker'
	},*/
	{
		name: 'Ibrahim Amin',
		designation: 'Executive Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'ibrahim-amin-2.jpg',
		linkdln: 'https://www.linkedin.com/in/ibrahim-amin-26760284/',
		type: 'Speaker'
	},
	{
		name: 'Faraz Anwer',
		designation: 'Partner, Business & Risk Consulting',
		company: 'A. F. Ferguson & Co. PWC',
		image: 'syed-faraz-anwer.jpg',
		linkdln: 'https://www.linkedin.com/in/syed-faraz-anwer-31532aa/',
		type: 'Speaker'
	},
	{
		name: 'Fahad Sheikh',
		designation: 'Senior Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'fahad-sheikh.jpg',
		linkdln: 'https://www.linkedin.com/in/fahaddsheikh/',
		type: 'Speaker'
	},
	{
		name: 'Haroon Q. Raja',
		designation: 'Senior Vice President',
		company: 'Pakistan Freelancers Association',
		image: 'Haroon Q. Raja.jpg',
		linkdln: 'https://www.linkedin.com/in/hqraja/',
		type: 'Speaker'
	},
	{
		name: 'Tufail Ahmed Khan',
		designation: 'Chief Executive Officer',
		company: 'Pakistan Freelancers Association',
		image: 'tufail-ahmed-khan.jpeg',
		linkdln: 'https://www.linkedin.com/in/tufail-ahmed-khan/',
		type: 'Speaker'
	},
	{
		name: 'Dua Sukhera',
		designation: 'Chief Executive Officer',
		company: 'The Institute of Entrepreneurship',
		image: 'Dua Sukhera.jpg',
		linkdln: 'https://www.linkedin.com/in/theduasukhera/',
		type: 'Speaker'
	},
/*	{
		name: 'Amer Ahmed Hashmi',
		designation: 'Chairman',
		company: 'Special Technology Zones Authority',
		image: 'Amer Hashmi.jpeg',
		linkdln: 'https://www.linkedin.com/in/amer-hashmi-144b49/',
		type: 'Speaker'
	},*/
	{
		name: 'Syed Arsalan Ali Shah',
		designation: 'Founder & CEO',
		company: 'Connected Pakistan',
		image: 'Syed Arsalan Ali Shah.jpg',
		linkdln: 'https://www.linkedin.com/in/syed-arsalan-aa276288/',
		type: 'Speaker'
	},
/*	{
		name: 'Najeeb Agrawalla',
		designation: 'CEO',
		company: '1Link Pvt Ltd',
		image: 'najeeb-agrawalla.jpg',
		linkdln: 'https://www.linkedin.com/in/najeeb-agrawalla-309249a5/',
		type: 'Speaker'
	},
	{
		name: 'Naeem Zamindar',
		designation: 'Founder and Managing Partner',
		company: 'Neem Exponential',
		image: 'Naeem Zamindar.jpeg',
		linkdln: 'https://www.linkedin.com/in/naeem-zamindar-62b486/',
		type: 'Speaker'
	},*/
/*	{
		name: 'Abdul Rahim Ahmed',
		designation: 'Director General & CIO',
		company: 'Special Technology Zones Authority (STZA)',
		image: 'Abdul-Rahim-Ahmad.png',
		linkdln: 'https://www.linkedin.com/in/rahim82/',
		type: 'Speaker'
	},
	{
		name: 'Shaheryar Saeed Khan',
		designation: 'Chief Digital Officer',
		company: 'Faysal Bank Ltd',
		image: 'Shaheryar Saeed Khan.png',
		linkdln: 'https://www.linkedin.com/in/shaheryar-saeed-khan/',
		type: 'Speaker'
	},
	{
		name: 'Javaid Iqbal',
		designation: 'Chief Commercial Officer',
		company: 'Special Technology Zones Authority',
		image: 'Javaid Iqbal.jpg',
		linkdln: 'https://www.linkedin.com/in/jiqbal/',
		type: 'Speaker'
	},
	{
		name: 'Adnan Nasir',
		designation: 'Head of Digital Payments',
		company: 'Bank Alfalah Limited',
		image: 'Adnan Nasir.JPG',
		linkdln: 'https://www.linkedin.com/in/adnan-nasir-0464881/',
		type: 'Speaker'
	},
	{
		name: 'Adnan Ali',
		designation: 'CEO & Director',
		company: 'PayFast',
		image: 'adnan-ali.jpg',
		linkdln: 'https://www.linkedin.com/in/adnan-ali-5934426/',
		type: 'Speaker'
	},
	{
		name: 'Vali Uddin',
		designation: 'Vice Chancellor',
		company: 'Sir Syed University',
		image: 'Prof. Dr. Vali Uddin.jpg',
		linkdln: 'https://www.linkedin.com/in/vali-uddin-0114044a/',
		type: 'Speaker'
	},*/
	{
		name: 'Nadia Iqbal',
		designation: 'CoFounder & CEO',
		company: 'Tech Alphalogix',
		image: 'nadia-iqbal.jpg',
		linkdln: 'https://www.linkedin.com/in/nadia-iqbal-14387153/',
		type: 'Speaker'
	},
	{
		name: 'Arzish Azam',
		designation: 'Founder & CEO',
		company: 'Ejad Labs',
		image: 'Arzish Azam.jpg',
		linkdln: 'https://www.linkedin.com/in/arzishazam/',
		type: 'Speaker'
	},
/*	{
		name: 'Ahad Khurshid',
		designation: 'CEO',
		company: 'Sahal Verifai',
		image: 'Ahad Khurshid.jpg',
		linkdln: 'https://www.linkedin.com/in/abdul-ahad-55558b197/',
		type: 'Speaker'
	},
	{
		name: 'Hussein Hassanali',
		designation: 'GM IT Audit',
		company: 'Habib Bank Limited',
		image: 'Hussein Hassanali.jpg',
		linkdln: 'https://www.linkedin.com/in/hussein-hassanali/',
		type: 'Speaker'
	},*/
/*	{
		name: 'Imran H. Shaikh',
		designation: 'Chief Operating Officer',
		company: 'JS Bank',
		image: 'Imran Haleem Sheikh.jpg',
		linkdln: 'https://www.linkedin.com/in/imranhaleemshaikh/',
		type: 'Speaker'
	},*/
]