import React, { useState } from 'react';
import {Card} from 'antd';
import styles from '../../styles/freelance-fest/general.module.css'
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../helpers/constants';
import { Typography } from 'antd';
import { Markup } from 'interweave';


const { Paragraph } = Typography;

const ScheduleItemCard = ({startTime, endTime, title, description, ...rest}) => {

    const [ellipsis, setEllipsis] = useState(true);

    const typoClose = () => {
        setEllipsis(!ellipsis)
    }

    const countDescription = (description) => {
        return description ? description.split(" ").filter((x) => x !== "").length : 0
    }

    return (
        <div className={`${styles.scheduleItem} ${(countDescription(description) < 10) && styles.small}`}>
            <div className={styles.scheduleItemRow}>
                <div className={`${styles.scheduleItemCol} ${styles.scheduleTimings}`}>
                    {startTime &&
                        <span className={styles.scheduleTime}>{startTime}</span>
                    }
                    {endTime &&
                        <span className={styles.scheduleTime}>{endTime}</span>
                    }
                </div>
                <div className={`${styles.scheduleItemCol} ${styles.scheduleAgenda}`}>
                    {title &&
                        <span className={styles.scheduleAgendaTitle}>{title}</span>
                    }
                    {description &&
                        <>
                            <span className={styles.scheduleAgendaDescription}>
                                <Paragraph ellipsis={ellipsis ? { rows: 2 } : false}>
                                    <Markup content={description}/>
                                </Paragraph> 
                            </span>
                            {(countDescription(description) >  10) &&
                                <span className={styles.typoExpand} onClick={typoClose}>
                                    {ellipsis ? 'More' : 'Less'}
                                </span>
                            }                            
                        </>
                    }
                   <hr/>
                </div>
            </div>
        </div>
    );
};

export default ScheduleItemCard;
