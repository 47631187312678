import React from 'react'
import { Form, Row, Col, Input, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import { notification} from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities } from '../../FreelanceFest/constants'
import { Collapse } from 'antd';
import PriceFormatted from '../../PriceFormatted'
const { Panel } = Collapse

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;

const { Text } = Typography;

class LoginForm extends React.Component {

    constructor(props) {
        super(props)
    }
   
    render() {
        const { selectedPackage } = this.props
        const isDiscounted = selectedPackage && (selectedPackage.discounted_price > 0) ? true : false
        return (
            <>
                <div className={`${styles.selectedEventContainer} ${styles.desktop}`}>
                     <div className={styles.ticketHead}>
                         <h3>Event Details</h3>
                         <hr/>
                     </div>
                     {(selectedPackage && selectedPackage.events) &&
                        <div className={styles.eventCol}>
                            <img alt={selectedPackage.events[0].title} src={`${process.env.REACT_APP_STORAGE_URL}/${selectedPackage.events[0].image}`}/>
                        </div>
                     }
                     <div className={styles.eventCol}>
                        {(selectedPackage && selectedPackage.events) &&
                            <div className={styles.eventDetail}>                        
                                <span className={styles.eventDetailHead}>Event Title</span>
                                <span className={styles.eventDetailInfo}>{selectedPackage.events[0].title}</span>
                            </div> 
                        }
                         
                        <div className={styles.eventDetail}> 
                            <span className={styles.eventDetailHead}>Selected Package</span>
                            <span className={styles.eventDetailInfo}>{selectedPackage && selectedPackage.name}</span>
                        </div>
                        {selectedPackage &&
                        <div className={styles.eventDetail}>                    
                            <span className={styles.eventDetailHead}>Ticket Price</span>
                            <span className={`${styles.eventDetailInfo} ${styles.regularPrice} ${isDiscounted && styles.stroked}`}><PriceFormatted price={selectedPackage.price}/></span>
                            {(selectedPackage && isDiscounted) &&
                                <span className={`${styles.eventDetailInfo} ${styles.eventDiscountedPrice}`}><PriceFormatted price={selectedPackage.discounted_price}/></span>
                            }
                        </div>
                        }
                     </div>
                </div>

                 <Collapse expandIconPosition="right" className={`${styles.selectedEventContainer} ${styles.mobile}`} ghost accordion>
                    <Panel header="Event Details" key="1">
                      {(selectedPackage && selectedPackage.events) &&
                         <div className={styles.eventCol}>
                             <img alt={selectedPackage.events[0].title} src={`${process.env.REACT_APP_STORAGE_URL}/${selectedPackage.events[0].image}`}/>
                         </div>
                      }
                      <div className={styles.eventCol}>
                          <div className={styles.eventDetail}>                        
                              <span className={styles.eventDetailHead}>Event Title</span>
                              <span className={styles.eventDetailInfo}>Freelance Fest 2022</span>
                          </div>
                          <div className={styles.eventDetail}>                        
                              <span className={styles.eventDetailHead}>Selected Package</span>
                              <span className={styles.eventDetailInfo}>{selectedPackage && selectedPackage.name}</span>
                          </div>
                          {selectedPackage &&
                             <div className={styles.eventDetail}>                    
                                 <span className={styles.eventDetailHead}>Ticket Price</span>
                                 <span className={`${styles.eventDetailInfo} ${styles.regularPrice} ${isDiscounted && styles.stroked}`}><PriceFormatted price={selectedPackage.price}/></span>
                                 {(selectedPackage && isDiscounted) &&
                                     <span className={`${styles.eventDetailInfo} ${styles.eventDiscountedPrice}`}><PriceFormatted price={selectedPackage.discounted_price}/></span>
                                 }
                             </div>
                          }
                      </div>
                    </Panel>
                 </Collapse>
            </>
        )
    }
}


export default withRouter(LoginForm);
