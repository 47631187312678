import React from 'react';
import Layout from '../components/Layout';
import {Zoom} from 'react-reveal';
import pafla from '../api/pafla'
import { Col, Row} from 'antd';
import {IMAGE_URL} from '../helpers/constants';
import { Helmet } from "react-helmet"
import styles from '../styles/membershipjoin.module.css'
import BenefitCard from '../components/BenefitCard'
import Package from '../components/Package';
import { ArrowPathRoundedSquareIcon, GlobeAltIcon, BoltIcon, AcademicCapIcon, DocumentCheckIcon, HeartIcon, FireIcon, HandRaisedIcon, BanknotesIcon } from '@heroicons/react/24/solid'
import { packages } from '../helpers/constants'
import { Collapse } from 'antd';

const { Panel } = Collapse;

const faqs = [
    {
        id: 1,
        question: 'How do I become a member?',
        answer: 'You can become a member by signing up to the association through the signup page.'
    },
    {
        id: 2,
        question: 'When does my membership expire?',
        answer: 'Your free PAFLA membership never expires but all paid memberships are viable for a year after purchase.'
    },
    {
        id: 3,
        question: 'How can I pay for paid plans?',
        answer: 'You have multiple payment options available to you at the membership page. You can either pay using your Credit/Debit card or through your bank account using the 1Bill Services.'
    },
    {
        id: 4,
        question: 'Are there any discounts on paid plans?',
        answer: 'Keep a close eye on our social channels, we reguarly post updates about our latest offers.'
    },
    {
        id: 5,
        question: 'Who do I contact for additional questions or queries?',
        answer: 'You can always get in touch with us through our social channels or email us at support@pafla.org.pk'
    }
]

class Membership extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        events: null
	    }
	}

	async componentDidMount() {
        
        await pafla.get('/events')
        .then((response) => {
            this.setState({events: response.data.data})
        })
    }


    render() {
        return (
        	<Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Membership - Pakistan Freelancers Association</title>
                </Helmet>
                <section className={`${styles.section}`}>
                     <Row className='contained-row' justify="space-between">
                         <Row justify="space-between">
                             <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                                <Zoom left>
                                    <Row>
                                        <Col xs={{span: 24}} md={{span: 24}} style={{margin: '0 auto'}}>
                                            <Row align='left'>
                                                <Col xs={{span: 24}} md={{span: 12}} style={{margin: '0 auto'}}>
                                                    <img src={`${IMAGE_URL}happy-freelancer.png`} alt=""/>
                                                </Col>
                                                <Col xs={{span: 24}} md={{span: 11, push: 1}} style={{margin: '0 auto', textAlign: 'left'}}>
                                                    <h2 style={{fontSize: '1.8rem'}}>A safetynet for all freelancers</h2>
                                                    <p>As a freelancer in Pakistan, being a member of the Pakistan Freelancers Association can provide a safety net by giving you access to exclusive benefits and resources that can help you grow your business and thrive in your career. Joining the association means becoming a part of a community of like-minded individuals who are working together to empower each other and create a stronger, more resilient industry. </p>
                                                    <p>Additionally, your membership will also contribute to the growth and development of the freelancing industry in Pakistan, thereby benefiting the country as a whole. As a member, you will have the opportunity to participate in initiatives and programs that support the growth of freelancing in Pakistan, help to create a strong and sustainable ecosystem for freelancers and create a brighter future for themselves and the country.</p>
                                                </Col>
                                            </Row>
                                        
                                        </Col>
                                    </Row>
                                </Zoom>
                            </Col>
                         </Row>
                     </Row>
                 </section>
                <section id={this.props.sectionId} className={`${styles.section} ${styles.partners}`}>
                    <Row className='contained-row' justify="space-between">
                        <Row justify="space-between">
                            <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                                <div className={styles.section_title_container}>
                                    <h2 style={{fontSize: '1.8rem'}}>Benefits</h2>
                                    <p>Join the Pakistan Freelancers Association today and reap the rewards of membership, including exclusive benefits and resources to enhance your freelance success.</p>
                                </div>
                                <div className={styles.benefit_items}> 
                                    <BenefitCard
                                        icon={<ArrowPathRoundedSquareIcon/>}
                                        title='Community'
                                        shortDescription="Get social support, sense of belonging, opportunities for personal and professional growth, access to resources and knowledge, and a platform for shared experiences and relationships."
                                    />
                                    <BenefitCard
                                        icon={<GlobeAltIcon/>}
                                        title='Voice & Support group'
                                        shortDescription="Become a part of a united voice that amplifies individual perspectives and experiences, increases visibility, recognition, and impact leading to increased advocacy, empowerment, and opportunities for positive change."
                                    />
                                    <BenefitCard
                                        icon={<BoltIcon/>}
                                        title='Exclusive Events'
                                        shortDescription="Attend our exclusive events providing you opportunities for personal and professional growth, networking, knowledge sharing, exposure to new ideas, and building relationships."
                                    />
                                    <BenefitCard
                                        icon={<FireIcon/>}
                                        title='Discounts and Promotions'
                                        shortDescription="Get access to exclusive discounts and promotions which can be applied to the tools and services you need for your business, increasing your efficiency and a competitive advantage over non-members."
                                    />
                                    <BenefitCard
                                        icon={<HeartIcon/>}
                                        title='Insurance'
                                        shortDescription="Get access to financial protection in case of accidental injury and unexpected events by covering your medical expenses and loss of income. Giving peace of mind to you and your loved ones."
                                    />
                                    <BenefitCard
                                        icon={<DocumentCheckIcon/>}
                                        title='Accounting and legal'
                                        shortDescription="Get peace of mind by ensuring timely, accurate tax filings and legal support. Stay compliant with tax laws, maximizing your deductions and getting the full refund you're owed."
                                    />
                                    <BenefitCard
                                        icon={<AcademicCapIcon/>}
                                        title='Workshops & Training'
                                        shortDescription="Get access to focussed workshops and specifically tailored training in supportive and interactive environments to upgrade skills, stay current, network, gain new insights, and boost confidence for career advancement."
                                    />
                                    <BenefitCard
                                        icon={<HandRaisedIcon/>}
                                        title='Showcase'
                                        shortDescription="Get opportunities to showcase your skills to a wider audience. Boost your credibility, gain new clients, and increase your earning potential. Stand out in your field and take your career to the next level."
                                    />
                                    <BenefitCard
                                        icon={<BanknotesIcon/>}
                                        title='Banking'
                                        shortDescription="Get access to convenient banking services like remote bank account opening and remote internet payment gateway for your websites. Streamline financial management for a hassle-free freelancing experience."
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </section>

                <section className={`${styles.section}`}>
                     <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                         <Zoom left>
                             <Row>
                                 <Col xs={{span: 24}} md={{span: 16}} style={{margin: '0 auto'}}>
                                     <Row align='middle'>
                                         <Col xs={{span: 24}} md={{span: 10}} style={{margin: '0 auto'}}>
                                             <img src={`${IMAGE_URL}together.png`} alt=""/>
                                         </Col>
                                         <Col xs={{span: 24}} md={{span: 14, push: 1}} style={{margin: '0 auto', textAlign: 'left'}}>
                                             <h2 style={{fontSize: '1.8rem'}}>Together for a Stronger Tomorrow</h2>
                                             <p>Join the largest registered entity for freelancers in Pakistan and become a part of a united community fighting for your rights. Support the growth of the Pakistani economy while benefiting from the collective strength and resources of the Pakistan Freelancers Association. Join us now and show the world the power of a united freelance community.</p>
                                         </Col>
                                     </Row>
                                 
                                 </Col>
                             </Row>
                         </Zoom>
                     </Col>
                 </section>
                <section id={this.props.sectionId} className={`${styles.section}`}>
                    <Row className='contained-row' justify="space-between">
                        <Row justify="space-between">
                            <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                                <div className={styles.section_title_container}>
                                    <h2 style={{fontSize: '1.8rem'}}>Packages</h2>
                                    <p>Find the right package that suits your needs by exploring the different options. Each package offers unique benefits and resources choose the one that aligns with your goals and needs.</p>
                                </div>
                                <div className={styles.packages}> 
                                    {packages.map((pckage) => {
                                        return (
                                            <Package
                                                active={false}
                                                name={pckage.name}
                                                memberPrice={pckage.memberPrice}
                                                memberPriceDiscounted={pckage.memberPriceDiscounted}
                                                discountText={pckage.discountText}
                                                description={pckage.description}
                                                features={pckage.features}
                                                buttonText={pckage.buttonText}
                                                buttonLink={pckage.buttonLink}
                                                disabled={pckage.disabled}
                                                validate={this.validateMembership}
                                                type={pckage.type}
                                            />
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </section>
                <section className={`${styles.section} ${styles.faqs} ${styles.partners}`}>
                    <Row className='contained-row' justify="space-between">
                        <Row justify="space-between">
                            <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                                <div className={styles.section_title_container}>
                                    <h2 style={{fontSize: '1.8rem'}}>Common Questions</h2>
                                    <p>The following answers are provided to address the frequently asked questions regarding the matter at hand. We hope that these answers will provide you with the information you need to make an informed decision. </p>
                                </div>
                                <Zoom left>
                                    <div className={styles.qaPanels}>
                                        <Collapse defaultActiveKey={['1']}>
                                            {faqs.map(faq => {
                                                return (
                                                    <Panel className={styles.question} header={faq.question} key={faq.id}>
                                                        <p className={styles.answer}>{faq.answer}</p>
                                                    </Panel>
                                                )
                                            })}
                                        </Collapse>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>
                    </Row>
                </section>
            </Layout>
        );
    }
}

export default Membership;
        