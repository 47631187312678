import React from 'react';
import {Row, Col, Button, Drawer, Menu } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import styles from '../../styles/freelance-fest/general.module.css'
import {HOME, ABOUT, IMAGE_URL, SIGN_UP, EVENTS, BLOGS, LOG_IN, MEDIA_MENTIONS, BOARD} from '../../helpers/constants';
import {Link, NavLink} from 'react-router-dom';
import ProfileMenu from './ProfileMenu'
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect'
import { Fade } from 'react-reveal'
import { nav } from './constants'

const menu = (
	<Menu>
		<Menu.Item>
			<NavLink to={ABOUT}>About us</NavLink>
		</Menu.Item>
		<Menu.Item>
			<NavLink to={BOARD}>Our Board</NavLink>
		</Menu.Item>
		<Menu.Item>
			<NavLink to={MEDIA_MENTIONS}>Media Mentions</NavLink>
		</Menu.Item>
	</Menu>
);



const MenuList = ({itemClick,...rest}) => {
	return (
		<ul {...rest} >
			{nav.map((navItem) => {
				return (
					<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
						<a href={navItem.link}>{navItem.name}</a>
					</li>
				)
			})}
		</ul>
	)
}

const MenuListMobile = ({itemClick,...rest}) => {
	const authCookie = Cookies.get('auth');
	let signedIn = false 
	if (authCookie) {
		if (authCookie.trim() !== "") {
			 signedIn = true 
		}
	}

	return (
		<ul {...rest} >
			{nav.map((navItem) => {
				return (
					<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
						<a href={navItem.link}>{navItem.name}</a>
					</li>
				)
			})}
		</ul>
	)
}

class Header extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}
	}

	onClose = () => {
		this.setState({
			visible: false,
		});
	};


	signedIn() {
		const authCookie = Cookies.get('auth');
		if (authCookie) {
			if (authCookie.trim() !== "") {
				return true
			}
		}
		return false

	}

	render() {
	   return (
		   <header className={styles.headerFreelanceFestISL}>
			   <Row>
				   <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}} className={styles.header_wrapper}>
				      <div className={styles.menu_modal}>
				   	   <MenuOutlined onClick={() => this.setState({visible: true}) } className={styles.menu_icon}/>
				   	   <Drawer
				   		   title={<CloseOutlined onClick={() => this.setState({visible: false})}/>}
				   		   placement="left"
				   		   closable={false}
				   		   onClose={this.onClose}
				   		   visible={this.state.visible}
				   	   >
				   		   <MenuListMobile className={`${styles.menu_mobile} ${styles.menu_list} mobile-menu`} itemClick={() => this.setState({visible: true})}/>
				   	   </Drawer>

				      </div>
					   <div className={styles.header_logo}>
						   <Link to="/freelance-fest-2022">
						   		{/*<img className={styles.header_logo_image} src={`${IMAGE_URL}/freelance-fest/logo.png`} alt=""/>*/}
						   		<span>FreelanceFest Islamabad 2022</span>
						   </Link>
					   </div>
					   <MenuList className={`${styles.menu_desktop} ${styles.menu_list}`}/>
					   {(this.signedIn() && !isMobile ) &&
						   <ProfileMenu/>
					   }
					   {/*{!this.signedIn() &&
						   <div className={styles.header_btn}>
								<Button className="btn_default">
									<a href='#packages'>Register Now</a>
								</Button>
						   </div>
					   }*/}
				   </Col>
			   </Row>
		   </header>
	   ); 
	}
}

export default Header;
