import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import {Zoom} from 'react-reveal';
import Sponsor from './Sponsor'

const {Title, Paragraph} = Typography;
const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.sponsors}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>Our Partners & Sponsors</h1>
                            <h2>We gratefully acknowledges the generosity of our many FreelanceFest 2022 sponsors. Their support, helps make it possible to accomplish all that we do.</h2>
                        </div>
                        <div className={styles.sponsor_items}>
                            <Sponsor 
                                priority="one"
                                image='freelance-fest/sponsors/gb-gov.png'
                                name="Government of Gilgit-Baltistan" 
                                type="Collaborating Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/sco.png'
                                name="SCO" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/kiup.png'
                                name="Karakoram International University" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/ioe.png'
                                name="The Institute of Entrepreneurship" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="one"
                                image='freelance-fest/sponsors/pwc.png'
                                name="A.F.Ferguson & Co. Chartered Accountants, a member firm of the PwC network" 
                                type="Knowledge Partner"
                            />
                            <Sponsor 
                                priority="one"
                                image='freelance-fest/sponsors/pitb.jpeg'
                                name="PITB" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/akrsp.jpg'
                                name="AKRSP" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/ta.png'
                                name="Tech Alphalogix" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/needstraining.jpeg'
                                name="Needs Training & Consulting" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/sonijawari.png'
                                name="Soni Jawari" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/kcbl.png'
                                name="Karakoram Co-operative Bank" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/uconnect.jpeg'
                                name="UConnect" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/bsv.png'
                                name="BSV" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/piaic.png'
                                name="PIAIC" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/stza.png'
                                name="STZA" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/fasset.png'
                                name="Fasset" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/kistpay.png'
                                name="KistPay" 
                                type="Supporting Partner"
                            />
                        </div>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
