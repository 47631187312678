import React from 'react';
import pafla from '../../api/pafla';
import Dashboard from '../../components/dashboard/Main';
import CourseCard from '../../components/CourseCard';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import { Row } from 'antd';
import styles from '../../styles/event.module.css';
import { Helmet } from 'react-helmet';
import { courses } from '../../helpers/gcc-constants';

class Discounts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			discounts: null,
			loading: true,
		};
	}

	async componentDidMount() {
		await pafla.get('/discounts').then((response) => {
			this.setState({ loading: false });
			this.setState({ discounts: response.data.data });
		});
	}

	render() {
		const { loading, discounts } = this.state;
		return (
			<Dashboard loading={loading}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Google Career Certificates - Pakistan Freelancers Association</title>
				</Helmet>
				<Row>
					<SectionBody>
						<SectionHeader>
							<h2>Google Career Certificates</h2>
						</SectionHeader>
						<div className={`card ${styles.discounts} ${styles.card_items}`}>
							{courses &&
								Object.entries(courses).map(([key, course], i) => {
									return (
										<CourseCard
											classes={styles.discountCard}
											key={course.id}
											title={course.name}
											text={`${course.description}`}
											link={course.button2Link}
											image={course.imageSrc}
											bodyClasses={styles.bodyclass}
										/>
									);
								})}
						</div>
					</SectionBody>
				</Row>
			</Dashboard>
		);
	}
}

export default Discounts;
