import React from 'react';
import Layout from '../components/Layout';
import { Zoom } from 'react-reveal';
import BoardMemberCard from '../components/BoardMemberCard';
import { Col, Row } from 'antd';
import styles from '../styles/card.module.css';
import { IMAGE_URL } from '../helpers/constants';
import Banner from '../components/Banner';
import { Helmet } from 'react-helmet';
import pafla from '../api/pafla';

const advisors = [
	{
		name: 'Ibrahim Amin',
		image: `${IMAGE_URL}board-members/ibrahim-amin-2.jpg`,
		position: 'Director',
	},
	{
		name: 'Tufail Ahmed Khan',
		image: `${IMAGE_URL}board-members/tufail-ahmed-khan.jpeg`,
		position: 'Director',
	},
	{
		name: 'Farheen Amin',
		image: `${IMAGE_URL}board-members/farheen-amin.jpeg`,
		position: 'Director',
	},
	{
		name: 'Zahida Junaid',
		image: `${IMAGE_URL}board-members/jiya-junaid.jpg`,
		position: 'Director',
	},
];

class Blogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			directors: [],
		};
	}

	async componentDidMount() {
		await pafla.get('/directors').then((response) => {
			this.setState({ directors: response.data.data });
		});
	}

	render() {
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Our Board - Pakistan Freelancers Association</title>
				</Helmet>
				<Banner
					title="Our Leadership"
					text="The organization's leaders come from a broad range of backgrounds and have a diversity of expertise and experience that spans science and technology, business, public policy, education, and communications. Our leaders are guided by the organization mission to empower the independent workforce of Pakistan"
					background={`${IMAGE_URL}home_banner_bg.png`}
					image={`${IMAGE_URL}home_banner_img.png`}
					width={[9, 14]}
				/>
				<section id="boardmembers">
					<Row justify="space-between">
						<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
							<Zoom left>
								<div className={styles.boardMemberCards}>
									{this.state.directors?.map((member) => {
										return (
											<BoardMemberCard
												key={member.name}
												image={`${process.env.REACT_APP_STORAGE_URL}/${member.image}`}
												name={member.name}
												position={member.position}
												bio={member.bio}
												linkdln={member.linkedin}
											/>
										);
									})}
									{/* advisors.map((member) => {
										return <BoardMemberCard key={member.name} image={member.image} name={member.name} position={member.position} />;
									})*/}
								</div>
							</Zoom>
						</Col>
					</Row>
				</section>
			</Layout>
		);
	}
}

export default Blogs;
