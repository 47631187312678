import React from 'react'
import { Form, Row, Col, Input, Select, Checkbox } from 'antd'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import axios from 'axios'
import { notification } from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities, states } from '../../FreelanceFest/constants'
import { Radio, Space } from 'antd';
import TicketShippingPreview from './TicketShippingPreview'
import ReactPixel from 'react-facebook-pixel'
import CryptoJS from 'crypto-js';

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;


class EventRegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            shipToTicket: false,
            formLoading: null,
            paymentMethod: 0,
            agreeTerms: false,
            authToken: null,
            returnURL: null,
            online_payment: false,
            RequestHash: null,
            grand_total: null,
            redirectRequestHash: null
        }
    }

    onFinish = (values) => {
        const { selectedTicket } = this.props
        this.setState({formLoading: true})

        var ticketValues = {}

        ticketValues.first_name = selectedTicket.first_name
        ticketValues.last_name = selectedTicket.last_name
        ticketValues.email = selectedTicket.email
        ticketValues.cnic = selectedTicket.cnic
        ticketValues.payment_method = values['payment_method']

        if (!this.state.shipToTicket) {
            ticketValues.physical_address = selectedTicket.physical_address
            ticketValues.city = selectedTicket.city
            ticketValues.state = selectedTicket.state
            ticketValues.country = selectedTicket.country
            ticketValues.phone_number = selectedTicket.phone_number
        } else {
            ticketValues.physical_address = values['physical_address']
            ticketValues.city = values['city']
            ticketValues.state = values['state']
            ticketValues.country = values['country']
            ticketValues.phone_number = values['phone_number']
        }

        if (selectedTicket) {
            pafla.post(`user/tickets/${selectedTicket.id}/orders`, ticketValues)
            .then((response) => {
                if (!response.data.error) {
                    notification.success({
                        message: 'Success',
                        description: response.data.message                     
                    });

                    ReactPixel.track('Purchase',
                      {
                        content_ids: ['2715521342086822'],
                        content_type: 'product',
                        eventref: 'fb_oea',
                        currency: 'PKR',
                        num_items: 1,
                        value: response.data.data.grand_total
                      }
                    );

                    this.setState({grand_total: response.data.data.grand_total})

                    /*if (this.state.online_payment) {
                        let authVars = {}

                        authVars.AuthToken = this.state.authToken
                        authVars.ChannelId = process.env.REACT_APP_BAFL_CHANNELID
                        authVars.Currency = process.env.REACT_APP_BAFL_CURRENCY
                        authVars.MerchantId = process.env.REACT_APP_BAFL_MERCHANTID
                        authVars.StoreId = process.env.REACT_APP_BAFL_STOREID
                        authVars.MerchantHash = process.env.REACT_APP_BAFL_MERCHANTHASH
                        authVars.MerchantUsername = process.env.REACT_APP_BAFL_MERCHANTUSERNAME
                        authVars.MerchantPassword = process.env.REACT_APP_BAFL_MERCHANTPASSWORD
                        authVars.TransactionTypeId = process.env.REACT_APP_BAFL_TRANSACTIONTYPEID
                        authVars.IsBIN = process.env.REACT_APP_BAFL_ISBIN
                        authVars.ReturnURL = process.env.REACT_APP_BAFL_RETURNURL
                        authVars.TransactionReferenceNumber = selectedTicket.id
                        authVars.TransactionAmount = response.data.data.grand_total
                        authVars.RequestHash = this.generateRequestHash(authVars)

                        this.setState({redirectRequestHash: authVars.RequestHash})

                        if (this.state.redirectRequestHash) {
                            document.getElementById("PageRedirectionForm").submit()
                        }
                        
                    } else {
                        this.props.history.push(`/order/thankyou`)
                    }*/
                    this.props.history.push(`/order/thankyou`)

                } else {
                    notification.error({
                        message: 'Error',
                        description: response.data.message                     
                    });
                }
                this.setState({formLoading: false})
            })
            .catch((error) => {
                this.setState({formLoading: false})
            })
        }
    }

    shipToTicket = (value: string) => {
        this.setState({shipToTicket: value.target.checked})
    }


    agreeTerms = (value: string) => {
        this.setState({agreeTerms: value.target.checked})
    }

    disableOnlinePayment = () => {
        this.setState({online_payment: false})
    }

    onPaymentMethodChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    userDetails() {
        let userDetails = Cookies.get('user_details')
        let userMeta = {}

        if (userDetails) {
            let userDetailsParsed = JSON.parse(userDetails)

            if (userDetailsParsed) {
                userMeta.phone = userDetailsParsed.phone 
            }
        }

        return userMeta
    }

    freeTicket = () => {
        this.setState({online_payment: true})
    }
    
    prepareRequest = async () => {
        this.setState({formLoading: true})
        this.setState({online_payment: true})
        const { selectedTicket } = this.props

        if(selectedTicket) {
            let authVars = {}
            
            authVars.HS_IsRedirectionRequest = process.env.REACT_APP_BAFL_REDIRECTREQUEST
            authVars.HS_ChannelId = process.env.REACT_APP_BAFL_CHANNELID
            authVars.HS_MerchantId = process.env.REACT_APP_BAFL_MERCHANTID
            authVars.HS_StoreId = process.env.REACT_APP_BAFL_STOREID
            authVars.HS_MerchantHash = process.env.REACT_APP_BAFL_MERCHANTHASH
            authVars.HS_MerchantUsername = process.env.REACT_APP_BAFL_MERCHANTUSERNAME
            authVars.HS_MerchantPassword = process.env.REACT_APP_BAFL_MERCHANTPASSWORD
            authVars.HS_TransactionReferenceNumber = selectedTicket.id
            authVars.HS_ReturnURL = process.env.REACT_APP_BAFL_RETURNURL
            authVars.HS_RequestHash = this.generateRequestHash(authVars)

            await axios.post(process.env.REACT_APP_BAFL_HANDSHAKE_URL, null,  {params: authVars})
            .then((response) => {
                if (response.data.success == 'true') {
                    this.setState({authToken: response.data.AuthToken})
                    this.setState({returnURL: response.data.ReturnURL})

                }
                this.setState({formLoading: false})
            })
            .catch((error) => {
                this.setState({formLoading: false})
            })
        }
    }

    generateRequestHash(vars) {
        const Key1 = process.env.REACT_APP_BAFL_KEY_ONE
        const Key2 = process.env.REACT_APP_BAFL_KEY_TWO
        var string = Object.keys(vars).map(function(k) {
            return k + "=" + vars[k];
        }).join('&')

        var text = CryptoJS.enc.Utf8.parse(string)
        var iv = CryptoJS.enc.Utf8.parse(Key2)
        var key = CryptoJS.enc.Utf8.parse(Key1)

        var encrypted = CryptoJS.AES.encrypt(text, key,
            {
                keySize: 128 / 8, 
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        ).toString()
        var decrypted =  CryptoJS.AES.decrypt(encrypted.toString(), key, {iv: iv});

        return encrypted
    }

    render() {
        const { selectedTicket } = this.props
        return (
            <>
                <Form
                    {...layout}
                    name="normal_login"
                    className={styles.event_register_form}
                    initialValues={{
                        "phone_number": this.userDetails().phone,
                        "country": "Pakistan"
                    }}
                    onFinish={this.onFinish}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 22 }}
                    layout="vertical"
                >

                    <div className={styles.ticketHead}>
                        <h3>Payment Method</h3>
                        <hr/>
                    </div>

                    <Row gutter={0}>
                        <Col xs={24} md={24}>
                            <Form.Item 
                                name="payment_method" 
                                rules={[{ required: true, message: 'Please select your payment method' }]} 
                                onChange={this.onPaymentMethodChange} 
                                value={this.state.paymentMethod}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value={4} onClick={this.freeTicket}>Free</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/*
                    {!this.state.online_payment &&
                        <>
                            <div className={styles.ticketHead}>
                                <h3>Shipping Details</h3>
                                <hr/>
                            </div>
                            {!this.state.shipToTicket && <TicketShippingPreview ticket={selectedTicket}/>}
                            
                            <Form.Item name="ship_to_ticket" valuePropName="checked" value={false} onChange={this.shipToTicket}>
                                <Checkbox >Ship to a differrent address</Checkbox>
                            </Form.Item>

                            {this.state.shipToTicket &&
                                <>
                                    <Row gutter={0}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                rules={[
                                                    { required: true, message: 'Please input your email' },
                                                ]}
                                                validateTrigger={"onBlur"}
                                                name="phone_number"
                                                label="Phone Number"
                                            >
                                                <Input size="large" placeholder="Phone Number" autocomplete="number"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="city" label="City" rules={[{ required: true }]}>
                                                <Select
                                                    placeholder="Select your city"
                                                    allowClear
                                                    className={styles.dropdown}
                                                >
                                                    {cities.map(city => {
                                                        return <Option className={styles.dropdownValue} value={city.title}>{city.title}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={0}>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="state" label="State" rules={[{ required: true }]}>
                                                <Select placeholder="Select your state" allowClear>
                                                    {states.map(city => {
                                                        return <Option value={city.title}>{city.title}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                                                <Select placeholder="Select your country" allowClear>
                                                    <Option value="pakistan">Pakistan</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={0}>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="physical_address" label="Physical Address" rules={[{ required: true }]}>
                                                <TextArea 
                                                    showCount 
                                                    placeholder="Provide your address" 
                                                    rows={3}
                                                    autoSize={{ minRows: 3, maxRows: 4 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                    }

                    

                    <Row gutter={0}>
                        <Col xs={24} md={24}>
                            <Form.Item 
                                name="checkbox" 
                                valuePropName="checked"
                                rules={[
                                  {
                                    validator: (_, value) =>
                                      value ? Promise.resolve() : Promise.reject(new Error('Please agree to the terms')),
                                  },
                                ]}

                            >
                                <Checkbox >I am aware that this ticket will be available at reception at the event. I can collect this ticket from the reception and pay the total amount for this ticket.</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>*/}

                    <Row gutter={0}>
                        <Col xs={24} md={24}>
                            <Form.Item>
                                <FormButton label="Book now" formLoading={this.state.formLoading}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Form action={process.env.REACT_APP_BAFL_REDIRECT_URL} id="PageRedirectionForm" method="post" novalidate="novalidate">
                    <input name="AuthToken" type="hidden" value={this.state.authToken}/>                                                                                                                                
                    <input name="RequestHash" type="hidden" value={this.state.redirectRequestHash}/>                                                                                                                            
                    <input name="ChannelId" type="hidden" value={process.env.REACT_APP_BAFL_CHANNELID}/>                                                                                                                            
                    <input name="Currency" type="hidden" value={process.env.REACT_APP_BAFL_CURRENCY}/>                                                                                                                               
                    <input name="IsBIN" type="hidden" value={process.env.REACT_APP_BAFL_ISBIN}/>                                                                                     
                    <input name="ReturnURL" type="hidden" value={process.env.REACT_APP_BAFL_RETURNURL}/>                                                                            
                    <input name="MerchantId" type="hidden" value={process.env.REACT_APP_BAFL_MERCHANTID}/>                                                                                                                           
                    <input name="StoreId" type="hidden" value={process.env.REACT_APP_BAFL_STOREID}/>                                                                                                                     
                    <input name="MerchantHash" type="hidden" value={process.env.REACT_APP_BAFL_MERCHANTHASH}/>                                  
                    <input name="MerchantUsername" type="hidden" value={process.env.REACT_APP_BAFL_MERCHANTUSERNAME}/>                                                                                                            
                    <input name="MerchantPassword" type="hidden" value={process.env.REACT_APP_BAFL_MERCHANTPASSWORD}/>
                    <input name="TransactionTypeId" type="hidden" value={process.env.REACT_APP_BAFL_TRANSACTIONTYPEID}/>                                                                                                                                                                                
                    <input name="TransactionReferenceNumber" type="hidden" value={selectedTicket && selectedTicket.id}/>                                  
                    <input name="TransactionAmount" type="hidden" value={this.state.grand_total}/>   
                </Form>
            </>
        )
    }
}


export default withRouter(EventRegisterForm);
