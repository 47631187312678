import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { Form, Row, Col, Input, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import { notification} from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities, states } from '../../FreelanceFest/constants'
import { Collapse } from 'antd';
const { Panel } = Collapse;

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;

const { Text } = Typography;


class EventRegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            userDetails: null,
            formLoading: null
        }
    }

    componentDidMount() {
        ReactPixel.track('InitiateCheckout',
            {
                content_ids: ['2715521342086822'],
                eventref: 'fb_oea',
                content_type: 'product'
            }
        )
    }

    onFinish = (values) => {
        const { selectedPackage, selectedEventId } = this.props
        this.setState({formLoading: true})

        if (selectedPackage) {
            pafla.post(`user/events/${selectedEventId}/packages/${selectedPackage.id}/tickets`, values)
            .then((response) => {
                if (!response.data.error) {
                    notification.success({
                        message: 'Success',
                        description: response.data.message                     
                    });
                    const ticketId = response.data.data.id
                    this.props.history.push(`/ticket/${ticketId}/order`)
                } else {
                    notification.error({
                        message: 'Error',
                        description: response.data.message                     
                    });
                }
                this.setState({formLoading: false})
            })
            .catch((error) => {
                this.setState({formLoading: false})
            })
        }
    }

    userDetails() {
        let userDetails = Cookies.get('user_details')
        
        
        let userMeta = {}
        if (userDetails) {
            let userDetailsParsed = JSON.parse(userDetails)

            if (userDetailsParsed) {
                userMeta.email = userDetailsParsed.email
                userMeta.first_name = userDetailsParsed.name.split(" ")[0]
                userMeta.last_name = userDetailsParsed.name.split(" ")[1]
                userMeta.phone = userDetailsParsed.phone 
            }
        }
        
        return userMeta
    }
   
    render() {
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.event_register_form}
                initialValues={{
                    "first_name": this.userDetails().first_name,
                    "last_name": this.userDetails().last_name,
                    "email": this.userDetails().email,
                    "phone_number": this.userDetails().phone,
                    "country": "Pakistan"
                }}
                onFinish={this.onFinish}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 22 }}
                layout="vertical"
            >
                <div className={styles.ticketHead}>
                    <h3>Ticket Details</h3>
                    <hr/>
                </div>
                <Row gutter={15}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your first name' }]}
                            validateTrigger={"onBlur"}
                            name="first_name"
                            label="First Name"
                        >
                            <Input placeholder="First Name" autocomplete="first-name"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your last name' }]}
                            validateTrigger={"onBlur"}
                            name="last_name"
                            label="Last Name"
                        >
                            <Input placeholder="Last Name" autocomplete="family-name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[
                                { type: 'email', message: 'The input is not valid email' },
                                { required: true, message: 'Please input your email' },
                            ]}
                            validateTrigger={"onBlur"}
                            name="email"
                            label="Email"
                        >
                            <Input size="large" placeholder="Email Address" autocomplete="username"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[
                                { required: true, message: 'Please input your National Identification Number' },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (isNaN(value)) {
                                            return Promise.reject("CNIC can only be numbers.");
                                        }
                                        if (value.length !== 13) {
                                            return Promise.reject("Please enter your complete CNIC of 13 digits");
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            validateTrigger={"onBlur"}
                            name="cnic"
                            label="CNIC"
                        >
                            <Input placeholder="Enter your CNIC here" autocomplete="last-name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col xs={24} md={12} >
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your company' }]}
                            validateTrigger={"onBlur"}
                            name="company"
                            label="Company"
                        >
                            <Input placeholder="Company" autocomplete="organization"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your position' }]}
                            validateTrigger={"onBlur"}
                            name="position"
                            label="Position"
                        >
                            <Input placeholder="Position" autocomplete="organization-title"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[
                                { required: true, message: 'Please input your phone number' },
                            ]}
                            validateTrigger={"onBlur"}
                            name="phone_number"
                            label="Phone Number"
                        >
                            <Input size="large" placeholder="Phone Number" autocomplete="tel-national"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please select your city' }]}>
                            <Select
                                placeholder="Select your city"
                                allowClear
                                className={styles.dropdown}
                                autocomplete="address-level2"
                            >
                                {cities.map(city => {
                                    return <Option className={styles.dropdownValue} value={city.title}>{city.title}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col xs={24} md={12}>
                        <Form.Item name="state" label="State" rules={[{ required: true, message: 'Please select your state' }]}>
                            <Select placeholder="Select your state" allowClear>
                                {states.map(city => {
                                    return <Option value={city.title}>{city.title}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Please select your country' }]}>
                            <Select placeholder="Select your country" allowClear>
                                <Option value="Pakistan">Pakistan</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={0}>
                    <Col xs={24} md={24}>
                        <Form.Item name="physical_address" label="Physical Address" rules={[{ required: true, message: 'Please provide your address' }]}>
                            <TextArea 
                                showCount 
                                placeholder="Provide your address" 
                                rows={3}
                                autoSize={{ minRows: 3, maxRows: 4 }}
                                autocomplete="street-address"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <FormButton label="Book your ticket" formLoading={this.state.formLoading}/>
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(EventRegisterForm);
