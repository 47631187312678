import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd'
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla'
import { CheckOutlined } from '@ant-design/icons'
import { useLocation } from "react-router-dom";
import { notification } from 'antd'
import Dashboard from '../../components/dashboard/Main'
import SectionBody from '../../components/dashboard/SectionBody';

const MemberSignup = (props) => {

    const search = useLocation().search;
    const orderId = new URLSearchParams(search).get("id");    
    const [order, setOrder] = useState(null);

    return (
        <Dashboard
            title="Thankyou - Pakistan Freelancers Association"
            loading={false}
            completeProfileCheck={true}
        >
            <Row>
                <SectionBody>
                    <div className={styles.orderThankyou}>
                        <Row gutter={0}>
                            <Col xs={24} className={styles.orderThankyouHeader}>
                                <CheckOutlined />
                                <h3>Your order is placed successfully. Please follow the instructions below to confirm your order.</h3>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col xs={24} md={24}>
                                <p>Thankyou for placing your order, your order currently is pending. To confirm your order please fllow the instructions below:</p>
                                <ol className={styles.orderThankyouInstructions}>
                                    <li>Open your banking App.</li>
                                    <li>Add 1Bill as a beneficary.</li>
                                    <li>Select Invoice/Voucher Payments.</li>
                                    <li>Enter <b>100122{orderId}</b> as your order id.</li>
                                    <li>Transfer the payment amount.</li>
                                </ol>
                            </Col>
                        </Row>
                        <a 
                            href='/dashboard/orders' 
                            target="_self" 
                            className={`btn_default`}
                        >
                            View all orders
                        </a>
                    </div>
                </SectionBody>
            </Row>
        </Dashboard>
    );
};

export default MemberSignup;
