import React from 'react';
import {Card} from 'antd';
import styles from '../../styles/freelance-fest/general.module.css'
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../helpers/constants';


const { Meta } = Card;

const BlogCard = ({image, name, type, priority}) => {
    return (
        <div className={`${styles.sponsor}`}>
            <div className={styles.sponsor_inner}>
                <div className={styles.coverr}>
                    <img src={`${IMAGE_URL}${image}`} alt={name}/>
                </div>
                <div className={styles.details}>
                    <div className={styles.details_inner}>
                        <div className={styles.title_holder}>  
                            <div className={styles.sponsor_company}>{type}</div>                        
                            <div className={styles.sponsor_position}>{name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
