import React from 'react';
import styles from '../../styles/freelance-fest/general.module.css'
import { CalendarOutlined } from '@ant-design/icons';

const eventDate = ({children}) => {
    return (
        <div className={styles.venueDate}>
            <a 
                target="_blank"
                href="https://calendar.google.com/calendar/r/eventedit?text=Freelance+Fest+2022&dates=20220122T040000/20220122T130000&ctz=Etc/GMT+5&details=For+details,+link+here:+https://pafla.org.pk/freelance-fest-2022&location=Pearl+Continental+Hotel+Club+Rd,+Civil+Lines,+Karachi,+Karachi+City,+Sindh,+Pakistan">
                <CalendarOutlined />
                <span className={styles.date}>28<sup>th</sup> 29<sup>th</sup> May 2022</span>
                <span className={styles.date}>Pearl Continental Karachi</span>
            </a>
        </div>
    )
}

export default eventDate;
