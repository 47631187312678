import React from 'react';
import Layout from '../components/Layout';
import {Zoom} from 'react-reveal';
import pafla from '../api/pafla'
import EventCard from '../components/EventCard';
import { Col, Row} from 'antd';
import styles from '../styles/event.module.css'
import {IMAGE_URL} from '../helpers/constants';
import Banner from '../components/Banner';
import { Helmet } from "react-helmet"


class Events extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        events: null
	    }
	}

	async componentDidMount() {
        
        await pafla.get('/events')
        .then((response) => {
            this.setState({events: response.data.data})
        })
    }


    render() {
        return (
        	<Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Events - Pakistan Freelancers Association</title>
                </Helmet>
	            <section id="main">
	                <Row justify="start">
	                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
	                        <Zoom left>
	                            <div className={`card ${styles.events} ${styles.card_items}`}>
	                                {this.state.events && 
                                        Object.entries(this.state.events).map(([key, event], i) => {
                                            const sd = (event && event.start_date) && new Date(event.start_date)
                                            const sdf = (event && event.start_date) && new Intl.DateTimeFormat('en', { 
                                                year: 'numeric', 
                                                month: 'long', 
                                                day: '2-digit', 
                                                hour: "numeric",
                                                minute: "numeric"
                                            }).format(sd);
                                            const link = event.landing_page_link ? event.landing_page_link : `event/${event.slug}/${event.id}`
                                            return (
                                                <EventCard
                                                    classes={styles.eventCard}
                                                    key={event.id}
                                                    title={event.title}
                                                    date={sdf}
                                                    text={`${event.excerpt}`}
                                                    link={link}
                                                    venue={event.venue}
                                                    image={`${process.env.REACT_APP_STORAGE_URL}${event.image}`}
                                                    bodyClasses={styles.bodyclass}
                                                />
                                            )
                                        })
                                    }

	                            </div>
	                        </Zoom>
	                    </Col>
	                </Row>
	            </section>
            </Layout>
        );
    }
}

export default Events;
        