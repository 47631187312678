import React from 'react';
import {Button, Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';
import styles from '../../styles/signUp.module.css'
import { Spin } from 'antd';


class LoginForm extends React.Component {
   
    render() {
        const { formLoading, onClickAction, label, additionalClass } = this.props
        return (
            <>
                {formLoading &&
                    <div className={`${styles.btn_primary_large} form-button ant-button ant-btn-primary`}>
                        {formLoading && <Spin style={{marginTop: "-10px"}}/>}
                    </div>
                }
                {!formLoading &&
                    <Button type="primary" onClick={onClickAction} htmlType="submit" className={`${styles.btn_primary_large} ${additionalClass}`}>
                        {label}
                    </Button>
                }
            </>
        )
    }
}


export default LoginForm;
