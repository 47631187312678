import React from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import pafla from "../api/pafla";
import styles from "../styles/event.module.css";
import { Spin } from "antd";
import { notification } from "antd";
import {
    TagOutlined,
    CloseOutlined
} from "@ant-design/icons";
import { withRouter } from "react-router";
import ReactPixel from 'react-facebook-pixel'

const { confirm } = Modal;

class DiscountClaimButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            loading: true,
            profileCompleted: false,
            showConfirmation: false,
            promotionURL: null,
            promotionCode: null
        };
    }

    async componentDidMount() {
        await pafla.get("/user/profile-completed").then((response) => {
            this.setState({ loading: false });
            this.setState({ profileCompleted: response.data.data });
        });
    }

    claim = async () => {
        const { profileCompleted } = this.state;
        const { discount } = this.props;

        if (!profileCompleted) {
            this.showConfirm(
                "Please complete your profile",
                <CloseOutlined />,
                "We've noticed you haven't completed your profile. Please complete your profile before continuing",
                () => this.redirectCompleteProfile()
            );
        } else {
            this.claimDiscount();
        }
    };

    redirectCompleteProfile = () => {
        this.props.history.push({
            pathname: '/dashboard/complete-profile',
            state: { referrer: window.location.pathname }
        });
    }

    claimDiscount = async () => {
        const { profileCompleted } = this.state;
        const { discount, history } = this.props;

        this.setState({ loading: true });
        await pafla
            .get(`discount/${discount.id}/claim`)
            .then((response) => {
                this.setState({ loading: false });
                if (!response.data.error) {
                    this.setState({ showConfirmation: true });
                    ReactPixel.track('SubmitApplication');
                    notification.success({
                        message: "Success",
                        description: response.data.message,
                    });
                    this.setState({ promotionURL: response.data.data.discount_link })
                    this.setState({ promotionCode: response.data.data.discount_code })
                } else {
                    notification.error({
                        message: "Error",
                        description: response.data.message,
                    });
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                notification.error({
                    message: "Error",
                    description: "An error occurred while making the request. Please try again later.",
                });
            });
    };

    showConfirm = (title, icon, content, onOk, onCancel) => {
        confirm({
            title: title,
            icon: icon,
            content: content,
            className: "mystyle",
            centered: true,
            onOk: onOk,
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    copyToClibboard = () => {
        navigator.clipboard.writeText(this.state.promotionCode)
        notification.success({
            message: "Success",
            description: "Promotion code copied to clibboard",
        });
    }

    render() {
        const { discount, discountLoading } = this.props;
        const { loading, showConfirmation, promotionCode } = this.state;
        return (
            <>
                <Button
                    type='primary'
                    onClick={this.claim}
                    className={`btn_default ${styles.event_register_btn}`}
                >
                    {loading || discountLoading ? (
                        <Spin style={{ marginTop: "-10px" }} />
                    ) : (
                        "Redeem now"
                    )}
                </Button>

                <Modal
                    title="Reedeem your discount"
                    centered
                    open={showConfirmation}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConfirmation: false })}
                    footer={null}
                >
                    <div>
                        <h4>
                            Redemption code
                        </h4>
                        <h2 className={styles.promotionCodeField}>
                            <label for="coupon" style={{marginRight: '15px'}}><TagOutlined /></label>
                            <span id="coupon">{promotionCode}</span>
                            <span className={styles.copyPromotionCode} onClick={this.copyToClibboard}>COPY</span>
                        </h2>

                        <Button
                            type='primary'
                            className={`btn_default ${styles.event_register_btn}`}
                        >
                            <a
                                href={this.state.promotionURL}
                                rel='noreferrer'
                                target='_blank'
                            >
                                Continue to {discount.company_name}
                            </a>
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(DiscountClaimButton);
