import React from 'react';
import {Card} from 'antd';
import {Fade} from 'react-reveal';
import styles from '../styles/card.module.css'
import { Markup } from 'interweave';


const Event = ({icon,title,shortDescription, longDescription}) => {
    return (
        <Card
            className={`${styles.blog_card} ${styles.benefit_card}`}
            bordered={true}
        >
            <span className={styles.benefittitleholder}>
                <span className={styles.benefiticon}>{icon}</span>
                <h3 title={title}>{title}</h3>
            </span>
            <p className={styles.ticket}>{shortDescription}</p>
        </Card>
    );
};

export default Event;
