import React from 'react';
import Dashboard from '../../components/dashboard/Main';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import SectionSidebar from '../../components/dashboard/SectionSidebar'
import { NavLink } from 'react-router-dom'
import {MEMBERSHIP, ORDERS} from '../../helpers/constants';
import pafla from '../../api/pafla'
import Cookies from 'js-cookie';
import type { ColumnsType } from 'antd/lib/table';
import { Row, Menu, Table } from 'antd';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}


const columns: ColumnsType<DataType> = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date'
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Payment Method',
		dataIndex: 'method',
		key: 'method',
	},
	{
		title: 'Paid for',
		key: 'paid',
		dataIndex: 'paid'
	}
];


class Orders extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			orders: [],
			loading: true,
			hasMore: true,
		}
	}

	componentDidMount() {

		const loggedCookie = Cookies.get('auth')

		if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
			pafla.get('/user/orders')
			.then((response) => {

				this.setState({loading: false})
				response.data.data.map((order) => {

					var getDate = new Date(order.created_at);

					var obj = {
					    date : getDate.getDate() + '/' + getDate.getMonth() + '/' + getDate.getFullYear(),
					    amount : order.grand_total,
					    method : order.paymentmethod.name
					};

					if (order.ticket) {
						obj.paid = order.ticket.event_name
					}

					this.setState({ orders: [...this.state.orders, obj] })
					return null
				})
			})
		} else {
			this.props.history.push('/')
		}
	}


	render() {
		const { loading } = this.state
		return (
			<Dashboard loading={loading}>
				<Row>
					<SectionSidebar width="6" title="Navigate">
					    <Menu>
					        <Menu.Item>
					            <NavLink to={MEMBERSHIP}>Membership Details</NavLink>
					        </Menu.Item>
					        <Menu.Item>
					            <NavLink to={ORDERS}>Order History</NavLink>
					        </Menu.Item>
					    </Menu>
					</SectionSidebar>
					<SectionBody width="18">		        	
						<SectionHeader>
						    <h2>Order History</h2>
						</SectionHeader>
					    <Table columns={columns} dataSource={this.state.orders} />
					</SectionBody>
				</Row>
		    </Dashboard>
		);
	}
}

export default Orders;
		