import React, { useState } from 'react';
import styles from '../../styles/dashboard.module.css';
import { Button, Drawer, Col } from 'antd';
import { isMobile } from 'react-device-detect'
import { FilterFilled } from '@ant-design/icons';

const SectionHeader = ({title = null, width = 12, children}) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            {(!isMobile ) ?
                <Col xs={{span: 24}} md={{span: width}}>
                    <div className={styles.dashboardSectionSidebar}>
                        {title && <h2>{title}</h2> }
                        {children}
                    </div>
                </Col>
            :
                <div className="dashboard-sidebar">
                    <Button className={styles.dashboardFilter} type="primary" onClick={showDrawer}>
                        <FilterFilled />
                    </Button>
                    <Drawer
                        title={title}
                        placement="bottom"
                        width={500}
                        onClose={onClose}
                        open={open}
                        className="dashboard-sidebar"
                    >
                        <div className={styles.dashboardSectionSidebar}>
                            {children}
                        </div>
                    </Drawer>
                </div> 
            }
        </>      
    );
};

export default SectionHeader;
