import React from 'react'
import { Form, Row, Col, Input, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import { notification} from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities } from '../../FreelanceFest/constants'
import { Collapse } from 'antd';
import PriceFormatted from '../../PriceFormatted'
const { Panel } = Collapse;

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;

const { Text } = Typography;

class LoginForm extends React.Component {

    calculateDiscount = () => {
        const { selectedTicket } = this.props
        let discount = 0;

        if(selectedTicket) {
            if (selectedTicket.discounted_price) {
                discount = selectedTicket.price - selectedTicket.discounted_price
            }
        }
        return discount

    }

    calculateTotal = () => {
        const { selectedTicket } = this.props
        let total = 0;

        if(selectedTicket) {
            if (selectedTicket.discounted_price) {
                total = selectedTicket.discounted_price
            } else {
                total = selectedTicket.price
            }
        }
        return total

    }
   
    render() {
        const { selectedTicket } = this.props
        return (
            <>
                {selectedTicket &&
                    <div className={`${styles.selectedEventContainer} ${styles.desktop}`}>
                         <div className={styles.ticketHead}>
                             <h3>Order Totals</h3>
                             <hr/>
                         </div>
                         <div className={styles.eventeventTotalHeadCol}>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Subtotal</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={selectedTicket.price}/></span>
                             </div>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Discount</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateDiscount()}/></span>
                             </div>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Shipping</span>
                                 <span className={styles.eventTotalInfo}>Free</span>
                             </div>
                             <hr/>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Total</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateTotal()}/></span>
                             </div>
                         </div>
                    </div>
                }

                {selectedTicket &&
                    <Collapse expandIconPosition="right" className={`${styles.selectedEventContainer} ${styles.mobile}`} ghost accordion>
                        <Panel header="Order Totals" key="1">
                            <div className={styles.eventeventTotalHeadCol}>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Subtotal</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={selectedTicket.price}/></span>
                                 </div>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Discount</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateDiscount()}/></span>
                                 </div>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Shipping</span>
                                     <span className={styles.eventTotalInfo}>Free</span>
                                 </div>
                                 <hr/>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Total</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateTotal()}/></span>
                                 </div>
                             </div>
                        </Panel>
                    </Collapse>
                }
            </>
        )
    }
}


export default withRouter(LoginForm);
