const HOME = '/';
const LOANS = '/loans';
const SIGN_UP = '/register';
const LOG_IN = '/login';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password';
const UNSUBSCRIBE = '/unsubscribe';
const HELP = '/help';
const ABOUT = '/about-us';
const BLOGS = '/blog';
const BLOG = '/blog/:blogSlug/:blogId';
const EVENTS = '/events';
const EVENT = '/event/:eventSlug/:eventId';
const OUR_VOICE = '/our-voice';
const IMAGE_URL = process.env.PUBLIC_URL + '/img/';
const PROFILE = '/profile';
const LOGOUT = '/logout';
const PRESSRELEASES = '/press-releases';
const PRESSRELEASE = '/press-release/:prSlug/:prId';
const PUBLICATIONS = '/publications';
const ISSUES = '/dashboard/issues';
const ISSUE = '/dashboard/issues/:issueId';
const ORDERS = '/dashboard/orders';
const NEW_ISSUE = '/dashboard/issues/new';
const MEDIA_MENTIONS = '/media-mentions';
const BOARD = '/board';
const MEMBERSHIP_JOIN = '/membership';
const FREELANCE_FEST = '/freelance-fest-2022';
const FREELANCE_FEST_ISLAMABAD = '/freelancefest-2022-islamabad';
const FREELANCE_FEST_ISLAMABAD_TWO = '/freelance-fest-2022-islamabad';
const FREELANCE_FEST_GILGIT = '/freelance-fest-2022-gilgit';
const EVENT_REGISTER = '/enroll/event/:eventId/package/:packageId';
const TICKET_ORDER = '/ticket/:ticketId/order';
const ORDER_THANKYOU = '/order/thankyou';
const DASHBOARD = '/dashboard';
const COMPLETE_PROFILE = '/dashboard/complete-profile';
const MEMBER_PROFILE = '/member/:memberId';
const MEMBERSHIP = '/dashboard/membership';
const MEMBERSHIP_BUY = '/dashboard/membership/:packageName/order';
const ORDER_ONEBILL = '/order/onebill';
const JOBS = '/dashboard/jobs';
const PEERS = '/dashboard/peers';
const DASHBOARD_EVENTS = '/dashboard/events';
const DASHBOARD_EVENT = '/dashboard/event/:eventSlug/:eventId';
const MENTORSHIP = '/dashboard/mentorship';
const TRAINING = '/dashboard/training';
const TESTS = '/dashboard/tests';
const FILINGS = '/dashboard/filings';
const INSURANCE = '/dashboard/insurance';
const CHAPTER_CONTRIBUTOR = '/dashboard/chapter-contributor';
const UNIVERSITY_AMBASSADOR = '/dashboard/university-ambassador';
const HOST_YOUR_MEETUP = '/dashboard/meetups';
const APPLY_AS_A_SPEAKER = '/dashboard/speaker';
const EDIT_PROFILE = '/dashboard/edit-profile';
const PROMOTIONS = '/dashboard/promotions';
const PROMOTION = '/dashboard/promotion/:companyName/:promotionId';
const GCC_DASHBOARD_LANDING = '/dashboard/pafla-google-career-certificates';
const GCC_ENROLL = '/dashboard/:programId/enroll-course/:courseId/:courseName';
const GCC_LANDING = '/pafla-google-career-certificates';
const GCC_THANKYOU = '/dashboard/course/thankyou';
const SOFTSKILLS_LANDING = '/pafla-google-soft-skills';
const DASHBOARD_COURSE = '/dashboard/course/:courseSlug/:courseId';
const COURSE_CERTIFY = '/dashboard/get-certified/:courseId';
const START_QUIZ = '/dashboard/:courseId/start-quiz/';
const QUIZ_RESULT = '/dashboard/:courseId/quiz-result/:attemptId';

const SITE_URL = window.location.protocol + '//' + window.location.host;

export {
	ABOUT,
	APPLY_AS_A_SPEAKER,
	BLOG,
	BLOGS,
	BOARD,
	CHAPTER_CONTRIBUTOR,
	COMPLETE_PROFILE,
	DASHBOARD,
	DASHBOARD_EVENT,
	DASHBOARD_EVENTS,
	EDIT_PROFILE,
	EVENT,
	EVENT_REGISTER,
	EVENTS,
	FILINGS,
	FORGOT_PASSWORD,
	UNSUBSCRIBE,
	FREELANCE_FEST,
	FREELANCE_FEST_ISLAMABAD,
	FREELANCE_FEST_ISLAMABAD_TWO,
	HELP,
	HOME,
	HOST_YOUR_MEETUP,
	IMAGE_URL,
	INSURANCE,
	ISSUE,
	ISSUES,
	JOBS,
	LOANS,
	LOG_IN,
	LOGOUT,
	MEDIA_MENTIONS,
	MEMBER_PROFILE,
	MEMBERSHIP,
	MEMBERSHIP_BUY,
	MENTORSHIP,
	NEW_ISSUE,
	ORDER_THANKYOU,
	ORDER_ONEBILL,
	ORDERS,
	PEERS,
	PUBLICATIONS,
	PRESSRELEASE,
	PRESSRELEASES,
	PROFILE,
	RESET_PASSWORD,
	SIGN_UP,
	SITE_URL,
	TESTS,
	TICKET_ORDER,
	TRAINING,
	UNIVERSITY_AMBASSADOR,
	FREELANCE_FEST_GILGIT,
	MEMBERSHIP_JOIN,
	OUR_VOICE,
	PROMOTIONS,
	PROMOTION,
	GCC_LANDING,
	GCC_DASHBOARD_LANDING,
	GCC_ENROLL,
	GCC_THANKYOU,
	SOFTSKILLS_LANDING,
	DASHBOARD_COURSE,
	COURSE_CERTIFY,
	START_QUIZ,
	QUIZ_RESULT,
};

export const packages = [
	{
		name: 'Seed',
		memberPrice: 0,
		buttonText: 'Downgrade',
		disabled: false,
		buttonLink: '/dashboard/membership/seed/order',
		features: [
			{
				name: 'Voice & Support Group',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Discount & Promotions',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Exclusive Events',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Membership Certificate',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},

			{
				name: 'Remote internet payment gateway',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
		],
	},
	{
		name: 'Surge',
		memberPrice: 500,
		type: '/mo',
		buttonText: 'Subscribe',
		disabled: false,
		buttonLink: '/dashboard/membership/surge/order',
		features: [
			{
				name: 'All in Seed +',
			},
			{
				name: 'Annual Tax Filing',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Accidental Health Insurance',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Workshops and Trainings',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
			{
				name: 'Exclusive walk-in access to events',
				/*description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."*/
			},
		],
	},
];
