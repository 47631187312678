import React from 'react';
import {Row, Col } from 'antd';
import styles from '../styles/home.module.css'
import Partner from './Partner'
import pafla from '../api/pafla'
import {IMAGE_URL} from '../helpers/constants';

class CorporatePartners extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            corporatePartners: [],
            partners: [],
            more: false
        }
    }

    async componentDidMount() {
        
        await pafla.get('/corporate-partners')
        .then((response) => {
            this.setState({corporatePartners: response.data.data})
        })

        await pafla.get('/partners')
        .then((response) => {
            this.setState({partners: response.data.data})
        })
    }

    toggleViewMore = () => {
        this.setState({more: !this.state.more})
    }


    render() {
        const { corporatePartners, partners, more } = this.state
        return (
            <section id={this.props.sectionId} className={`${styles.section} ${styles.partners}`}>
                <Row className='contained-row' justify="space-between">
                    <Row justify="space-between">
                        <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                            <div className={styles.section_title_container}>
                                <h1>Our Corporate Partners</h1>
                                <h2>We gratefully acknowledge the generosity of our partners. Their support, helps make it possible to accomplish all that we do.</h2>
                            </div>
                            <div className={styles.partner_items}>                                
                                {corporatePartners && 
                                    corporatePartners.map(corporatePartner => {
                                        return (
                                            <Partner 
                                                image={`${process.env.REACT_APP_STORAGE_URL}/${corporatePartner.logo}`}
                                                name={corporatePartner.name} 
                                                type="Corporate Partner"
                                            />
                                        )                                            
                                    })
                                }
                                {partners && more && 
                                    partners.map(partner => {
                                        return (
                                            <Partner 
                                                image={`${process.env.REACT_APP_STORAGE_URL}/${partner.logo}`}
                                                name={partner.name}
                                            />
                                        )                                            
                                    })
                                }
                            </div>
                            <span onClick={this.toggleViewMore} className={styles.viewMorePartners}>{more ? 'View Less' : 'View More'}</span>
                        </Col>
                    </Row>
                </Row>
            </section>
        );
    }
}

export default CorporatePartners;
