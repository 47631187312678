import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { Zoom } from 'react-reveal';
import pafla from '../api/pafla';
import { Col, Row } from 'antd';
import { IMAGE_URL } from '../helpers/constants';
import { checklist, faqs, caseStudies, courses } from '../helpers/gcc-constants';
import { Helmet } from 'react-helmet';
import styles from '../styles/membershipjoin.module.css';
import gccstyles from '../styles/gccstyles.module.css';
import Offerrings from '../components/Offerrings';
import {
	CheckCircleIcon,
	GlobeAltIcon,
	BoltIcon,
	AcademicCapIcon,
	DocumentCheckIcon,
	HeartIcon,
	FireIcon,
	HandRaisedIcon,
	BanknotesIcon,
} from '@heroicons/react/24/solid';
import { Collapse } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import ReactPlayer from 'react-player/youtube';

const { Panel } = Collapse;

const swiperParams = {
	spaceBetween: 50,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
};

class Membership extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: null,
		};
	}

	render() {
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Google Career Certificates - Pakistan Freelancers Association</title>
				</Helmet>
				<section className={`${styles.section}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<Zoom left>
									<Row>
										<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
											<Row align="left">
												<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
													<img src={`${IMAGE_URL}GCC-KV.jpg`} alt="" style={{ margin: 'auto' }} />
												</Col>
												<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto', textAlign: 'left' }}>
													<h2 style={{ fontSize: '1.8rem' }}>Learn job-ready skills with Google</h2>
													<p>
														Google Career Certificates provide hands-on training in fields like data analytics, digital marketing, IT support,
														cybersecurity, and more, without needing a college degree. Taught by experienced Google employees, these practical
														programs can be completed part-time in 3-6 months.
													</p>
													<p>
														PAFLA and Google have teamed up to offer fully funded Google Career Certificates scholarships to 500 high-performing
														freelancers! We know that with Google's scholarships, you're not just another freelancer—you're a certified expert, armed
														with the latest industry insights and techniques. Check out all the Google Career Certificates Scholarships Courses and
														apply now!
													</p>
													<p>
														Additionally, all PAFLA members also get exclusive access to the Google Curated Soft Skill Program designed to empower you
														in communication, personal branding, critical thinking, and time management skills. Soft Skills Program launches soon -
														stay tuned!
													</p>
													<p>
														<a
															href="#gcc-courses"
															title="View Courses"
															className={`ant-btn ant-btn-default ant-btn-lg btn_default ${styles.cta_button}`}
														>
															View Courses and Apply Now
														</a>
													</p>
												</Col>
											</Row>
										</Col>
									</Row>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>
				<section className={`${styles.section} ${gccstyles.caseStudiesSection}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<Zoom left>
									<Row>
										<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
											<Row align="left">
												<Col xs={{ span: 24 }} md={{ span: 12 }} style={{ margin: '0 auto' }}>
													<h2 className={gccstyles.gccHalfHeading}>Your journey as a Google Career Certifications Scholar with PAFLA</h2>
												</Col>
												<Col xs={{ span: 24 }} md={{ span: 11, push: 1 }} style={{ margin: '0 auto', textAlign: 'left' }}>
													<div className={gccstyles.checklist}>
														{checklist &&
															Object.entries(checklist).map(([key, item], i) => {
																return (
																	<div className={gccstyles.checklistItem}>
																		<div className={gccstyles.checkIcon}>
																			<CheckCircleIcon />
																		</div>
																		{!item.link ? (
																			item.name
																		) : (
																			<a style={{ color: 'inherit' }} href={item.link}>
																				<span dangerouslySetInnerHTML={{ __html: item.name }} />
																			</a>
																		)}
																	</div>
																);
															})}
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>

				<section id="gcc-courses" className={`${styles.section}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Google Career Certificate Programs</h2>
									<p>
										Google offers multiple high-growth professional skill certificate programs. You can check out the details about each certificate
										in this section. Apply now to avail a scholarship for your preferred program.
									</p>
								</div>
								<div className={`${styles.packages} ${styles.offerrings}`}>
									{courses.map((course) => {
										return (
											<Offerrings
												name={course.name}
												imageSrc={course.imageSrc}
												description={course.description}
												features={course.features}
												button1Text={course.button1Text}
												button1Link={course.button1Link}
												button2Text={course.button2Text}
												button2Link={course.button2Link}
											/>
										);
									})}
								</div>
							</Col>
						</Row>
					</Row>
				</section>

				<section id={this.props.sectionId} className={`${styles.section} ${gccstyles.caseStudiesSection}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>GCC Graduate Success Stories </h2>
									<p>
										Have a look at what Afreen's successful story as a freelancer and others who made it big after graduating as a Google Career
										Certificate Scholar
									</p>
								</div>
								<div>
									<div className={styles.swiperContainer}>
										<Swiper className={styles.swiperSlider} {...swiperParams} modules={[Navigation]}>
											{caseStudies &&
												Object.entries(caseStudies).map(([key, caseStudy], i) => {
													return (
														<SwiperSlide>
															<div className={gccstyles.case_study}>
																<div className={gccstyles.case_study_video}>
																	<ReactPlayer url={caseStudy.video_url} light={true} className={gccstyles.videoPlayer} />
																</div>
																<div className={gccstyles.case_study_details}>
																	<h3>Graduate Case Studies</h3>
																	<h2 style={{ fontSize: '1rem' }}>"{caseStudy.quote}"</h2>
																	<h3>
																		{caseStudy.name}, {caseStudy.designation}
																	</h3>

																	<div class={gccstyles.case_study_navigation}>
																		<div className={`swiper-button-prev ${gccstyles.swiperleftnav}`} />
																		<div className={`swiper-button-next ${gccstyles.swiperrighttnav}`} />
																	</div>
																</div>
															</div>
														</SwiperSlide>
													);
												})}
										</Swiper>
									</div>
								</div>
							</Col>
						</Row>
					</Row>
				</section>
				<section id="screening-criteria" className={`${styles.section}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Screening Criteria for Google Career Certificates Program</h2>
									<p>
										Our screening criteria for Google Career Certificates applications focus on creating an inclusive and diverse learning
										environment. We evaluate applications based on geographical, gender, age and occupational factors.
									</p>
								</div>

								<ul style={{ textAlign: 'left', marginTop: '30px', marginBottom: '20px', paddingLeft: '0px' }}>
									<li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
										<CheckCircleIcon style={{ maxWidth: '25px', marginRight: '10px', color: '#fc8900' }} />
										Geographical distribution ensures equal opportunity access, addressing regional disparities.
									</li>
									<li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
										<CheckCircleIcon style={{ maxWidth: '25px', marginRight: '10px', color: '#fc8900' }} /> Gender distribution fosters a balanced
										tech workforce by promoting diversity.
									</li>
									<li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
										<CheckCircleIcon style={{ maxWidth: '25px', marginRight: '10px', color: '#fc8900' }} /> Age distribution recognizes varied career
										stages, supporting lifelong learning.
									</li>
									<li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
										<CheckCircleIcon style={{ maxWidth: '25px', marginRight: '10px', color: '#fc8900' }} /> Occupational distribution enriches
										collaborative learning, uniting diverse backgrounds.
									</li>
								</ul>
							</Col>
						</Row>
					</Row>
				</section>
				<section className={`${styles.section} ${styles.faqs} ${styles.partners}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Common Questions</h2>
									<p>
										The following answers are provided to address the frequently asked questions regarding the matter at hand. We hope that these
										answers will provide you with the information you need to make an informed decision.{' '}
									</p>
								</div>
								<Zoom left>
									<div className={styles.qaPanels}>
										<Collapse defaultActiveKey={['1']}>
											{faqs.map((faq) => {
												return (
													<Panel className={styles.question} header={faq.question} key={faq.id}>
														<p className={styles.answer}>{faq.answer}</p>
													</Panel>
												);
											})}
										</Collapse>
									</div>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>
			</Layout>
		);
	}
}

export default Membership;
