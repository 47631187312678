import React, { useEffect, useState, useRef } from "react";
import { Row, Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import Dashboard from "../../components/dashboard/Main";
import SectionBody from "../../components/dashboard/SectionBody";
import SectionSidebar from "../../components/dashboard/SectionSidebar";
import PrimarySkillFilter from "../../components/dashboard/PrimarySkillFilter";
import HourlyRateFilter from "../../components/dashboard/HourlyRateFilter";
import Peer from "../../components/dashboard/Peer";
import Loader from '../../components/Loader'
import pafla from "../../api/pafla";

const MemberSignup = (props) => {
    const peersRef = useRef(null);
    const [peers, setPeers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [skill, setSkill] = useState([]);
    const [hourlyRate, sethourlyRate] = useState(null);
    const [nextPage, setNextPage] = useState(2);
    const [hasMorePeers, sethasMorePeers] = useState(true);

    useEffect(() => {
        if (!peers && (skill === undefined || skill.length == 0)) {
            getPeers(props);
        } else {
            getPeersbyAttributes();
        }

    }, [skill, hourlyRate]);

    const getPeers = async (props) => {
        await pafla.get(`peers`).then((response) => {
            let newPeers = response.data.data.items;

            setPeers(newPeers);

            setLoading(false);
        });
    };

    const getMorePeers = async (props) => {
        await pafla.get(`peers`, {
            params: {
                skills: skill.join("+"),
                hourly_rate: hourlyRate,
                page: nextPage
            },
        }).then((response) => {
            let newPeers = response.data.data.items;
            let { current_page, last_page } = response.data.data.pagination;

            if(nextPage > 1) {
                console.log("not refreshed")
                setPeers((prevPeers) => [...prevPeers, ...newPeers]);
            } else {
                console.log("refreshed")
                setPeers(newPeers);
            }

            

            if( parseInt(current_page) === parseInt(last_page) ) {
                sethasMorePeers(false)
            } else {
                setNextPage((nextPage) => nextPage + 1);
            }
            

            setLoading(false);
        });
    };

    const setPeersbySkills = (selected, selectedSkill) => {
        if (selected) {
            setSkill([...skill, selectedSkill]);
        } else {
            setSkill(skill.filter((value) => value !== selectedSkill));
        }
    };

    const setPeersbyHourlyRate = (selected, selectedRate) => {
        sethourlyRate(selectedRate);
    };

    const getPeersbyAttributes = async () => {
        await pafla
            .get(`peers`, {
                params: {
                    skills: skill.join("+"),
                    hourly_rate: hourlyRate,
                    page: 1
                },
            })
            .then((response) => {
                let newPeers = response.data.data.items;
                setPeers(newPeers);
                setLoading(false);
                setNextPage(2)
            });
    };

    return (
        <Dashboard loading={loading} completeProfileCheck={true}>
            <Row>
                <SectionSidebar title='Filter by' width='6'>
                    <PrimarySkillFilter
                        setPeersbySkills={(selected, selectedSkill) =>
                            setPeersbySkills(selected, selectedSkill)
                        }
                    />
                    <HourlyRateFilter
                        setPeersbyHourlyRate={(selected, selectedRate) =>
                            setPeersbyHourlyRate(selected, selectedRate)
                        }
                    />
                </SectionSidebar>
                <SectionBody width='18' innerRef={peersRef}>
                    {peers && (
                        <InfiniteScroll
                            dataLength={peers.length} //This is important field to render the next data
                            next={getMorePeers}
                            hasMore={hasMorePeers}
                            loader={<Loader margin="20px auto"/>}
                        >
                            {peers?.map((peer) => {
                                return <Peer key={peer.id} peer={peer} />;
                            })}
                        </InfiniteScroll>
                    )}
                </SectionBody>
            </Row>
        </Dashboard>
    );
};

export default MemberSignup;
