import React from 'react';
import pafla from '../api/pafla'
import { withRouter } from 'react-router'
import Cookies from 'js-cookie';
import { message } from 'antd';


class Logout extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        blogs: null,
	        imageURL: null
	    }
	}

	async componentDidMount() {

        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
            await pafla.post('user/logout')
            .then((response) => {
                message.success(response.data.message)
                this.props.history.goBack() 
                Cookies.remove('auth', '', { path: '/' })
                
            })
            .catch( () => {
                message.success('Logged out Successfully')
                this.props.history.goBack()  
                Cookies.remove('auth', '', { path: '/' }) 
            })
        } else {
            this.props.history.push('/') 
            Cookies.remove('auth', '', { path: '/' })
            message.success('Logged out Successfully')
        }

        this.props.history.push('/')   
    }


    render() {
        return null
    }
}

export default withRouter(Logout);
        