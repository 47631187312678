import React from "react";
import Dashboard from "../../components/dashboard/Main";
import SectionHeader from "../../components/dashboard/SectionHeader";
import SectionBody from "../../components/dashboard/SectionBody";
import pafla from "../../api/pafla";
import { Col } from "antd";
import styles from "../../styles/issues.module.css";
import Cookies from "js-cookie";
import { SITE_URL } from "../../helpers/constants";
import {
    Comment,
    Avatar,
    Row,
    Button,
    Modal,
    Tag,
    Form,
    Input,
    notification,
} from "antd";
import Moment from "react-moment";
import "moment-timezone";
import FormButton from "../../components/auth/FormButton";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";

const { TextArea } = Input;

class Issue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            issues: [],
            loading: true,
        };
    }

    componentDidMount() {
        const { issueId } = this.props.match.params;

        const loggedCookie = Cookies.get("auth");
        const loggedUser = Cookies.get("user_details");

        if (
            !!loggedCookie ||
            typeof loggedCookie != undefined ||
            loggedCookie !== "undefined"
        ) {
            pafla.get(`/user/issues/${issueId}`).then((response) => {
                this.setState({ issue: response.data.data });
                this.setState({ loading: false });
                this.fetchComments();
            });
        } else {
            this.setState({ loading: false });
            this.props.history.push("/");
        }

        if (
            !!loggedUser ||
            typeof loggedUser != undefined ||
            loggedUser !== "undefined"
        ) {
            this.setState({ user: JSON.parse(loggedUser) });
        }
    }

    fetchComments() {
        const { issueId } = this.props.match.params;
        pafla.get(`/user/issues/${issueId}/comments`).then((response) => {
            this.setState({ comments: response.data.data });
        });
    }

    openIssue = () => {
        const { issueId } = this.props.match.params;
        this.setState({ issueOpenLoading: true });
        pafla.post(`/user/issues/${issueId}/open`).then((response) => {
            if (!response.data.error) {
                this.setState({ issueOpenLoading: false });
                this.setState({ isModalOpen: false });
                this.setState({ issue: response.data.data });
                notification.success({
                    message: "Success",
                    description: response.data.message,
                });
            } else {
                this.setState({ issueOpenLoading: false });
                this.setState({ isModalOpen: false });
                notification.error({
                    message: "Error",
                    description: response.data.message,
                });
            }
        });
    };

    resolveIssue = () => {
        const { issueId } = this.props.match.params;
        this.setState({ issueResolvedLoading: true });
        pafla.post(`/user/issues/${issueId}/resolve`).then((response) => {
            if (!response.data.error) {
                this.setState({ issueResolvedLoading: false });
                this.setState({ isModalOpen: false });
                this.setState({ issue: response.data.data });
                notification.success({
                    message: "Success",
                    description: response.data.message,
                });
            } else {
                notification.error({
                    message: "Error",
                    description: response.data.message,
                });
            }
        });
    };

    closeIssue = () => {
        const { issueId } = this.props.match.params;
        this.setState({ issueClosedLoading: true });
        pafla.post(`/user/issues/${issueId}/close`).then((response) => {
            if (!response.data.error) {
                this.setState({ issueClosedLoading: false });
                this.setState({ isModalOpen: false });
                this.setState({ issue: response.data.data });
                notification.success({
                    message: "Success",
                    description: response.data.message,
                });
            } else {
                notification.error({
                    message: "Error",
                    description: response.data.message,
                });
            }
        });
    };

    renderActions() {
        return <span key='comment-basic-reply-to'>Reply to</span>;
    }

    showModal() {
        this.setState({ isModalOpen: true });
    }

    hideModal() {
        this.setState({ isModalOpen: false });
    }

    renderIssue() {
        const { issue } = this.state;
        return (
            <>
                {issue && (
                    <div className={styles.issue}>
                        <SectionHeader>
                            <div className={styles.issueMeta}>
                                <h2>{issue.title}</h2>
                            </div>
                            {issue.status && (
                                <div className={styles.issueTag}>
                                    <Button
                                        onClick={() => this.showModal()}
                                        className={styles.new_issue_button}
                                    >
                                        Change Status
                                    </Button>
                                    <Tag>{issue.status.name}</Tag>

                                    <Modal
                                        title='Change Status'
                                        open={this.state.isModalOpen}
                                        onCancel={() => this.hideModal()}
                                        footer={[]}
                                    >
                                        <div className={styles.issueActions}>
                                            <FormButton
                                                label='Reopen Issue'
                                                onClickAction={this.openIssue}
                                                formLoading={
                                                    this.state.issueOpenLoading
                                                }
                                                additionalClass={
                                                    styles.issueActionButtons
                                                }
                                            />
                                            <FormButton
                                                label='Mark Resolved'
                                                onClickAction={
                                                    this.resolveIssue
                                                }
                                                formLoading={
                                                    this.state
                                                        .issueResolvedLoading
                                                }
                                                additionalClass={
                                                    styles.issueActionButtons
                                                }
                                            />
                                            <FormButton
                                                label='Mark Closed'
                                                onClickAction={this.closeIssue}
                                                formLoading={
                                                    this.state
                                                        .issueClosedLoading
                                                }
                                                additionalClass={
                                                    styles.issueActionButtons
                                                }
                                            />
                                        </div>
                                    </Modal>
                                </div>
                            )}
                        </SectionHeader>

                        <Comment
                            key={issue.id}
                            actions={this.renderActions()}
                            author={issue.author.name}
                            avatar={
                                <Avatar
                                    src={issue.author.avatar}
                                    alt={issue.author.name}
                                    size={40}
                                />
                            }
                            content={
                                <div className={styles.issueDescription}>
                                    {issue.description}
                                </div>
                            }
                            datetime={
                                <>
                                    <Tag className={styles.memberTag}>
                                        {issue.author.role}
                                    </Tag>
                                    <Moment tz='UTC' fromNow>
                                        {issue.created_at}
                                    </Moment>
                                </>
                            }
                        ></Comment>
                    </div>
                )}
            </>
        );
    }

    renderComments() {
        return (
            <div className={styles.comments}>
                {this.state.comments &&
                    this.state.comments.map((comment) => {
                        return (
                            <Comment
                                author={comment.author.name}
                                avatar={
                                    <Avatar
                                        src={comment.author.avatar}
                                        alt={comment.author.name}
                                        size={40}
                                    />
                                }
                                content={
                                    <div className={styles.issueDescription}>
                                        {comment.description}
                                    </div>
                                }
                                datetime={
                                    <>
                                        <Tag className={styles.memberTag}>
                                            {comment.author.role}
                                        </Tag>
                                        <Moment tz='UTC' fromNow>
                                            {comment.created_at}
                                        </Moment>
                                    </>
                                }
                            />
                        );
                    })}
            </div>
        );
    }

    commentBoxOnSubmit = (values) => {
        this.setState({ commentLoading: true });
        const { issueId } = this.props.match.params;
        pafla
            .post(`user/issues/${issueId}/comments/create`, values)
            .then((response) => {
                if (!response.data.error) {
                    notification.success({
                        message: "Success",
                        description: response.data.message,
                    });
                    this.fetchComments();
                } else {
                    notification.error({
                        message: "Error",
                        description: response.data.message,
                    });
                }
                this.setState({ commentLoading: false });
            });
    };

    renderCommentBox() {
        const { issue } = this.state;
        return (
            <>
                {issue && issue.status.name == "Open" && (
                    <Comment
                        author={this.state.user && this.state.user.name}
                        className={styles.commentBoxContainer}
                        avatar={
                            <Avatar
                                src={this.state.user && this.state.user.avatar}
                                alt={this.state.user && this.state.user.name}
                                size={40}
                            />
                        }
                        content={
                            <div className={styles.commentBox}>
                                <Form
                                    name='comment_box'
                                    className={styles.commentBox}
                                    initialValues={{ agree: false }}
                                    onFinish={this.commentBoxOnSubmit}
                                >
                                    <Form.Item
                                        name='description'
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter a comment",
                                            },
                                        ]}
                                    >
                                        <TextArea name='description' rows={4} />
                                    </Form.Item>
                                    <Form.Item>
                                        <FormButton
                                            label='Add comment'
                                            onClickAction={this.buttonClicked}
                                            formLoading={
                                                this.state.commentLoading
                                            }
                                            className={styles.new_issue_button}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        }
                        datetime={
                            <>
                                <Tag className={styles.memberTag}>
                                    {this.state.user &&
                                        this.state.user.role_name}
                                </Tag>
                            </>
                        }
                    />
                )}
            </>
        );
    }

    render() {
        const { issue, loading } = this.state;
        return (
            <Dashboard loading={loading} completeProfileCheck={true}>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {issue && issue.title} - Pakistan Freelancers
                        Association
                    </title>
                </Helmet>
                <Row>
                    <SectionBody>
                        <Row>
                            {this.renderIssue()}
                            {this.renderComments()}
                            {this.renderCommentBox()}
                            {this.state.loading && <Loader />}
                        </Row>
                    </SectionBody>
                </Row>
            </Dashboard>
        );
    }
}

export default Issue;
