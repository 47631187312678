import React from 'react';
import {Form, Input, Typography} from 'antd';
import styles from '../../styles/signUp.module.css'
import {passwordRule} from '../../helpers/rules';
import pafla from '../../api/pafla'
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router'
import FormButton from './FormButton'
import ReactPixel from 'react-facebook-pixel'


const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
};

class RegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null
        }
    }

    onFinish = (values) => {
        this.setState({formLoading: true})
        pafla.post(`user/register`, values)
        .then((response) => {
            if (!response.data.error) {
                const { state } = this.props.location
                Cookies.set('auth', response.data.data.api_token, { path: '/' })
                Cookies.set('user_details', response.data.data, { path: '/' })
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });
                ReactPixel.track('Lead');

                if (state) {
                    if (state.referrer) {
                        this.props.history.push(state.referrer)
                    } else {
                        this.props.history.push('/dashboard/membership')
                    }
                    
                } else {
                    this.props.history.push('/dashboard/membership')
                }
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }            
            this.setState({formLoading: false})
        })
    }
   
    render() {
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.reg_form}
                initialValues={{agree: false}}
                onFinish={this.onFinish}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your full name',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="name"
                >
                    <Input size="large" placeholder="Full Name"/>
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="email"
                >
                    <Input size="large" placeholder="Email Address" autocomplete="username"/>
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[{required: true, message: 'Please input your contact number'}]}
                >
                    <Input size="large" placeholder="Contact Number" autocomplete="number"/>
                </Form.Item>

                <Form.Item
                    rules={passwordRule}
                    name="password"
                    validateTrigger={"onBlur"}
                >
                    <Input
                        size="large"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Password (min 8 characters)"
                    />
                </Form.Item>
                {/*<Form.Item
                >
                    <Form.Item name="agree"
                               valuePropName="checked"
                               rules={[{
                                   required: true,
                                   message: 'Please agree with terms & conditions!',
                                   transform: value => (value || undefined),
                                   type: 'boolean'
                               }]}
                               noStyle>
                        <Checkbox>I agree to the terms & conditions</Checkbox>
                    </Form.Item>
                </Form.Item>*/}

                <Form.Item>
                    <FormButton 
                        label="Join Now"
                        onClickAction={this.buttonClicked}
                        formLoading={this.state.formLoading}
                    />
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(RegisterForm);
