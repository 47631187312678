import React, { useState, useEffect, useRef } from 'react';
import { Divider, Tag, Button } from 'antd';
import { IMAGE_URL } from '../../helpers/constants';
import styles from '../../styles/member.module.css'

const MemberProfile = ({website, linkedin, profiles}) => {

    const [profileData, setProfileData] = useState(false)
    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;

        if (profileData && (Object.keys(profileData).length > 0)) {
            return;
        }

        const parsed = JSON.parse( profiles );
        setProfileData(parsed)

        return () => mounted.current = false;
    })

    return (
        <div className={styles.memberDetail}>
            <h4>Explore</h4>
            <div className={styles.profileLinks}>
                {website && 
                    <a target="_blank" rel="noreferrer" href={website}>
                        <img alt="linkedln" src={`${IMAGE_URL}profile-icons/website.png`}/>
                    </a>
                }
                {linkedin &&
                    <a target="_blank" rel="noreferrer" href={linkedin}>
                        <img alt="linkedln" src={`${IMAGE_URL}profile-icons/linkedin.png`}/>
                    </a>
                }
                {Object.keys(profileData)?.map((name, i) => {
                    if (name === 'otherplatform') {
                        return (
                            <a target="_blank" rel="noreferrer"  href={profileData['otherplatform']['link']}>
                                <img alt={name} src={`${IMAGE_URL}profile-icons/${name}.png`}/>
                            </a>
                        )
                    } else {
                        return (
                            <a target="_blank" rel="noreferrer"  href={profileData[name]}>
                                <img alt={name} src={`${IMAGE_URL}profile-icons/${name}.png`}/>
                            </a>
                        )
                    }                    
                })}
            </div>
            <div className={styles.getInTouch}>
                <Button type="primary" shape="round" size="medium" block>
                    Get in Touch
                </Button>
            </div>
        </div>
    );
};

export default MemberProfile;
