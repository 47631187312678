import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import styles from '../../styles/dashboard.module.css'
import {
    CHAPTER_CONTRIBUTOR,
    UNIVERSITY_AMBASSADOR,
    HOST_YOUR_MEETUP,
    APPLY_AS_A_SPEAKER
} from '../../helpers/constants'

const ContributeSidebar = (props) => {
	return (
		<div className={styles.filter}>
			<Menu>
			    <Menu.Item>
			        <NavLink to={CHAPTER_CONTRIBUTOR}>Chapter Contributor</NavLink>
			    </Menu.Item>
			    <Menu.Item>
			        <NavLink to={UNIVERSITY_AMBASSADOR}>University Ambassador</NavLink>
			    </Menu.Item>
			    <Menu.Item>
			        <NavLink to={HOST_YOUR_MEETUP}>Meetups</NavLink>
			    </Menu.Item>
			    <Menu.Item>
			        <NavLink to={APPLY_AS_A_SPEAKER}>Speaker/Trainer</NavLink>
			    </Menu.Item>
			</Menu>
		</div>
	);
};

export default ContributeSidebar;
