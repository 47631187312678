import React from 'react';
import ReactPixel from 'react-facebook-pixel'
import Layout from '../components/Layout';
import styles from '../styles/blog.module.css'
import {IMAGE_URL} from '../helpers/constants';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";



class AboutUs extends React.Component {


    componendDidMount() {
        ReactPixel.pageView()
    }

    render() {
        return (
            <Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>About Us - Pakistan Freelancers Association</title>
                </Helmet>

                <Banner
                    title="About PAFLA"
                    text="We're promoting the interests of independent workers through advocacy, education, and services."
                    background={`${IMAGE_URL}home_banner_bg.png`}
                    image={`${IMAGE_URL}home_banner_img.png`}
                    width={[9, 14]}
                />
                <section className='grey'>
                    <div className='single-blog'>
                        <h1 className="section-title">Who We Are</h1>
                        <p>Pakistan Freelancers Association is a support system for all independent workers. Our mission is to promote the interests of independent workers through advocacy, education, and services. We build novel solutions to support independent workers so they can be secure and thrive, no matter how they work.</p>
                        <p><b><u>Objectives drive us:</u></b></p>
                        <ul>
                            <li><b>Advocate for the interests of freelancers:</b> Pakistan Freelancers Association advocates for policy changes that support freelancers’ needs and rights.</li>
                            <li><b>Create community:</b> Bringing the diverse and disparate population of freelancers together—out of isolation and into a community—is at the base of all we do. In community, freelancers can support one another through the exchange of knowledge, networks, and encouragement.</li>
                            <li><b>Provide benefits:</b> Being self-employed leaves many, if not most, freelancers without cost-effective access to financial benefits. We aim to provide freelancers with all necessary benefits including insurances, loans, discounts and legal and financial consultation needed to grow their independent careers.</li>
                            <li><b>Build resources:</b> Starting an independent career or finding the right people working as freelancers can be challenging. We build resources around the existing network of freelancers aiding in more people and organizations becoming a part of this workforce</li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className='single-blog'>
                        <h1 className="section-title">Our Vision</h1>
                        <p>We envision a future in which the freelancers of Pakistan; an independent workforce are treated as an equal partner and contributor to the economy.</p>
                        <ul>
                            <li>Where Pakistan is top-ranking within the freelancing industry globally and contributing majorly to the annual remittances coming to Pakistan.</li>
                            <li>Where Pakistani freelancers are eligible to all the banking and financial services enabled through digital mediums</li>
                            <li>Where location is a part of equal opportunity for IT enabled services and Pakistani companies look forward to hire and work with the independent workforce.</li>
                        </ul>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default AboutUs;
