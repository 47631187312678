import React from 'react'
import { Form, Row, Col, Input, Select, Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from '../../../styles/event.module.css'
import pafla from '../../../api/pafla'
import { notification} from 'antd'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router'
import FormButton from '../FormButton'
import { cities } from '../../FreelanceFest/constants'
import PriceFormatted from '../../PriceFormatted'

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

const { Option } = Select;
const { TextArea } = Input;

const { Text } = Typography;

class LoginForm extends React.Component {
   
    render() {
        const { selectedTicket } = this.props
        const isDiscounted = selectedTicket && (selectedTicket.discounted_price > 0) ? true : false
        return (
            <div className={styles.ticketPreview}>
                <div className={styles.ticketPreviewCol}>
                     <div className={styles.ticketPreviewColInner}>
                         <img src={`${process.env.REACT_APP_STORAGE_URL}/${selectedTicket.event.image}`}/>
                     </div>
                     <div className={styles.ticketPreviewColInner}>
                        {selectedTicket &&
                            <>
                                <div className={styles.ticketPreviewDetail}>                        
                                    <span className={styles.ticketPreviewHead}>Event</span>
                                    <span className={styles.ticketPreviewInfo}>{selectedTicket.event.title}</span>
                                </div>

                                <div className={styles.ticketPreviewDetail}>                        
                                    <span className={styles.ticketPreviewHead}>Selected Package</span>
                                    <span className={styles.ticketPreviewInfo}>{selectedTicket && selectedTicket.package_name}</span>
                                </div>
                                <div className={styles.ticketPreviewDetail}>                    
                                    <span className={styles.ticketPreviewHead}>Ticket Price</span>
                                    <span className={`${styles.ticketPreviewInfo}`}>
                                        {selectedTicket.price &&
                                            <span className={`${styles.regularPrice} ${isDiscounted && styles.stroked}`}><PriceFormatted price={selectedTicket.price}/></span>
                                        }
                                        {(selectedTicket && isDiscounted && selectedTicket.discounted_price ) &&
                                            <span className={`${styles.ticketPreviewInfo} ${styles.eventDiscountedPrice}`}><PriceFormatted price={selectedTicket.discounted_price}/></span>
                                        }
                                    </span>
                                    
                                </div>
                            </>
                        }                 
                     </div>
                </div>
                <div className={styles.ticketPreviewColFull}>
                    <hr/>
                    <div className={styles.ticketPreviewDetail}>                        
                        <span className={styles.ticketPreviewHead}>Full Name</span>
                        <span className={styles.ticketPreviewInfo}>{selectedTicket && selectedTicket.first_name} {selectedTicket && selectedTicket.last_name}</span>
                    </div>
                    <div className={styles.ticketPreviewDetail}>                        
                        <span className={styles.ticketPreviewHead}>Company & Position</span>
                        <span className={styles.ticketPreviewInfo}>{selectedTicket && selectedTicket.position}, {selectedTicket && selectedTicket.company}</span>
                    </div>
                    <div className={styles.ticketPreviewDetail}>                        
                        <span className={styles.ticketPreviewHead}>CNIC</span>
                        <span className={styles.ticketPreviewInfo}>{selectedTicket && selectedTicket.cnic}</span>
                    </div>
                    <div className={styles.ticketPreviewDetail}>                        
                        <span className={styles.ticketPreviewHead}>Full Address</span>
                        <span className={styles.ticketPreviewInfo}>{selectedTicket && selectedTicket.physical_address}, {selectedTicket && selectedTicket.city}, {selectedTicket && selectedTicket.state}, {selectedTicket && selectedTicket.country}</span>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(LoginForm);
