import React from 'react';
import Layout from '../components/Layout';



const Help = () => {
    return (
        <Layout>
            <div>
                <h1>Help</h1>
            </div>
        </Layout>
    );
};

export default Help;
