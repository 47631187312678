import React from 'react';
import { Tooltip } from 'antd';
import styles from '../../styles/dashboard.module.css'
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../helpers/constants';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import PriceFormatted from '../PriceFormatted';

const BlogCard = ({name, memberPrice, memberPriceDiscounted, discountText, description, features, buttonLink, buttonText, active, disabled, validate, type }) => {
    const disable = (active || disabled) ? true : false
    return (
        <div className={styles.package}>
            <div className={styles.scheduleItemRow}>
                <div className={styles.pricingPlan}>
                    <h4 className={styles.packageTitle}>{name}</h4>
                    <div className={styles.pricing}>
                        {(memberPrice > 0 ) ?
                            <div className={memberPriceDiscounted && styles.memberDiscountedStroked} >
                                <sup>PKR</sup>
                                <span className={styles.packagePrice}><PriceFormatted price={memberPrice}/>{type && type}</span>
                            </div>
                            :
                            <span className={styles.packagePrice}>Free</span>
                        }
                        {memberPriceDiscounted &&
                            <>
                                <div className={memberPriceDiscounted && styles.memberDiscounted}>
                                    <sup>PKR</sup>
                                    <span className={styles.packagePrice}><PriceFormatted price={memberPriceDiscounted}/>{type && type}</span>
                                </div>
                                {discountText &&
                                    <div className={styles.discountText}>
                                        {discountText}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <p className={styles.packageDescription}>{description}</p>
                    <Link
                        target="_self" 
                        className={`btn_default ${styles.package_button} ${active ? styles.active : null}`}
                        disabled={disable}
                        onClick={() => validate(name, buttonLink, disable)}
                    >
                        {active ? 'Active' : buttonText}
                    </Link>
                </div>
                <div className={`${styles.features}`}>
                    {features.map(feature => {
                        return (
                            <div className={styles.feature}>
                                <span className={styles.featureTitle}>
                                    {feature.name}
                                    {feature.description &&
                                        <Tooltip placement="bottom" title={feature.description}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    }
                                </span>
                                <span className={styles.featureEnabled}><CheckOutlined /></span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
