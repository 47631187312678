import React, { useEffect, useState } from 'react';
import { Input, Radio, Space } from 'antd';
import styles from '../../styles/dashboard.module.css'
import pafla from '../../api/pafla'

const MemberSignup = (props) => {

	const [hourlyRate, setHourlyRate] = useState(['All Categories'])
	const [loading, setLoading] = useState(true)

	const onChange = async (e: CheckboxChangeEvent) => {
		setHourlyRate(e.target.value)
	    props.setPeersbyHourlyRate(e.target.checked, e.target.value)       
	};

	return (
		<div className={styles.filter}>
			<h3>Hourly Rate</h3>
			<Radio.Group onChange={onChange} value={hourlyRate}>
				<Space direction="vertical">
					<Radio value="" defaultChecked="true">Any Hourly rate</Radio>
					<Radio value="1,500">Less than Rs 500</Radio>
					<Radio value="500,1000">Rs 500 to Rs 1000</Radio>
					<Radio value="1000,3000">Rs 1000 to Rs 3000</Radio>
					<Radio value="3000,100000">Rs 3000 and above</Radio>
				</Space>
			</Radio.Group>
		</div>
	);
};

export default MemberSignup;
