import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookies from 'js-cookie';

class RestrictRoute extends React.Component {

	SignedIn() {
	    const authCookie = Cookies.get('auth');
	    if (authCookie) {
	        return true
	    }
	    return false

	}

	render() {
		const { register = false } = this.props
	    if (this.SignedIn()) {
	    	return <Route {...this.props} />
	    }

	    if (register) {
	    	return (
	    		<Redirect 
	    			to={{
	    				pathname: "/register",
	    				state: { referrer: window.location.pathname }
	    			}}
	    			{...this.props} 
	    		/>
	    	)
	    }

	    return (
	    	<Redirect 
	    		to={{
	    			pathname: "/login",
	    			state: { referrer: window.location.pathname }
	    		}}
	    		{...this.props} 
	    	/>
	    )
	}
}

export default RestrictRoute
