import React from "react";
import styles from "../styles/signUp.module.css";
import { IMAGE_URL } from "../helpers/constants";
import { Button, Typography, notification } from "antd";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
import pafla from "../api/pafla";

const { Title, Text } = Typography;
const MemberSignup = (props) => {
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const params = queryString.parse(location.search);

    useEffect(() => {
        if (params.id) {
            setId(params.id);
        }
    }, [params.id]);

    const onClickAction = () => {
        console.log(id);
        setLoading(true);
        pafla.post(`user/unsubscribe`, { id: id }).then((response) => {
            if (!response.data.error) {
                setLoading(false);
                //this.props.history.push('/profile')
                notification.success({
                    message: "Success",
                    description: response.data.message,
                });
            } else {
                setLoading(false);
                notification.error({
                    message: "Error",
                    description: response.data.message,
                });
            }
            this.setState({ formLoading: false });
        });
    };

    return (
        <Layout>
            <div className={styles.card_items}>
                <div className='single-blog'>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>PAFLA - Pakistan Freelancers Association</title>
                    </Helmet>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={`${IMAGE_URL}unsubscribe.jpg`}
                            alt=''
                            style={{ maxWidth: "300px", margin: "auto", marginBottom: "20px" }}
                        />
                        <h3 style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "20px" }}>
                            We're sorry to see you go!
                        </h3>
                        <p style={{ marginBottom: "20px" }}>
                            By unsubscribing from the Pakistan Freelancers
                            Association, you'll miss out on professional
                            networking, skill development opportunities, job
                            alerts, industry insights, and community support.{" "}
                        </p>
                        <p style={{ marginBottom: "40px" }}>
                            If you're sure about unsubscribing, click the
                            "Unsubscribe" button. Thank you for being a part of
                            our community!
                        </p>
                        {loading && (
                            <div
                                className={`${styles.btn_primary_large} form-button ant-button ant-btn-primary`}
                            >
                                {loading && (
                                    <Spin style={{ marginTop: "-10px" }} />
                                )}
                            </div>
                        )}
                        {!loading && (
                            <Button
                                type='primary'
                                onClick={onClickAction}
                                className={styles.btn_primary_large}
                            >
                                Unsubscribe Now
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MemberSignup;
