import React, { useState } from 'react';
import { Tooltip } from 'antd';
import styles from '../styles/membershipjoin.module.css';
import { Link } from 'react-router-dom';
import { SIGN_UP } from '../helpers/constants';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import PriceFormatted from './PriceFormatted';

const BlogCard = ({ imageSrc, name, description, features, button1Link, button1Text, button2Link, button2Text }) => {
	const [more, setMore] = useState(false);

	const toggleViewMore = () => {
		setMore(!more);
	};

	return (
		<div className={`${styles.package} ${!more && styles.packageActive}`}>
			<div className={styles.scheduleItemRow}>
				<div className={styles.pricingPlan}>
					<div className={styles.packageImage}>
						<img src={imageSrc} title={name} />
					</div>
					<h4 className={styles.packageTitle}>{name}</h4>
					<p className={styles.packageDescription}>{description}</p>
					<span onClick={toggleViewMore} className={styles.learnMore}>
						{!more ? 'Learn more' : 'View less'}
					</span>
				</div>
				{more && (
					<>
						<h3>What you'll learn</h3>
						<div className={`${styles.features}`}>
							{features.map((feature) => {
								return (
									<div className={styles.feature}>
										<span className={styles.featureTitle}>
											{feature.name}
											{feature.description && (
												<Tooltip placement="bottom" title={feature.description}>
													<QuestionCircleOutlined />
												</Tooltip>
											)}
										</span>
										<span className={styles.featureEnabled}>
											<CheckBadgeIcon />
										</span>
									</div>
								);
							})}
						</div>
						<div className={styles.ctas}>
							<Link target="_self" className={`btn_default ${styles.package_button}`} to={button2Link}>
								{button2Text}
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default BlogCard;
