import React from 'react';
import {Card, Typography} from 'antd';
import styles from '../styles/card.module.css'
import {Link} from 'react-router-dom';
import { CalendarOutlined, PushpinOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { Markup } from 'interweave';

const { Paragraph } = Typography;

const Event = ({image,title,text,link, date, classes, bodyClasses, venue}) => {
    return (
        <Card
            className={`${styles.blog_card} ${classes}`}
            bordered={false}
            cover={<img alt={title} src={image} />}
        >
            <div className={bodyClasses}>
                <h3 title={title}>{title}</h3>
                <div className={styles.detail}>
                    <p className={styles.date}>
                        <span className={styles.dateIcon}><CalendarOutlined /></span>
                        {date}
                    </p>
                </div>
                <Paragraph ellipsis={{ rows: 3 }}><Markup content={text}/></Paragraph> 
                <div className={styles.detail}>
                    <p className={styles.ticket}>
                        <span className={styles.dateIcon}><MoneyCollectOutlined /></span>
                        Free
                    </p>                    
                    <p className={styles.location}>
                        <span className={styles.dateIcon}><PushpinOutlined /></span>
                        {venue}                    
                    </p>
                </div>
                <Link to={link} className={styles.event_link}>View Details</Link>
            </div>
        </Card>
    );
};

export default Event;
