import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import RegisterButton from './RegisterButton'
import Bookmark from './Bookmark'

const {Title, Paragraph} = Typography;

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.venue}`}>
            <Row className='contained-row' justify="space-between">
                <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                    <Row>
                        <Col 
                            className={styles.desktopHide} 
                            xs={{span: 24, offset: 0}} 
                            md={{span: 12, offset: 1}} 
                            lg={{span: 11, offset: 2}}
                        >
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/gilgit.jpg`} alt=""/>
                           </div>
                        </Col>
                        <Col 
                            xs={{span: 24}}
                            md={{span: 10}}
                        >
                            <div className={styles.about_text}>
                                <div className={styles.card_item}>
                                    <Slide bottom>
                                        <h1>Venue</h1>
                                    </Slide>

                                    <Bookmark/>
                                    <Slide bottom>
                                            <Paragraph className="section-text">
                                                Gilgit-Baltistan (GB) is a very sparsely populated high-mountain area in the north of Pakistan. Its natural environment is usually described with superlatives – the longest glaciers outside of the polar region, home of the world’s second highest peak (K2) and four more eight-thousanders. GB is largely a high-mountain desert; geologically, it spreads over three high mountain systems: Himalaya, Karakorum and Hindukush. 
                                                    <Divider style={{visibility: 'hidden', margin: 15}}/>
                                                    The society of GB is diverse in terms of language, religion and ethnicity.
                                            </Paragraph>
                                    </Slide>
                                    {/*<Fade right>
                                        <RegisterButton/>
                                    </Fade>*/}
                                </div>
                            </div>
                        </Col>
                        <Col 
                            className={styles.desktopShow} 
                            xs={{span: 24, offset: 0}} 
                            md={{span: 12, offset: 1}} 
                            lg={{span: 12, offset: 2}}
                        >                           
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/gilgit.jpg`} alt=""/>
                           </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default HomeAbout;
