import React, { useEffect, useState } from 'react';
import { Row } from 'antd'
import Dashboard from '../../components/dashboard/Main'
import { RiseOutlined } from '@ant-design/icons';
import SectionBody from '../../components/dashboard/SectionBody';

const MemberSignup = (props) => {

    return (
        <Dashboard
            loading={false}
            completeProfileCheck={true}
        >
            <Row>
                <SectionBody>
                    <div style={{width:"100%",textAlign: "center","padding": "150px"}}>
                        <h2 style={{width:"100%",textAlign: "center",fontSize: "30px"}}><RiseOutlined style={{ fontSize: '30px', marginRight: '10px' }}/>Coming Soon</h2>
                        <p>Keep a close eye, this is in the works and will be released soon! You will also be notified of further details via email.</p>
                        <br/>
                    </div>                 
                </SectionBody>
            </Row> 
        </Dashboard>
    );
};

export default MemberSignup;
