import React, { useState, useEffect, useRef } from 'react';
import {
    PushpinOutlined
} from '@ant-design/icons';

import { Avatar, Divider } from 'antd';
import styles from '../styles/member.module.css'
import Hourlyrate from './Hourlyrate'

const MemberProfile = (props) => {

    var formatter = new Intl.NumberFormat('en-PK', {
      style: 'currency',
      currency: 'PKR',
      maximumFractionDigits: 0
    });


    return formatter.format(props.rate) + '/hr'
};

export default MemberProfile;
