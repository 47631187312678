import React from 'react';
import {Card} from 'antd';
import styles from '../styles/freelance-fest/general.module.css'


const { Meta } = Card;

const BlogCard = ({image, name, type = null, priority}) => {
    return (
        <div className={`${styles.sponsor}`}>
            <div className={styles.sponsor_inner}>
                <div className={styles.coverr}>
                    <img src={`${image}`} alt={name}/>
                </div>
                <div className={styles.details}>
                    <div className={styles.details_inner}>
                        <div className={styles.title_holder}>                       
                            <div className={styles.sponsor_position}>{name}</div>
                            {type && <p>{type}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
