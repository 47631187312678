import React from 'react';
import ReactPixel from 'react-facebook-pixel'
import Layout from '../components/Layout';
import {Zoom} from 'react-reveal';
import pafla from '../api/pafla'
import MediaMentionCard from '../components/MediaMentionCard';
import {Col, Row} from 'antd';
import styles from '../styles/card.module.css'
import {IMAGE_URL} from '../helpers/constants';
import Banner from '../components/Banner';
import { Helmet } from "react-helmet"

class Blogs extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        pressreleases: null,
	        imageURL: null
	    }
	}

	async componentDidMount() {
		
        await pafla.get('/mediamentions')
        .then((response) => {
            this.setState({mediaMentions: response.data})
        })
    }


    render() {
        return (
        	<Layout>
        		<Helmet>
        		    <meta charSet="utf-8" />
        		    <title>Media Mentions - Pakistan Freelancers Association</title>
        		</Helmet>
        		<Banner
        		    title="Media Mentions"
        		    background={`${IMAGE_URL}home_banner_bg.png`}
        		    image={`${IMAGE_URL}home_banner_img.png`}
        		    width={[9, 14]}
        		/>
	            <section id="pressrelease">
	                <Row justify="space-between">
	                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
	                        <Zoom left>
	                            <div className={styles.media_card_items}>
	                                {this.state.mediaMentions && 
                                        Object.entries(this.state.mediaMentions).map(([key, mm], i) => {
                                            return (
                                                <MediaMentionCard
                                                    key={mm.id}
                                                    image={`${process.env.REACT_APP_STORAGE_URL}${mm.logo}`}
                                                    title={mm.title}
                                                    text={`${mm.excerpt.substring(0, 100)}...`}
                                                    link={mm.link}
                                                />
                                            )
                                        })
                                    }

	                            </div>
	                        </Zoom>
	                    </Col>
	                </Row>
	            </section>
            </Layout>
        );
    }
}

export default Blogs;
        