import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd';
import styles from '../../styles/home.module.css'
import pafla from '../../api/pafla'
import { Typography } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

const { Title, Paragraph } = Typography;

const InThePress = ({sectionId}) => {

	const [mentions, setMentions] = useState(null)

	useEffect(() => {
		if (!mentions) {
			getMentions()
		}
	});

	const swiperParams = {
		spaceBetween: 50,
		speed: 800,
		loop: true,
		autoplay: {
			delay: 100,
			pauseOnMouseEnter: true,
			disableOnInteraction: false
		},
	    breakpoints: {
	        320: {
	          slidesPerView: 3,
	          spaceBetween: 20,
	        },
	        768: {
	          slidesPerView: 4,
	          spaceBetween: 30,
	        },
	        1024: {
	          slidesPerView: 6,	          
	        },
	    }
	}

	const getMentions = async () => {

		await pafla.get('/mediamentions-order')
			.then((response) => {
				setMentions(response.data)
			})
	}

	return (
		<section className={styles.featured} id="featured">
		    <Row>
		        <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
		            <Row justify="space-between" gutter={20}>
		                <Col xs={{span: 24}} md={{span: 24}} xl={{span: 3}}  className={styles.statistics_heading}>
		                    <Title level={2} className={styles.featuredTitle}>Featured In</Title>
		                </Col>
		                <Col xs={{span: 24}} md={{span: 24}} xl={{span: 21}}  className={styles.statistics_heading}>
		                    <div className={styles.featuredswiperContainer}>
		                    	<Swiper 
		                    		className={styles.swiperSlider} 
		                    		{...swiperParams} 
		                    		modules={[Autoplay, Navigation]}
		                    	>
		                    		{mentions && Object.entries(mentions).map(([key, mention], i) => {
		                    			return(                
		                    				<SwiperSlide className={styles.featuredLogo}>
		                    					<a href={mention.link} target="_blank">
		                    						<img src={`${process.env.REACT_APP_STORAGE_URL}${mention.logo}`}/>
		                    					</a>
		                    				</SwiperSlide>
		                    			)
		                    		  })}
		                    	</Swiper>
		                    </div>
		                </Col>
		            </Row>
		        </Col>
		    </Row>
		</section>
	);
};

export default InThePress;
