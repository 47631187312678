import React from 'react';
import {Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';
import styles from '../../styles/signUp.module.css'
import pafla from '../../api/pafla'
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router'
import FormButton from './FormButton'


const layout = {
    wrapperCol: {
        lg: {span: 24, offset: 0},
        xs: {span: 24, offset: 0},
        md: {span: 24, offset: 0},
        sm: {span: 24, offset: 0}
    },
};

const {Text} = Typography;

class RegisterForm extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null
        }
    }

    componentDidMount() {
        const { data } = this.props

        this.formRef.current.setFieldsValue({
            name: data.name,
            email: data.email,
            phone: data.phone
        })
    }

    onFinish = (values) => {
        this.setState({formLoading: true})
        pafla.post(`user/update`, values)
        .then((response) => {
            if (!response.data.error) {
                Cookies.set('auth', response.data.data.api_token, { path: '/' })
                this.props.history.push('/profile')
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }            
            this.setState({formLoading: false})
        })
    }
   
    render() {
        const { data } = this.props
        return (
            <Form
                {...layout}
                ref={this.formRef}
                name="normal_login"
                className={styles.reg_form}
                initialValues={{agree: false}}
                onFinish={this.onFinish}
                layout='vertical'
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your full name',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="name"
                    label='Full Name'
                >
                    <Input value={data.name} size="large" placeholder="Full Name"/>
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                    ]}
                    validateTrigger={"onBlur"}
                    name="email"
                    label='Email Address'
                >
                    <Input size="large" placeholder="Email Address" value={data.email}/>
                </Form.Item>
                <Form.Item

                    name="phone"
                    rules={[{required: true, message: 'Please input your contact number'}]}
                    label='Contact Number'
                >
                    <Input size="large" placeholder="Contact Number" value={data.phone}/>
                </Form.Item>

                <Form.Item>
                    <FormButton 
                        label="Update"
                        onClickAction={this.buttonClicked}
                        formLoading={this.state.formLoading}
                    />
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(RegisterForm);
