import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';
import QuizForm from '../../components/dashboard/QuizForm';
import QuizResult from './QuizResult';
import pafla from '../../api/pafla';
import { withRouter } from 'react-router-dom';
class StartQuiz extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			course: null,
			loading: true,
			eligible: false,
			message: null,
			attempt: null,
		};
	}
	async componentDidMount() {
		this.checkQuizEligibility();
	}

	checkQuizEligibility = async () => {
		const { courseId } = this.props.match.params;
		await pafla.get(`/courses/${courseId}/check-quiz-eligibility`).then((response) => {
			this.setState({ loading: false });
			if (response.data.data.eligible) {
				this.setState({ eligible: true });
			} else {
				this.setState({ eligible: false });
				this.setState({ message: response.data.message });
			}
		});
	};

	handleAttempt = (attempt) => {
		this.setState({ quizAttempt: attempt });
	};

	render() {
		return (
			<Dashboard loading={this.state.loading} completeProfileCheck={false}>
				<Row>
					<SectionBody width="24">
						<>
							{this.state.eligible && !this.state.quizAttempt && <QuizForm onQuizAttempt={this.handleAttempt} />}
							{!this.state.eligible && !this.state.loading && !this.state.quizAttempt && (
								<h2>We're sorry you're not eligible for this Quiz. {this.state.message}</h2>
							)}
							{this.state.eligible && !this.state.loading && this.state.quizAttempt && <QuizResult result={this.state.quizAttempt} />}
						</>
					</SectionBody>
				</Row>
			</Dashboard>
		);
	}
}

export default withRouter(StartQuiz);
