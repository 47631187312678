import React, { useState, useEffect, useRef } from 'react';
import {
    PushpinOutlined
} from '@ant-design/icons';

import { Avatar, Divider } from 'antd';
import styles from '../../styles/member.module.css'
import Hourlyrate from '../Hourlyrate'

const MemberHeader = (props) => {

    return (
        <>
            <div className={styles.memberProfileHeader}>
                <div className={styles.avatar}>
                    <Avatar
                        src={props.avatar}
                        size={{ xs: 100, md: 40, lg: 64, xl: 100, xxl: 100 }}
                    />
                </div>
                <div className={styles.detail}>
                    <h1>{props.name}</h1>
                    <h2>{props.title}</h2>
                    <p><PushpinOutlined /> {props.city}, {props.country}</p>
                </div>

                <div className={styles.hourly_rate}>
                    <h2><Hourlyrate rate={props.rate}/></h2>
                </div>
            </div>
            <Divider className={styles.mobileDivdier}/>
        </>
    );
};

export default MemberHeader;
