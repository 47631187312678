import React from 'react';
import {Link} from 'react-router-dom';
import { Menu } from 'antd';
import styles from '../../styles/freelance-fest/general.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'



const { SubMenu } = Menu;

class ProfileMenu extends React.Component {
  state = {
	current: 'my-account',
  };

  handleClick = e => {
	this.setState({ current: e.key });
  };

  render() {
	const { current } = this.state;
	return (
		<div className={styles.profileMenuContainer}>
			<Menu className={styles.profileMenu} onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
				<SubMenu 
					className={styles.profileMenuInner} 
					key="my-account" 
					title="My Account" 
					icon={<FontAwesomeIcon icon={faUser} className={styles.profileMenuIcon} />}
				>
						
						<Menu.Item key="setting:2">
							<Link to='/profile'>Profile</Link>
						</Menu.Item>
					<Menu.Item key="setting:1">
						<Link to='/issues'>My Issues</Link>
					</Menu.Item>
					<Menu.Item key="setting:1">
						<Link to='/orders'>My Orders</Link>
					</Menu.Item>
					<Menu.Item key="setting:3">
						<Link to='/logout'>Logout</Link>
					</Menu.Item>
				</SubMenu>
			</Menu>
		</div>
	);
  }
}


export default ProfileMenu;
