import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import RegisterButton from './RegisterButton'
import Bookmark from './Bookmark'

const {Title, Paragraph} = Typography;

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.venue}`}>
            <Row className='contained-row' justify="space-between">
                <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                    <Row>
                        <Col 
                            className={styles.desktopHide} 
                            xs={{span: 24, offset: 0}} 
                            md={{span: 12, offset: 1}} 
                            lg={{span: 11, offset: 2}}
                        >
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/venue.webp`} alt=""/>
                           </div>
                        </Col>
                        <Col 
                            xs={{span: 24}}
                            md={{span: 10}}
                        >
                            <div className={styles.about_text}>
                                <div className={styles.card_item}>
                                    <Slide bottom>
                                        <h1>Venue</h1>
                                    </Slide>

                                    <Bookmark/>
                                    <Slide bottom>
                                            <Paragraph className="section-text">
                                                The Pearl Continental Hotel Karachi embodies extraordinary hospitality with modern-day sophistication. Overlooking the sprawling city, Pearl-Continental Hotel Karachi is a part of the most significant chain of hotels in Pakistan. You’ll find yourself in the heart of one of the city's most vibrant neighbourhoods with the most exciting galleries, attractions, events, restaurants, and shopping.
                                        
                                                    <Divider style={{visibility: 'hidden', margin: 15}}/>
                                                The venue is well connected with public transport and accessible to everyone, no matter where you plan to stay. It has its own valet parking. You could find more information about the venue at their website linked below 
                                            </Paragraph>
                                    </Slide>
                                    {/*<Fade right>
                                        <RegisterButton/>
                                    </Fade>*/}
                                </div>
                            </div>
                        </Col>
                        <Col 
                            className={styles.desktopShow} 
                            xs={{span: 24, offset: 0}} 
                            md={{span: 12, offset: 1}} 
                            lg={{span: 11, offset: 2}}
                        >                           
                           <div className={styles.cart_items}>
                              <img src={`${IMAGE_URL}freelance-fest/venue.webp`} alt=""/>
                           </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default HomeAbout;
