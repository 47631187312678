import React from 'react';
import { Col, Row, Image, Button, Tabs, Tag } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import EventSpeaker from '../../components/EventSpeaker';
import pafla from '../../api/pafla'
import ReactHtmlParser from 'react-html-parser'
import styles from '../../styles/event.module.css'
import { Helmet } from "react-helmet"
import DiscountClaimButton from '../../components/DiscountClaimButton'
import { CalendarOutlined, PushpinOutlined, ApartmentOutlined, MoneyCollectOutlined, GlobalOutlined, SendOutlined } from '@ant-design/icons';

class Discount extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			event: null,
			loading: true,
			ticketURL: null
		}
	}

	async componentDidMount() {
		const { promotionId } = this.props.match.params

		await pafla.get(`/discount/${promotionId}`)
			.then((response) => {
				this.setState({ loading: false })
				this.setState({ discount: response.data.data })
			})
	}

	render() {
		const { discount, loading } = this.state
		return (
			<Dashboard loading={loading}>
				<div className={styles.card_items}>
					{discount &&
						<div className='contained-row event'>
							<Helmet>
								<meta charSet="utf-8" />
								<title>{discount.company_name} - Pakistan Freelancers Association</title>
							</Helmet>
							<Row gutter={{ xs: 0, md: 24 }}>
								<Col xs={{ span: 24 }} md={{ span: 16 }}>
									<div className={styles.eventData}>
										{discount.company_logo &&
											<img className={styles.discountImage}
												src={`${process.env.REACT_APP_STORAGE_URL}/${discount.company_logo}`}
												alt={discount.title.rendered}
											/>
										}
										<div className={styles.eventContent}>
											<h2>{discount.company_name} - {discount.title}</h2>
											{ReactHtmlParser(discount.description)}
										</div>
									</div>
								</Col>
								<Col xs={{ span: 24 }} md={{ span: 8 }}>
									<div className={styles.eventSidebar}>
										<div className={styles.sectionHeader}>
											Promotion Details
										</div>
										{discount.organizer &&
											<div className={styles.eventDetail}>
												<div className={styles.icon}>
													<ApartmentOutlined />
												</div>
												<div className={styles.data}>
													<div className={styles.header}>
														Organizer
													</div>
													<div className={styles.detail}>
														{`${discount.organizer}`}
													</div>
												</div>
											</div>
										}
										{discount.free &&
											<div className={styles.eventDetail}>
												<div className={styles.icon}>
													<MoneyCollectOutlined />
												</div>
												<div className={styles.data}>
													<div className={styles.header}>
														Price
													</div>
													<div className={styles.detail}>
														Free
													</div>
												</div>
											</div>
										}
										<div className={styles.eventDetail}>
											<DiscountClaimButton
												discount={discount}
												discountLoading={loading}
											/>
										</div>
									</div>
									{discount.terms &&
										<div className={styles.eventSidebar}>
											<div className={styles.sectionHeader}>
												Terms & Conditions
											</div>
											<div className={styles.eventDetail}>
												{ReactHtmlParser(discount.terms)}
											</div>
										</div>
									}
								</Col>
							</Row>
						</div>
					}
				</div>
			</Dashboard>
		);
	}
}

export default Discount;
