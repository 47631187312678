import { IMAGE_URL } from './constants';

export const faqs = [
	{
		id: 1,
		question: 'How can I qualify for the Soft Skills Program?',
		answer:
			'The Soft Skills Program is open to everyone, regardless of prior qualifications. You can enrol in the program from this website and you will be onboarded on the batch automatically. ',
	},
	{
		id: 2,
		question: 'Is there a limit to the number of enrollments awarded through this program?',
		answer: 'No, there is no limit to the number of enrollments available.',
	},
	{
		id: 3,
		question: 'Do I need a college degree to apply for this program?',
		answer: 'No, you do not need any prior degree to enroll in the Soft Skills Program.',
	},
	{
		id: 4,
		question: 'As an independent freelancer, how can I enroll in the Soft Skills Program?',
		answer: 'To apply for the Soft Skills Program, first become a PAFLA member, then fill out the enrollment form.',
	},
	{
		id: 6,
		question: 'What prerequisites are required to start the Soft Skills Program?',
		answer: 'None. The program is designed to help individuals at any skill level, whether new to the field or with some experience.',
	},
	{
		id: 7,
		question: 'Is the Soft Skills Program conducted entirely online?',
		answer:
			"Yes, you can attend the Soft Skills Program remotely from anywhere and at any time in Pakistan; there's no need to attend in-person classes.",
	},
	{
		id: 8,
		question: 'Where can I find the topics covered in the Soft Skills Program?',
		answer: 'The Soft Skills Program topics are available in English on YouTube.',
	},
	{
		id: 9,
		question: 'What additional support is available after completing the Soft Skills Program?',
		answer: 'You can always reach out to us through our social channels or email us at support@pafla.org.pk for further assistance.',
	},
];

export const checklist = [
	{
		name: 'Join PAFLA and ensure your profile is complete.',
	},
	{
		name: 'Complete the Enrollment form to register for the SoftSkills Program.',
	},
	{
		name: 'Once you are onboard, you will receive a new video each week for four weeks.',
	},
	{
		name: 'Explore the topics <b>here</b>.',
		link: '#softskills-topics',
	},
	{
		name: 'Finish all 4 videos and then complete the Quiz',
	},
	{
		name: 'Upon passing the quiz, gain access to your Soft Skills Completition Certificate powered by Google.',
	},
];

export const topics = [
	{
		imageSrc: `${IMAGE_URL}/google-softskills/Irene.jpg`,
		name: 'Professional Communication & Networking',
		description:
			"In this topic, we'll cover a variety of essential areas. Firstly, we'll delve into understanding different communication frameworks and forms, focusing on how they contribute to maintaining effective communication. Then, we'll move on to applying these frameworks practically across various communication channels. Additionally, we'll gain insight into various social styles, as outlined by Tracom, and learn how to leverage them effectively. By the end of this segment, you'll possess the skills necessary to communicate confidently and build valuable professional connections.",
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/William.jpg`,
		name: 'Personal Branding & Interview Communication',
		description:
			"In this topic, we'll tackle various key areas. Firstly, we'll focus on crafting a standout resume, learning how to make it shine and effectively highlight your strengths and accomplishments. Next, we'll delve into the art of standing out during networking opportunities, equipping you with the tools to leave a lasting impression and forge valuable connections. Lastly, we'll discuss strategies for setting yourself up for promotions, exploring how to position yourself within your organization for career growth and advancement. By the end of this topic, you'll be empowered with the skills and knowledge to effectively communicate your personal brand and excel in interviews and career progression opportunities.",
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/Brigetta.jpg`,
		name: 'Critical Thinking & Problem Solving',
		description:
			"In this topic, we'll cover several crucial aspects. Firstly, we'll emphasize the significance of critical thinking as the foundation for effective problem-solving, highlighting its role in analyzing situations and making informed decisions. Next, we'll explore various tools and frameworks designed to help define problem statements and uncover root causes, enabling you to approach issues with a structured and systematic mindset. Lastly, we'll focus on evaluating potential solutions and implementing the most promising ones, equipping you with the skills to navigate complex problems with confidence and precision. By the end of this segment, you'll have honed your critical thinking abilities and problem-solving skills, empowering you to overcome challenges and achieve your goals effectively.",
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/Valeria.jpg`,
		name: 'Time Management',
		description:
			"In this topic, we'll cover several important areas. Firstly, we'll delve into the significance of time management, emphasizing its role in maximizing productivity and achieving goals. Next, we'll examine the five principles of effective time management, providing you with a comprehensive framework for organizing your tasks and priorities. Additionally, we'll introduce various tools and techniques designed to enhance self-management skills, empowering you to make the most of your time and resources. By the end of this segment, you'll have a deeper understanding of time management principles and practical strategies to better manage your time and accomplish your objectives.",
	},
];

export const partners = [
	{
		imageSrc: `${IMAGE_URL}/google-softskills/enablers.png`,
		name: 'Enablers Group',
		type: 'Enablement Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/empowerers.png`,
		name: 'Empowerers',
		type: 'Enablement Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/naheed.png`,
		name: 'Naheed.pk',
		type: 'Enablement Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/FAST.png`,
		name: 'FAST NUCES',
		type: 'Outreach Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/empower.png`,
		name: 'Empower',
		type: 'Outreach Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/techub.png`,
		name: 'Techub',
		type: 'Outreach Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/covalent.png`,
		name: 'Covalent',
		type: 'Outreach Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/truckker.png`,
		name: 'Truckker',
		type: 'Outreach Partner',
	},
	{
		imageSrc: `${IMAGE_URL}/google-softskills/Womenza.png`,
		name: 'Womenza',
		type: 'Outreach Partner',
	},
];
