import React from 'react';
import {Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';
import styles from '../../styles/signUp.module.css'
import {passwordRule} from '../../helpers/rules';
import pafla from '../../api/pafla'
import { notification } from 'antd';
import { withRouter } from 'react-router'
import queryString from 'query-string'
import FormButton from './FormButton'



const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
};

const {Text} = Typography;

class RegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null
        }
    }

    onFinish = (values) => {
        this.setState({formLoading: true})
        const token = queryString.parse(this.props.location.search).token
        values.reset_token = token
        pafla.post(`user/resetpassword`, values)
        .then((response) => {
            if (!response.data.error) {
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });
                this.props.history.push('/login')
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }            
            this.setState({formLoading: false})
        })
    }
   
    render() {
        const token = queryString.parse(this.props.location.search).token
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.reg_form}
                initialValues={{agree: false}}
                onFinish={this.onFinish}
                layout='vertical'
            >

                <Form.Item
                    rules={passwordRule}
                    name="password"
                    validateTrigger={"onBlur"}
                >
                    <Input
                        size="large"
                        type="password"
                        placeholder="New Password (min 8 characters)"
                    />
                </Form.Item>

                <Form.Item
                    rules={passwordRule}
                    name="password_confirmation"
                    validateTrigger={"onBlur"}
                >
                    <Input
                        size="large"
                        type="password"
                        placeholder="Confirm New Password (min 8 characters)"
                    />
                </Form.Item>
                <Input
                    type="hidden"
                    name="reset_token"
                    value={token}
                />

                <Form.Item>
                    <FormButton 
                        label="Update Password"
                        onClickAction={this.buttonClicked}
                        formLoading={this.state.formLoading}
                    />
                </Form.Item>
            </Form>
        )
    }
}


export default withRouter(RegisterForm);
