import React from 'react';
import Layout from '../components/Layout';
import pafla from '../api/pafla'
import ReactHtmlParser from 'react-html-parser'
import styles from '../styles/blog.module.css'
import { Helmet } from "react-helmet"


class Blogs extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            blog: null
        }
    }

    async componentDidMount() {     
        const { prId } = this.props.match.params
        await pafla.get(`/pressreleases/${prId}`)
        .then((response) => {
            this.setState({pr: response.data})
        })
    }


    render() {
        return (
            <Layout>
                <div className={styles.card_items}>
                    {this.state.pr && 
                        this.state.pr.map(pr => {
                            const d = new Date(pr.created_at); 
                            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                            const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                            const pdfLink = pr.pdf ? JSON.parse(pr.pdf)[0].download_link : null
                            return (
                                <div className='single-blog'>
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title>{pr.title} - Pakistan Freelancers Association</title>
                                    </Helmet>
                                    <h6>Date Published: {`${da} ${mo} ${ye}`}</h6> 
                                    {/*{pdfLink &&
                                        <h6><a href={`https://api.pafla.org/storage/${pdfLink}`} target='_blank'>Download PDF</a></h6>
                                    }*/}
                                    <h1>{pr.title}</h1>
                                    {ReactHtmlParser(pr.content)}
                                    {/*{pdfLink &&
                                        <h6><a href={`https://api.pafla.org/storage/${pdfLink}`} target='_blank'>Download PDF</a></h6>
                                    }*/}
                                </div>
                            )
                        })
                    }

                </div>
            </Layout>
        );
    }
}

export default Blogs;
        