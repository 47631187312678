import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import Layout from '../components/Layout';
import { Zoom } from 'react-reveal';
import pafla from '../api/pafla';
import PrCard from '../components/PrCard';
import { Col, Row } from 'antd';
import styles from '../styles/blog.module.css';
import { Helmet } from 'react-helmet';

class Blogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pressreleases: null,
			imageURL: null,
		};
	}

	async componentDidMount() {
		await pafla.get('/pressreleases').then((response) => {
			this.setState({ pressreleases: response.data });
		});
	}

	render() {
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Press Releases - Pakistan Freelancers Association</title>
				</Helmet>
				<section id="pressrelease">
					<Row justify="space-between">
						<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
							<Zoom left>
								<div className={styles.card_items}>
									{this.state.pressreleases &&
										Object.entries(this.state.pressreleases).map(([key, pr], i) => {
											return <PrCard key={pr.id} release={pr} />;
										})}
								</div>
							</Zoom>
						</Col>
					</Row>
				</section>
			</Layout>
		);
	}
}

export default Blogs;
