import React from 'react';
import {Card} from 'antd';
import styles from '../styles/card.module.css'
import {Link} from 'react-router-dom';


const {Meta} = Card;

const BlogCard = ({image,title,text,link}) => {
    return (
        <Card
            className={styles.mediaMention}
            bordered={false}
            cover={<img alt={title} src={image} className={styles.mediaMentionImage} />}
        >
            <Meta
                title={title}
                description={text}
            />
            <a href={link} className={styles.blog_link} target="_blank">Read Full Article</a>
        </Card>
    );
};

export default BlogCard;
