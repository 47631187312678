import React from 'react';

const Community = (props) => {
    var color = props.type === 'light' ? '#fff' : '#000';

    const style = {
        maxWidth: '70%'
    }

    const headingStyle = {
        color: color
    }

    return (
        <div style={style}>
            <h1 style={headingStyle}>{props.title}</h1>
            <h2 style={headingStyle}>{props.subtitle}</h2>
        </div>
    );
};

export default Community;
