import React, { useState, useEffect, useRef } from 'react';
import { Divider, Tag } from 'antd';
import styles from '../../styles/member.module.css'

const MemberProfile = (props) => {

    return (
        <div className={styles.memberDetail}>
            <h4>Level of Expertise</h4>
            <p>{props.level}</p>
        </div>
    );
};

export default MemberProfile;
