import React from 'react';
import styles from '../../styles/signUp.module.css';
import {Col, List, Row, Typography} from 'antd';
import {IMAGE_URL} from '../../helpers/constants';
import { isMobile } from 'react-device-detect'

const list = [
    /*{title: 'Insurance plans hand-picked for freelancers'},*/
    {title: 'Community networking and training events'},
    {title: 'Advocacy to support the new workforce'},
    {title: 'Resources to grow your business'},
    {title: 'Exclusive member discounts'},
]

const {Title, Text} = Typography;
const LoginLeft = () => {
    return (
        <>
            {!isMobile && 
                <Col xs={{span: 24}} md={{span: 12}} className={styles.sign_up_info}
                     style={{backgroundImage: `url(${IMAGE_URL}logo_bg.png)`}}>
                    <Title level={2} className={styles.heading}>Become a Member</Title>
                    <Row style={{width: '100%'}}>
                        <Col xs={{span: 20, offset: 2}} sm={{span: 18, offset: 6}} md={{span: 16, offset: 5}}>
                            <List
                                className={styles.reg_list}
                                itemLayout="horizontal"
                                split={false}
                                dataSource={list}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<img src={`${IMAGE_URL}check_circle.png`} alt=""
                                                         className={styles.list_img}/>}
                                            title={<Text>{item.title}</Text>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                    <div className={styles.img_bottom}>
                        <img src={`${IMAGE_URL}stuff.png`} alt=""/>
                    </div>
                </Col>
            }
        </>
    );
};

export default LoginLeft;
