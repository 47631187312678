import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';

const EnrollThankyou = (props) => {
	const search = useLocation().search;
	const [enrollment, setEnrollment] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getIDFromURL = () => {
			const searchParams = new URLSearchParams(search);
			const id = searchParams.get('id');
			return id;
		};
		fetchEnrollment(getIDFromURL());
	}, [search]);

	const fetchEnrollment = async (id) => {
		try {
			setLoading(true);
			await pafla.get(`course-enrollment/${id}`).then((response) => {
				if (!response.data.error) {
					setEnrollment(response.data.data);
					setLoading(false);
				} else {
					notification.error({
						message: 'Error',
						description: response.data.message,
					});
				}
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			setLoading(false);
		}
	};

	return (
		<Dashboard title="Thankyou - Pakistan Freelancers Association" loading={loading} completeProfileCheck={false}>
			<SectionBody>
				<Row>
					<div className={styles.courseThankyou}>
						<Row gutter={0} align="left">
							<Col xs={24}>
								<div className={styles.heading}>
									<CheckCircleOutlined />
									<h3>Your enrollment is recieved!</h3>
								</div>
							</Col>
						</Row>
						<Row gutter={0}>
							<Col xs={24} md={24}>
								<p>
									Thankyou for registering for {enrollment?.course}. Your enrollment has been received. Please find the details of your registration
									below:
								</p>
							</Col>

							<Col xs={24} md={24}>
								<table>
									<colgroup>
										<col span="1" style={{ width: '40%' }} />
										<col span="1" style={{ width: '60%' }} />
									</colgroup>
									<tbody>
										<tr>
											<td>
												<b>Enrollment ID</b>
											</td>
											<td>{enrollment?.id}</td>
										</tr>
										<tr>
											<td>
												<b>Name</b>
											</td>
											<td>{enrollment?.user_name}</td>
										</tr>
										<tr>
											<td>
												<b>Course</b>
											</td>
											<td>{enrollment?.course}</td>
										</tr>
										<tr>
											<td>
												<b>Program</b>
											</td>
											<td>{enrollment?.program}</td>
										</tr>
										<tr>
											<td>
												<b>Status</b>
											</td>
											<td>{enrollment?.status}</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col xs={24} md={24} style={{ marginTop: '30px' }}>
								<p>
									A confirmation email has been sent to <u>{enrollment?.user_email}</u> with further details. We greatly appreciate your commitment
									and wish you the best of luck in this course!
								</p>
							</Col>
						</Row>
					</div>
				</Row>
			</SectionBody>
		</Dashboard>
	);
};

export default EnrollThankyou;
