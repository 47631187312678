import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import {Zoom} from 'react-reveal';
import Sponsor from './Sponsor'

const {Title, Paragraph} = Typography;
const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.sponsors}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>Our Partners & Sponsors</h1>
                            <h2>We gratefully acknowledges the generosity of our many FreelanceFest 2022 sponsors. Their support, helps make it possible to accomplish all that we do.</h2>
                        </div>
                        <div className={styles.sponsor_items}>
                            <Sponsor 
                                priority="one"
                                image='freelance-fest/sponsors/pwc.png'
                                name="A.F.Ferguson & Co. Chartered Accountants, a member firm of the PwC network" 
                                type="Knowledge Partner"
                            />
                            <Sponsor 
                                priority="one"
                                image='freelance-fest/sponsors/faysalbank.png'
                                name="Faysal Bank Limited" 
                                type="Platinum Sponsor"
                            />

                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/BankIslami.jpeg'
                                name="Bank Islami" 
                                type="Gold Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/bafl.png'
                                name="Bank Alfalah" 
                                type="Platinum Sponsor"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/Consultisor.png'
                                name="Consultisor" 
                                type="Live Streaming Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/Meezan Bank.png'
                                name="Meezan Bank" 
                                type="Product Launching Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/iobm.png'
                                name="IoBM" 
                                type="Academic Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/payfast.png'
                                name="Payfast" 
                                type="Tech Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/sahalverifai.png'
                                name="Sahal Verifai" 
                                type="Tech Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/1Link.png'
                                name="1Link Pvt. Ltd" 
                                type="Payments Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/ejadlabs.png'
                                name="Ejad Labs" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/payoneer.png'
                                name="Payoneer" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/ignite.png'
                                name="Ignite" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/stza.png'
                                name="Special Technology Zones Authority (STZA)" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/Qisstpay.png'
                                name="Qisstpay" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/onx.png'
                                name="Onx Digital" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/jang.png'
                                name="Jang Group" 
                                type="Media Partner"
                            />
                            <Sponsor 
                                priority="three"
                                image='freelance-fest/sponsors/Silkbank.png'
                                name="Silk Bank" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="two"
                                image='freelance-fest/sponsors/jsbank.png'
                                name="JS Bank" 
                                type="Powered by"
                            />
                            <Sponsor 
                                priority="two"
                                image='freelance-fest/sponsors/AlMeezan.jpeg'
                                name="Al Meezan Investment" 
                                type="Supporting Partner"
                            />
                            <Sponsor 
                                priority="two"
                                image='freelance-fest/sponsors/techx.png'
                                name="TechX Pakistan" 
                                type="Media Partner"
                            />
                        </div>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
