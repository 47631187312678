import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React  from 'react';

class ImageUpload extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        loading: false,
	        imageUrl: null
	    }
	}

	componentDidUpdate() {
		if (this.props.img && this.state.imageUrl === null) {
			const imgUrl = this.props.img
			this.setState({imageUrl: imgUrl})
		}
	}

	getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
		}

		const isLt2M = file.size / 1024 / 1024 < 2;

		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");
		}

		return isJpgOrPng && isLt2M;
	};

	handleChange = (info) => {
		if (info.file.status === "uploading") {
			this.setState({loading: true})
			return;
		}

		if (info.file.status === "done") {
			// Get this url from response in real world.
			this.getBase64(info.file.originFileObj, (url) => {
				this.setState({loading: false})
				this.setState({imageUrl :url})
			});

			if (!info.file.response.error) {
				this.props.filename(info.file.response.data);
			}
		}
	};

	render() {
		return (
			<Upload
				name="image"
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				action={`${process.env.REACT_APP_API_URL}/upload-image`}
				beforeUpload={this.beforeUpload}
				onChange={this.handleChange}
				maxCount={1}
			>
				{this.state.imageUrl ? (
					<img
						src={this.state.imageUrl}
						alt="avatar"
						style={{
							width: "100%"
						}}
					/>
				) : (
					<div>
						{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
						<div
							style={{
								marginTop: 8
							}}
						>
							Upload
						</div>
					</div>
				)}
			</Upload>
		);
	}
};


export default ImageUpload;