import React from 'react';
import Layout from '../components/Layout';
import Subscribe from '../components/Subscribe';
import Banner from '../components/Banner';
import { SIGN_UP, IMAGE_URL } from '../helpers/constants';
import HomeAbout from '../components/home/HomeAbout';
import InThePress from '../components/home/InThePress';
import Statistics from '../components/home/Statistics';
import Community from '../components/home/Community';
import Partners from '../components/Partners';
import Highlights from '../components/home/Highlights';
import Featured from '../components/home/Featured';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
	return (
		<Layout>
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Pakistan Freelancers Association</title>
				</Helmet>
				<Banner
					title="The biggest safetynet for the independent workforce in Pakistan."
					text="Providing freelancers a platform as well as a support group to help grow their career and overcome their challenges."
					button={
						<Button type="primary" className="ant-btn-orange mb-3">
							<Link to={SIGN_UP}>Become a member</Link>
						</Button>
					}
					background={`${IMAGE_URL}home_banner_bg.png`}
					image={`${IMAGE_URL}home_banner_img.png`}
					width={[9, 14]}
				/>
				<Featured />
				<HomeAbout />
				<Highlights />
				<Partners />
				<Statistics />
				<InThePress />
				<Community />
				<Subscribe />
			</div>
		</Layout>
	);
};

export default Home;
