import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import {Zoom} from 'react-reveal';
import Speaker from './Speaker'
import { speakers } from './constants'

const {Title, Paragraph} = Typography;

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.speakers}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>Meet our Speakers</h1>
                            <h2>Freelance Fest gathers the brightest minds in the gig-economy world. Learn new skills and get insights from experts and practitioners from all around!</h2>
                        </div>
                        <div className={styles.speaker_items}>
                            {speakers.map(speaker => {
                                return (
                                    <Speaker
                                        name={speaker.name}
                                        designation={speaker.designation}
                                        company={speaker.company}
                                        image={speaker.image}
                                        linkdln={speaker.linkdln}
                                        type={speaker.type}
                                    />
                                )
                            })}
                        </div>
                        <div className={styles.speaker_items} style={{display: 'inline-flex'}}>
                            <Button type="primary" className={`btn_default ${styles.event_register_btn} mb-5`}>
                                <a href='https://airtable.com/shrNYWHhyTJ0HDflb' target="_blank">Click here to view all speakers</a>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
