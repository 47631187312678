import React from 'react';
import {Card} from 'antd';
import styles from '../../styles/freelance-fest/general.module.css'
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../helpers/constants';
import { CheckOutlined } from '@ant-design/icons';

const { Meta } = Card;

const BlogCard = ({name, memberPrice, memberPriceDiscounted, discountText, description, features, buttonLink, buttonText, buttonType }) => {
    return (
        <div className={styles.package}>
            <div className={styles.scheduleItemRow}>
                <div className={styles.pricingPlan}>
                    <h4 className={styles.packageTitle}>{name}</h4>
                    <div className={styles.pricing}>
                        {memberPrice ?
                            <div className={memberPriceDiscounted && styles.memberDiscountedStroked} >
                                {(memberPrice != 'Free') &&  <sup>PKR</sup> }
                                <span className={styles.packagePrice}>{memberPrice}</span>
                            </div>
                            :
                            <span className={styles.packagePrice}>-</span>
                        }
                        {memberPriceDiscounted &&
                            <>
                                <div className={memberPriceDiscounted && styles.memberDiscounted}>
                                    <sup>PKR</sup>
                                    <span className={styles.packagePrice}>{memberPriceDiscounted}</span>
                                </div>
                                {discountText &&
                                    <div className={styles.discountText}>
                                        {discountText}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <p className={styles.packageDescription}>{description}</p>
                    {(buttonType === 'link') ?
                        <a
                            href={buttonLink} 
                            target="_new" 
                            className={`btn_default ${styles.package_button}`}
                        >
                            {buttonText}
                        </a>
                    :
                        <Link
                            to={buttonLink} 
                            target="_self" 
                            className={`btn_default ${styles.package_button}`}
                        >
                            {buttonText}
                        </Link>
                    }
                </div>
                <div className={`${styles.features}`}>
                    {features.map(feature => {
                        return (
                            <div className={styles.feature}>
                                <span className={styles.featureTitle}>{feature.name}</span>
                                <span className={styles.featureEnabled}><CheckOutlined /></span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
