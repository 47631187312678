import React from 'react';
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import Dashboard from '../../components/dashboard/Main';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import MembershipCard from '../../components/dashboard/MembershipCard';
import MembershipDetails from '../../components/dashboard/MembershipDetails';
import Package from '../../components/dashboard/Package';
import SectionSidebar from '../../components/dashboard/SectionSidebar'
import {MEMBERSHIP, ORDERS} from '../../helpers/constants';
import pafla from '../../api/pafla'
import Cookies from 'js-cookie';
import { Row, Col, Menu } from 'antd';
import { packages } from '../../helpers/constants'
import { notification } from 'antd'


class Membership extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        membership: null,
            loading: true,
            hasMore: true,
	    }
	}

	componentDidMount() {

        const loggedCookie = Cookies.get('auth')

        if (!!loggedCookie || typeof loggedCookie != undefined || loggedCookie !== 'undefined') {
            pafla.get('/user/membership')
            .then((response) => {
                this.setState({membership: response.data.data})
                this.setState({loading: false})
            })
        } else {
            this.props.history.push('/')
        }
    }

    validateMembership = async (packageName, link, disable) => {

        if (disable) {
            return false
        }

        await pafla.post('/user/membership/validate', { package_name: packageName })
        .then((response) => {
            if (!response.data.error) {
                notification.success({
                    message: 'Success',
                    description: response.data.message                     
                });   

                this.props.history.push(link)
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message                     
                });
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }


    render() {
        const { loading, membership } = this.state;
        return (
        	<Dashboard loading={loading}>
                <Row>
                    <SectionSidebar width="6" title="Navigate">
                        <Menu>
                            <Menu.Item>
                                <NavLink to={MEMBERSHIP}>Membership Details</NavLink>
                            </Menu.Item>
                            <Menu.Item>
                                <NavLink to={ORDERS}>Order History</NavLink>
                            </Menu.Item>
                        </Menu>
                    </SectionSidebar>
                    {membership &&
                        <SectionBody width="18">
                            <SectionHeader>
                                <h2>Membership</h2>
                            </SectionHeader>
                            <Row gutter="0" align="middle">
                                <Col xs={{span: 24}} md={{span: 14}} style={{margin: '0 auto'}}>
                                    <MembershipCard data={membership}/>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 10}} style={{margin: '0 auto'}}>
                                    <MembershipDetails data={membership}/>
                                </Col>
                            </Row>
                            <SectionHeader>
                                <h2>Packages</h2>
                            </SectionHeader>
                            {packages.map((pckage) => {
                                const active = (membership && (membership.membership_package === pckage.name)) ? true : false
                                return (
                                    <Package
                                        active={active}
                                        name={pckage.name}
                                        memberPrice={pckage.memberPrice}
                                        memberPriceDiscounted={pckage.memberPriceDiscounted}
                                        discountText={pckage.discountText}
                                        description={pckage.description}
                                        features={pckage.features}
                                        buttonText={pckage.buttonText}
                                        buttonLink={pckage.buttonLink}
                                        disabled={pckage.disabled}
                                        validate={this.validateMembership}
                                        type={pckage.type}
                                    />
                                )
                            })}
                        </SectionBody>
                    }
                </Row>
            </Dashboard>
        );
    }
}

export default withRouter(Membership);
        