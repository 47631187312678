import React from 'react';
import {Row, Col, Button, Drawer, Menu, Dropdown, Alert } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import styles from '../styles/header.module.css'
import {HOME, ABOUT, IMAGE_URL, SIGN_UP, EVENTS, BLOGS, LOG_IN, MEDIA_MENTIONS, BOARD, MEMBERSHIP_JOIN, OUR_VOICE} from '../helpers/constants';
import {Link, NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect'
import { DownOutlined } from '@ant-design/icons';

const MenuList = ({itemClick,...rest}) => {
	return (
		<ul {...rest} >
			{/*<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink  to={INSURANCE}>Insurance</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={LOANS}>Loans</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={HELP}>Help</NavLink>
			</li>*/}
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink exact to={HOME}>Home</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={EVENTS}>Events</NavLink>
			</li>
			{<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={BLOGS}>Blog</NavLink>
			</li>}
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={ABOUT}>About us</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={OUR_VOICE}>Our Voice</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={BOARD}>Our Board</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={MEMBERSHIP_JOIN}>Membership</NavLink>
			</li>

			{/*<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<Dropdown overlay={menu} placement='bottomCenter' overlayClassName='menu_overlay'>
					<a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
						About Us <DownOutlined />
					</a>
				</Dropdown>
			</li>*/}
		</ul>
	)
}

const MenuListMobile = ({itemClick,...rest}) => {
	var membershipId=null
	const authCookie = Cookies.get('auth');
	let signedIn = false 
	if (authCookie) {
		if (authCookie.trim() !== "") {
			 signedIn = true 
		}
	}
	const userDetails = Cookies.get('user_details') ;
    if (userDetails) {
        const parsed = JSON.parse( userDetails )
        if (parsed.member_id) {
             membershipId = parsed.member_id 
        }
    }

	return (
		<ul {...rest} >
			{/*<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink  to={INSURANCE}>Insurance</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={LOANS}>Loans</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={HELP}>Help</NavLink>
			</li>*/}
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={HOME}>Home</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={EVENTS}>Events</NavLink>
			</li>
			{<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={BLOGS}>Blog</NavLink>
			</li>}
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={ABOUT}>About Us</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={OUR_VOICE}>Our Voice</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={BOARD}>Our Board</NavLink>
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={MEMBERSHIP_JOIN}>Membership</NavLink>
			</li>
			{/*<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
				<NavLink to={MEDIA_MENTIONS}>Media Mentions</NavLink>
			</li>*/}
			{!signedIn && 
				<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
					<NavLink to={LOG_IN}>Login</NavLink>
				</li>
			}
			{signedIn && 
		   		<div className={styles.profileMenuContainer}>
					<Menu className={styles.profileMenu} >
						<Menu.Item 
							className={styles.profileMenuInner} 
							icon={<FontAwesomeIcon icon={faUser} className={styles.profileMenuIcon} />}
						>
							<NavLink className={styles.profileMenuText} to={`/dashboard/peers`}>My Dashboard</NavLink>
						</Menu.Item>
					</Menu>
			   	</div>
			}
		</ul>
	)
}

class Header extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}
	}

	onClose = () => {
		this.setState({
			visible: false,
		});
	};


	signedIn() {
		const authCookie = Cookies.get('auth');
		if (authCookie) {
			if (authCookie.trim() !== "") {
				return true
			}
		}
		return false
	}

	membershipId() {

	}

	isProfileCompleted() {
	    const userDetails = Cookies.get('user_details') ;
	    if (userDetails) {
	        const parsed = JSON.parse( userDetails )
	        if (parsed.is_profile_completed) {
	            return true
	        }
	    }
	    return false
	}

	render() {
	   	return (
	   		<>
		   		<Row>
		   			<Col xs={{span: 24}} md={{span: 24}} style={{margin: '0 auto'}}>
				   		{(this.signedIn() && !this.isProfileCompleted()) && 
				   			<Alert
								message={<Link to="/dashboard/complete-profile">We've noticed that you haven't completed your profile with us yet. To enjoy full beneifts please complete your profile from <u>here</u></Link>}
								type="error"
								closable
								style={{width: '100%'}}
				   			/>	
				   		}
			   		</Col>
		   		</Row>
	   		   	<header className={styles.header}>
	   		   		<Row>
	   				   <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}} className={styles.header_wrapper}>
	   				      <div className={styles.menu_modal}>
	   				   	   <MenuOutlined onClick={() => this.setState({visible: true}) } className={styles.menu_icon}/>
	   				   	   <Drawer
	   				   		   title={<CloseOutlined onClick={() => this.setState({visible: false})}/>}
	   				   		   placement="left"
	   				   		   closable={false}
	   				   		   onClose={this.onClose}
	   				   		   visible={this.state.visible}
	   				   	   >
	   				   		   <MenuListMobile className={`${styles.menu_mobile} ${styles.menu_list} mobile-menu`} itemClick={() => this.setState({visible: true})}/>
	   				   	   </Drawer>

	   				      </div>
	   					   <div className={styles.header_logo}>
	   						   	<Link to="/">
	   								<img className='logo' src={`${IMAGE_URL}logo.svg`} alt=""/>
	   						   	</Link>
	   					   </div>
	   					   <MenuList className={`${styles.menu_desktop} ${styles.menu_list}`}/>
	   					   {(this.signedIn() && !isMobile ) &&
	   					   		<div className={styles.profileMenuContainer}>
									<Menu className={styles.profileMenu} >
										<Menu.Item 
											className={styles.profileMenuInner} 
											icon={<FontAwesomeIcon icon={faUser} className={styles.profileMenuIcon} />}
										>
											<NavLink className={styles.profileMenuText} to={`/dashboard/peers`}>My Dashboard</NavLink>
										</Menu.Item>
									</Menu>
	   						   	</div>
	   					   }
	   					   {!this.signedIn() &&
	   						   <div className={styles.header_btn}>
	   								{!isMobile && <Link to={LOG_IN} className={styles.header_menu_login}>Login</Link>}
	   								<Button className="btn_default">
	   									<Link to={SIGN_UP}>Become a member</Link>
	   								</Button>
	   						   </div>
	   					   }
	   				   </Col>
	   			   </Row>
	   		   	</header>
	   		</>
	   ); 
	}
}

export default Header;
