import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet"
import styles from '../../styles/dashboard.module.css';
import SectionBody from './SectionBody'
import { FrownOutlined } from '@ant-design/icons';
import { COMPLETE_PROFILE } from '../../helpers/constants';
import Loader from '../Loader'
import { Button, Row } from 'antd';
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from '../Footer'

const DashboardMain = ({completeProfileCheck = false, loading = true, title = null, children}) => {

    const [profileCompleted, setProfileCompleted] = useState(false)
    const scrollRef = useRef(null)

    useEffect(() => {
        const userDetails = Cookies.get('user_details');
        if (userDetails) {
            const parsed = JSON.parse( userDetails )
            if (parsed.is_profile_completed) {
                setProfileCompleted(true)
            }
        }

        if (loading) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        }

    })

    const showCompleteProfile = () => {
        // if check is true and profile is completed show
        if (completeProfileCheck && profileCompleted) {
            return false
        } 
        // if check is false show
        else if (!completeProfileCheck) {
            return false
        } else {
            return true
        }      
    }

    return ( 
        <div ref={scrollRef} className={`dashboard ${styles.dashboardBody}`}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
            </Helmet>
            <Header/>
            <div className={styles.dashboardInner}>
                {loading && <Row> <SectionBody> <Loader margin="300px auto"/> </SectionBody> </Row>}
                {!loading &&
                    <>
                        {showCompleteProfile() &&
                            <Row>
                                <SectionBody>
                                    <div className={styles.dashboardCompleteNotify}>
                                        <h2 style={{width:"100%",textAlign: "center"}}><FrownOutlined style={{ fontSize: '23px', marginRight: '5px' }}/>  Uh oh! We've noticed you haven't completed your profile.</h2>
                                        <p>Please complete your profile by clicking the button below to enjoy full benefits</p>
                                        <br/>
                                        <Button type="primary">
                                            <Link to={COMPLETE_PROFILE}>Complete your profile now!</Link>
                                        </Button>
                                    </div>                    
                                </SectionBody>
                            </Row>
                        }
                        {!showCompleteProfile() && 
                            <>{children}</>
                        }
                    </>
                }
            </div>
            <Footer/>
        </div>
    );
};

export default DashboardMain;
