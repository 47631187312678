import React, { useState, useEffect, useRef } from 'react';
import {
    PushpinOutlined
} from '@ant-design/icons';

import { Avatar, Divider, Typography } from 'antd';
import styles from '../../styles/member.module.css'
import Hourlyrate from '../Hourlyrate'

const { Text } = Typography;

const MemberProfile = (props) => {

    return (
        <>
            <div className={styles.memberProfileHeader}>
                <div className={styles.avatar}>
                    <Avatar
                        src={props.avatar}
                        size={{ xs: 100, sm: 32, md: 40, lg: 64, xl: 60, xxl: 100 }}
                    />
                </div>
                <div className={styles.detail}>
                    <h2>{props.name}</h2>
                    <Text type="h3" ellipsis={true} style={{maxWidth: '300px'}}>{props.title}</Text>
                    <h5><PushpinOutlined /> {props.city}, {props.country}</h5>
                </div>

                <div className={styles.hourly_rate}>
                    <h3><Hourlyrate rate={props.rate}/></h3>
                </div>
            </div>
            <Divider/>
        </>
    );
};

export default MemberProfile;
