import React from 'react';
import { Row, Col, Drawer, Menu, Dropdown } from 'antd';
import { CloseOutlined, MenuOutlined, DownOutlined } from '@ant-design/icons';
import styles from '../../styles/dashboard.module.css';
import {
	IMAGE_URL,
	MEMBERSHIP,
	EDIT_PROFILE,
	ISSUES,
	PEERS,
	JOBS,
	MENTORSHIP,
	TRAINING,
	TESTS,
	FILINGS,
	INSURANCE,
	CHAPTER_CONTRIBUTOR,
	UNIVERSITY_AMBASSADOR,
	HOST_YOUR_MEETUP,
	APPLY_AS_A_SPEAKER,
	DASHBOARD_EVENTS,
	PROMOTIONS,
	GCC_DASHBOARD_LANDING,
} from '../../helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';

const { SubMenu } = Menu;

const MenuList = ({ itemClick, ...rest }) => {
	let membershipId = null;
	const userDetails = Cookies.get('user_details');
	if (userDetails) {
		const parsed = JSON.parse(userDetails);
		if (parsed.member_id) {
			membershipId = parsed.member_id;
		}
	}

	const network = (
		<Menu className={styles.mobileMenu}>
			<Menu.Item>
				<NavLink to={PEERS}>Peers</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`/member/${membershipId}`}>Your profile</NavLink>
			</Menu.Item>
		</Menu>
	);

	const contribute = (
		<Menu className={styles.mobileMenu}>
			<Menu.Item>
				<NavLink to={CHAPTER_CONTRIBUTOR}>Chapter Contributor</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={UNIVERSITY_AMBASSADOR}>University Ambassador</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={HOST_YOUR_MEETUP}>Meetups</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={APPLY_AS_A_SPEAKER}>Speaker/Trainer</NavLink>
			</Menu.Item>
		</Menu>
	);

	const safetynet = (
		<Menu className={styles.mobileMenu}>
			{/*<Menu.Item>
                <NavLink to={FILINGS}>Annual Fillings</NavLink>
            </Menu.Item>
            <Menu.Item>
                <NavLink to={INSURANCE}>Insurance</NavLink>
            </Menu.Item>*/}
			<Menu.Item>
				<NavLink to={ISSUES}>Issues</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={PROMOTIONS}>Discounts & Promotions</NavLink>
			</Menu.Item>
		</Menu>
	);

	const learn = (
		<Menu className={styles.mobileMenu}>
			<Menu.Item>
				<NavLink to={DASHBOARD_EVENTS}>Events</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={GCC_DASHBOARD_LANDING}>Google Career Certificates</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to="/dashboard/course/soft-skills-batch-2/11">Google Soft Skills Program</NavLink>
			</Menu.Item>
			{/*<Menu.Item>
                <NavLink to={TESTS}>Skill tests</NavLink>
            </Menu.Item>            
            <Menu.Item>
                <NavLink to={MENTORSHIP}>Mentorship</NavLink>
            </Menu.Item>*/}
		</Menu>
	);

	return (
		<ul {...rest}>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void 0}>
				{!isMobile ? (
					<Dropdown overlay={network} placement="bottomCenter" overlayClassName="menu_overlay">
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Network <DownOutlined />
						</a>
					</Dropdown>
				) : (
					<>
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Network <DownOutlined />
						</a>
						{network}
					</>
				)}
			</li>
			{/*<li className={styles.menu_item} onClick={itemClick ? itemClick : void(0)}>
                <NavLink to={JOBS}>Remote Jobs</NavLink>
            </li>   */}
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void 0}>
				{!isMobile ? (
					<Dropdown overlay={learn} placement="bottomCenter" overlayClassName="menu_overlay">
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Learn <DownOutlined />
						</a>
					</Dropdown>
				) : (
					<>
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Learn <DownOutlined />
						</a>
						{learn}
					</>
				)}
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void 0}>
				{!isMobile ? (
					<Dropdown overlay={safetynet} placement="bottomCenter" overlayClassName="menu_overlay">
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Safety net <DownOutlined />
						</a>
					</Dropdown>
				) : (
					<>
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Safety net <DownOutlined />
						</a>
						{safetynet}
					</>
				)}
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void 0}>
				{!isMobile ? (
					<Dropdown overlay={contribute} placement="bottomCenter" overlayClassName="menu_overlay">
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Contribute <DownOutlined />
						</a>
					</Dropdown>
				) : (
					<>
						<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Contribute <DownOutlined />
						</a>
						{contribute}
					</>
				)}
			</li>
			<li className={styles.menu_item} onClick={itemClick ? itemClick : void 0}>
				<NavLink to={MEMBERSHIP}>Membership</NavLink>
			</li>
		</ul>
	);
};

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			current: 'my-account',
		};
	}

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	signedIn() {
		const authCookie = Cookies.get('auth');
		if (authCookie) {
			if (authCookie.trim() !== '') {
				return true;
			}
		}
		return false;
	}

	isProfileCompleted() {
		const userDetails = Cookies.get('user_details');
		if (userDetails) {
			const parsed = JSON.parse(userDetails);
			if (parsed.is_profile_completed) {
				return true;
			}
		}
		return false;
	}

	handleClick = (e) => {
		this.setState({ current: e.key });
	};

	render() {
		return (
			<div className={styles.dashboardHeader}>
				<Row>
					<Col xs={{ span: 22 }} md={{ span: 24 }} style={{ margin: '0 auto' }} className={styles.header_wrapper}>
						<div className={styles.menu_modal}>
							<MenuOutlined onClick={() => this.setState({ visible: true })} className={styles.menu_icon} />
							<Drawer
								title={<CloseOutlined onClick={() => this.setState({ visible: false })} />}
								placement="left"
								closable={false}
								onClose={this.onClose}
								visible={this.state.visible}
							>
								<MenuList className={`${styles.menu_mobile} ${styles.menu_list} mobile-menu`} itemClick={() => this.setState({ visible: true })} />
							</Drawer>
						</div>
						<div className={styles.header_logo}>
							<img className={styles.dashboard_logo} src={`${IMAGE_URL}logo.svg`} alt="" />
						</div>
						<MenuList className={`${styles.menu_desktop} ${styles.menu_list}`} />
						<div className={styles.profileMenuContainer}>
							<Menu className={styles.profileMenu} onClick={this.handleClick} mode="horizontal">
								<SubMenu
									className={styles.profileMenuInner}
									key="my-account"
									icon={<FontAwesomeIcon icon={faUser} className={styles.profileMenuIcon} />}
								>
									<Menu.Item key="setting:2">
										<Link to={EDIT_PROFILE}>Edit Profile</Link>
									</Menu.Item>
									<Menu.Item key="setting:3">
										<Link to="/logout">Logout</Link>
									</Menu.Item>
								</SubMenu>
							</Menu>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Header;
