import React from 'react';
import styles from '../../styles/freelance-fest/general.module.css'
import { CalendarOutlined } from '@ant-design/icons';

const eventDate = ({children}) => {
    return (
        <div className={styles.venueDate}>
            <a 
                target="_blank"
                href="https://calendar.google.com/calendar/r/eventedit?text=Freelance+Fest+2022+Gilgit&dates=20220816T040000/20220817T130000&ctz=Etc/GMT+5&details=For+details,+link+here:+https://pafla.org.pk/freelance-fest-2022-gilgit&location=W8F5+7P9,+Saddar Bazaar,+Gilgit,+Gilgit-Baltistan,+Pakistan">
                <CalendarOutlined />
                <span className={styles.date}>16<sup>th</sup> 17<sup>th</sup> August 2022</span>
                <span className={styles.date}>Govt High School #1 Gilgit</span>
            </a>
        </div>
    )
}

export default eventDate;
