import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Row, Col, Input, Select, Checkbox, Alert, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import styles from '../../styles/dashboard.module.css';
import pafla from '../../api/pafla';
import axios from 'axios';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';
import FormButton from '../auth/FormButton';
import { Radio } from 'antd';
import ReactPixel from 'react-facebook-pixel';
import { useForm } from 'antd/lib/form/Form';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
	wrapperCol: {
		lg: { span: 12, offset: 6 },
		xs: { span: 16, offset: 0 },
		md: { span: 16, offset: 4 },
		sm: { span: 16, offset: 4 },
	},
};

const CourseEnrollmentForm = (props) => {
	const [courses, setCourses] = useState(null);
	const [questionnaires, setQuestionnaires] = useState(null);
	const [questionnaireId, setQuestionnaireId] = useState(null);
	const [formLoading, setFormLoading] = useState(true);
	const { courseId, programId } = useParams();
	const [currentCourseId, setCurrentCourseId] = useState(null);
	const [activeConsent, setActiveConsent] = useState(false);
	const [profileCompleted, setProfileCompleted] = useState(true);
	const [form] = useForm();
	const history = useHistory();

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				await pafla.get('course-program', { params: { program_id: programId } }).then((response) => {
					setCourses(response.data.data.courses);
					setQuestionnaires(response.data.data.questionnaire);
					setQuestionnaireId(response.data.data.questionnaire.id);
					setFormLoading(false);

					// Set the initial value based on courseId from URL params
					if (courseId) {
						setCurrentCourseId(parseInt(courseId));
						form.setFieldsValue({
							course: parseInt(courseId),
						});
					}
				});
			} catch (error) {
				console.error('Error fetching data:', error);
				setFormLoading(false);
			}
		};

		const checkProfileCompleted = async () => {
			try {
				await pafla.get('/user/profile-completed').then((response) => {
					setFormLoading(false);
					setProfileCompleted(response.data.data);
				});
			} catch (error) {
				console.error('Error fetching data:', error);
				setFormLoading(false);
			}
		};
		checkProfileCompleted();
		fetchCourses();
	}, []);

	const handleCourseChange = (value) => {
		setCurrentCourseId(value);
	};

	const handleQuestionnaireChange = (questionId, value) => {
		const updatedValues = {};
		updatedValues[`question_${questionId}`] = value;
		form.setFieldsValue(updatedValues);
	};

	const onConsentChange = (active) => {
		setActiveConsent(active);
		form.setFieldsValue({
			consent: active,
		});
	};

	const onFinish = async (values) => {
		setFormLoading(true);

		const formData = {};

		formData.course = values.course;
		formData.questionnaire_id = questionnaireId;
		formData.questionnaire = groupQuestionnireData(values);

		try {
			await pafla.post(`course`, formData).then((response) => {
				setFormLoading(false);
				if (!response.data.error) {
					ReactPixel.track('CourseEnrolled');
					notification.success({
						message: 'Success',
						description: response.data.message,
					});
					history.push(`/dashboard/course/thankyou?id=${response.data.data.hashed}`);
				} else {
					notification.error({
						message: 'Error',
						description: response.data.message,
					});
				}
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			setFormLoading(false);
		}
	};

	const groupQuestionnireData = (formData) => {
		const questionData = [];

		// Loop through the keys of formData
		Object.keys(formData).forEach((key) => {
			// Check if the key starts with 'question_'
			if (key.startsWith('question_')) {
				// Extract the question number
				const questionNumber = key.replace('question_', '');

				// Push an object containing the question number and value to the questionData array
				questionData.push({
					questionNumber: questionNumber,
					value: formData[key],
				});
			}
		});

		return JSON.stringify(questionData);
	};

	return (
		<Form
			{...layout}
			form={form}
			name="normal_login"
			className={styles.contribtue_form}
			initialValues={{
				course: currentCourseId,
			}}
			onFinish={onFinish}
			labelCol={{ span: 12 }}
			wrapperCol={{ span: 24 }}
			layout="vertical"
		>
			{/* 			{!profileCompleted && (
				<Row gutter={15} style={{ marginBottom: '30px' }}>
					<Space direction="vertical" style={{ width: '100%' }} size="large">
						<Link
							to={{
								pathname: '/dashboard/complete-profile',
								state: { referrer: window.location.pathname },
							}}
						>
							<Alert
								message="Complete your profile"
								description={
									<p>
										We have noticed you have not completed your profile. Please <u>click here</u> to complete your profile to successfully enroll in
										this course.
									</p>
								}
								type="warning"
								showIcon
							/>
						</Link>
					</Space>
				</Row>
			)} */}
			<Row gutter={0}>
				<Col xs={24} md={24}>
					<Form.Item
						name="course"
						label="Select your course"
						rules={[
							{
								required: true,
								message: 'Your course is required',
							},
						]}
					>
						<Select placeholder="Select your course" allowClear className={styles.dropdown} onChange={handleCourseChange} loading={formLoading}>
							{courses &&
								courses.map((course) => {
									return (
										<Option key={course.id} className={styles.dropdownValue} value={course.id}>
											{course.title}
										</Option>
									);
								})}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				{questionnaires?.questions.map((ques) => {
					const { question, type, choices } = ques;
					const questionId = ques.id;
					return (
						<Col xs={24} md={24}>
							<Form.Item
								name={`question_${questionId}`}
								label={question}
								rules={[
									{
										required: true,
										message: 'This question is required',
									},
								]}
							>
								{type === 'mcq' && (
									<Select
										placeholder={question}
										allowClear
										loading={formLoading}
										className={styles.dropdown}
										onChange={(value) => handleQuestionnaireChange(questionId, value)}
									>
										{choices.map((choice, index) => (
											<Option key={index} className={styles.dropdownValue} value={choice.answer}>
												{choice.answer}
											</Option>
										))}
									</Select>
								)}
								{type === 'text' && (
									<TextArea rows={3} placeholder={question} onChange={(e) => handleQuestionnaireChange(questionId, e.target.value)} />
								)}
							</Form.Item>
						</Col>
					);
				})}
			</Row>

			<Row gutter={0}>
				<Col xs={24} md={24}>
					<Form.Item>
						<FormButton label="Submit your enrollment" formLoading={formLoading} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default withRouter(CourseEnrollmentForm);
