import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd';
import styles from '../../styles/home.module.css'
import pafla from '../../api/pafla'
import ReactPlayer from 'react-player/youtube'
import { Typography } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

const { Paragraph } = Typography;

const InThePress = ({sectionId}) => {

	const [highlights, setHighlights] = useState(null)
	const [focusHighlight, setFocusHighlight] = useState(null)

	useEffect(() => {
		if (!highlights) {
			getHighlight()
		}
	});

	const swiperParams = {
		spaceBetween: 50,
	    navigation: {
	        nextEl: '.swiper-button-next',
	        prevEl: '.swiper-button-prev',
	    },
	    breakpoints: {
	        320: {
	          slidesPerView: 2
	        },
	        768: {
	          slidesPerView: 2
	        },
	        1024: {
	          slidesPerView: 4,	          
	        },
	    }
	}

	const getHighlight = async () => {

		await pafla.get('highlights')
			.then((response) => {
				setHighlights(response.data)
				setFocusHighlight(response.data[0])
			})
	}

	return (
		<section id={sectionId} className={`${styles.section} ${styles.highlights}`}>
			<Row className='contained-row' justify="space-between">
				<Row justify="space-between">
					<Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
						<div className={styles.section_title_container}>
							<h1>Highlights</h1>
							<h2>We gratefully acknowledge the generosity of our distinguished speakers. Their support, helps make it possible to accomplish all that we do.</h2>
						</div>
						<div className={styles.highlight_items}>
							<div className={`${styles.highlight_item}`}>
								<div className={styles.highlight_item_inner}>
									<div className={`${styles.cover_video} ${styles.focuscover_video}`}>
										<ReactPlayer
											url={focusHighlight?.video_url}
											light={true}
											controls={true}
											width="1080"
											height="600"
											className={`${styles.videoPlayer} ${styles.focusVideoPlayer}`}
										/>
									</div>
									{/*<div className={styles.pr_details}>
										<h3>{focusHighlight?.name}</h3>
										<h4>{focusHighlight?.position}</h4>
										<h5>{focusHighlight?.organisation}</h5>
										<p>{focusHighlight?.description}</p>
									</div>*/}
								</div>
							</div>
							<div className={styles.swiperContainer}>
								<Swiper 
									className={styles.swiperSlider} 
									{...swiperParams} 
									modules={[Navigation]}
								>
									{highlights && Object.entries(highlights).map(([key, highlight], i) => {
										return(                
											<SwiperSlide onClick={() => setFocusHighlight(highlight)}>
												<div className={styles.highlight}>
													<div className={styles.cover_video}>
														<ReactPlayer
															url={highlight.video_url}
															light={true}
															className={styles.videoPlayer}
														/>
													</div>
													<div className={styles.highlight_details}>
														<h3>{highlight.name}</h3>
														<h4>{highlight.position}</h4>
														<h5>{highlight.organisation}</h5>
													</div>
												</div>
											</SwiperSlide>
										)
									  })}
								</Swiper>
								<div className={`swiper-button-prev ${styles.swiperleftnav}`}/>
								<div className={`swiper-button-next ${styles.swiperrighttnav}`}/>
							</div>
						</div>
					</Col>
				</Row>
			</Row>
		</section>
	);
};

export default InThePress;
