import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { Zoom } from 'react-reveal';
import pafla from '../api/pafla';
import { Col, Row, Typography } from 'antd';
import { IMAGE_URL } from '../helpers/constants';
import { checklist, faqs, topics, partners } from '../helpers/softskills-constants';
import Partner from '../components/Partner';
import StatisticsCard from '../components/StatisticsCard';
import { Helmet } from 'react-helmet';
import styles from '../styles/membershipjoin.module.css';
import gccstyles from '../styles/gccstyles.module.css';
import {
	CheckCircleIcon,
	GlobeAltIcon,
	BoltIcon,
	AcademicCapIcon,
	DocumentCheckIcon,
	HeartIcon,
	FireIcon,
	HandRaisedIcon,
	BanknotesIcon,
} from '@heroicons/react/24/solid';
import { Collapse } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import ReactPlayer from 'react-player';

const { Panel } = Collapse;

const { Title, Paragraph } = Typography;

const swiperParams = {
	spaceBetween: 50,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
};

class Membership extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: null,
		};
	}

	render() {
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Google Soft Skills Program - Pakistan Freelancers Association</title>
				</Helmet>
				<section className={`${styles.section}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<Zoom left>
									<Row>
										<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
											<Row align="left">
												<Col xs={{ span: 24 }} md={{ span: 12 }} style={{ maxHeight: '300px', margin: '0 auto' }}>
													<ReactPlayer
														url="img/google-softskills/Soft-Skills-KVl.mp4"
														className={gccstyles.bgvideoPlayer}
														loop={true}
														playIcon={false}
														controls={false}
														playing
														muted
														playsinline
													/>
												</Col>
												<Col xs={{ span: 24 }} md={{ span: 11, push: 1 }} style={{ margin: '0 auto', textAlign: 'left' }}>
													<h2 style={{ fontSize: '1.8rem' }}>Enhance Your Skills With Google Soft Skills Program</h2>
													<p>
														Google Curated Soft Skill Program is your opportunity to master essential skills in communication, personal branding,
														critical thinking, and time management. Developed by Google experts, this program is designed to empower you with the soft
														skills you need to succeed in today's dynamic workplace.
													</p>
													<p>
														With this program, you'll not only enhance your skills but also become a certified expert powered by Google, equipped with
														the latest techniques and strategies. Don't miss out on this chance to elevate your career. Apply now and start your
														journey towards success!
													</p>

													<p>Google’s Soft Skills Program covers essential soft skills such as:</p>
													<ul>
														<li>Professional communication</li>
														<li>Networking</li>
														<li>Personal branding</li>
														<li>Critical thinking</li>
														<li>Problem-solving</li>
														<li>Time management</li>
													</ul>
													<p>
														<a
															href="#softskills-topics"
															title="View Courses"
															className={`ant-btn ant-btn-default ant-btn-lg btn_default ${styles.cta_button}`}
														>
															View Modules and Apply Now
														</a>
													</p>
												</Col>
											</Row>
										</Col>
									</Row>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>
				<section className={styles.statistics} id="statistics" style={{ backgroundColor: '#fff8e6', padding: '80px 0', textAlign: 'left' }}>
					<Row className="contained-row" justify="space-between">
						<Col xs={{ span: 22 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
							<Row justify="space-between" gutter={20}>
								<Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }} className={styles.statistics_heading}>
									<Title level={2} className="section-title">
										Why Soft Skills are important for you
									</Title>
									<Paragraph className="section-text">
										In a growing global workforce, soft skills are vital for success. Soft skills will help you manage projects, communicate
										effectively, and build lasting client connections, contributing significantly to your success. Fuelling careers and the economy in
										Pakistan, an impact survey supported by <u>Google</u> and conducted by <u>The Economist</u> concluded
									</Paragraph>
								</Col>
								<Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }} xl={{ span: 5, offset: 3 }} style={{ margin: '0 auto' }}>
									<StatisticsCard
										image={`${IMAGE_URL}sponsors.png`}
										statistic="81.5%"
										title="of employees in Pakistan report communication as a must-have"
									/>
								</Col>
								<Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }} style={{ margin: '0 auto' }}>
									<StatisticsCard
										image={`${IMAGE_URL}community.png`}
										statistic="47%"
										title="of the employees consider soft skills as a top priority for employability"
									/>
								</Col>
								<Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }} style={{ margin: '0 auto' }}>
									<StatisticsCard
										image={`${IMAGE_URL}loans.png`}
										statistic="53.3%"
										title="of employees in Pakistan consider self-management a high priority"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</section>
				<section className={`${styles.section} ${gccstyles.caseStudiesSection}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<Zoom left>
									<Row>
										<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ margin: '0 auto' }}>
											<Row align="left">
												<Col xs={{ span: 24 }} md={{ span: 12 }} style={{ margin: '0 auto' }}>
													<h2 className={gccstyles.gccHalfHeading}>Your journey as a Google Soft Skills Program Graduate with PAFLA</h2>
												</Col>
												<Col xs={{ span: 24 }} md={{ span: 11, push: 1 }} style={{ margin: '0 auto', textAlign: 'left' }}>
													<div className={gccstyles.checklist}>
														{checklist &&
															Object.entries(checklist).map(([key, item], i) => {
																return (
																	<div className={gccstyles.checklistItem}>
																		<div className={gccstyles.checkIcon}>
																			<CheckCircleIcon />
																		</div>
																		{!item.link ? (
																			item.name
																		) : (
																			<a style={{ color: 'inherit' }} href={item.link}>
																				<span dangerouslySetInnerHTML={{ __html: item.name }} />
																			</a>
																		)}
																	</div>
																);
															})}
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>

				<section id="softskills-topics" className={`${styles.section}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Google Soft Skill Program Modules</h2>
									<p>
										Dive into essential soft skills modules, curated by Google experts. Unlock your potential and excel in today's dynamic workplace
										with a comprehensive Soft Skills Program.
									</p>
								</div>
								<div className={`${styles.packages} ${styles.offerrings}`}>
									<Zoom left>
										<Row>
											{topics.map((topic) => {
												return (
													<Col xs={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: '40px' }}>
														<Row align="left">
															<Col xs={{ span: 24 }} md={{ span: 11 }}>
																<img
																	src={topic.imageSrc}
																	title={topic.name}
																	style={{ border: '2px solid #eee', borderRadius: '10px', boxShadow: '6px 10px 10px #eee' }}
																/>
															</Col>
															<Col xs={{ span: 24 }} md={{ span: 12, push: 1 }} style={{ margin: '0 auto', textAlign: 'left' }}>
																<h2 style={{ fontSize: '1.5rem' }}>{topic.name}</h2>
																<p>
																	<span dangerouslySetInnerHTML={{ __html: topic.description }} />
																</p>
															</Col>
														</Row>
													</Col>
												);
											})}
										</Row>
									</Zoom>
									<Row align="center" style={{ marginTop: '50px' }}>
										<Link
											target="_self"
											className={`ant-btn ant-btn-default ant-btn-lg btn_default ${styles.cta_button}`}
											to="/dashboard/course/soft-skills-batch-2/11"
										>
											Register Now!
										</Link>
									</Row>
								</div>
							</Col>
						</Row>
					</Row>
				</section>
				{/* <section id={this.props.sectionId} className={`${styles.section} ${styles.partners}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Our Partners</h2>
									<p>We gratefully acknowledge the generosity of our partners. Their support, helps make it possible to accomplish all that we do.</p>
								</div>
								<div className={gccstyles.partner_items}>
									{partners?.map((partner) => {
										return <Partner image={partner.imageSrc} name={partner.name} type={partner.type} />;
									})}
								</div>
							</Col>
						</Row>
					</Row>
				</section> */}
				<section className={`${styles.section} ${styles.faqs}`}>
					<Row className="contained-row" justify="space-between">
						<Row justify="space-between">
							<Col xs={{ span: 22 }} md={{ span: 22 }} style={{ margin: '0 auto' }}>
								<div className={styles.section_title_container}>
									<h2 style={{ fontSize: '1.8rem' }}>Common Questions</h2>
									<p>
										The following answers are provided to address the frequently asked questions regarding the matter at hand. We hope that these
										answers will provide you with the information you need to make an informed decision.{' '}
									</p>
								</div>
								<Zoom left>
									<div className={styles.qaPanels}>
										<Collapse defaultActiveKey={['1']}>
											{faqs.map((faq) => {
												return (
													<Panel className={styles.question} header={faq.question} key={faq.id}>
														<p className={styles.answer}>{faq.answer}</p>
													</Panel>
												);
											})}
										</Collapse>
									</div>
								</Zoom>
							</Col>
						</Row>
					</Row>
				</section>
			</Layout>
		);
	}
}

export default Membership;
