import React from 'react';
import {Card} from 'antd';
import styles from '../styles/event.module.css'
import { IMAGE_URL } from '../helpers/constants';

const Speaker = ({name, designation, company, image, linkdln, type}) => {
    return (
        <div className={styles.speaker}>
            <div className={styles.speaker_inner}>
                <div className={styles.cover}>
                    {image ?
                        <img src={`${process.env.REACT_APP_STORAGE_URL}/${image}`} alt=''/>
                    :
                        <img src={`${IMAGE_URL}freelance-fest/person.jpg`} alt=''/>
                    }
                </div>
                <div className={styles.details}>
                    <div className={styles.details_inner}>
                        <div className={styles.title_holder}>
                            {name ?
                                <div className={styles.speaker_name}>{name}</div>
                            :
                                <div className={styles.speaker_name}>TBA</div>
                            }
                            {designation ?
                                <div className={styles.speaker_position}>{designation}</div>
                            :
                                <div className={styles.speaker_position}>Coming Soon</div>
                            }
                            {company ?
                                <div className={styles.speaker_company}>{company}</div>
                            :
                                <div className={styles.speaker_company}>Pakistan Freelancers Association</div>
                            }
                            {/*{linkdln &&
                                <div className={styles.social_holder}>
                                    <div className={styles.speaker_lnkdln}>
                                        <a href={linkdln} target="_blank">
                                            <LinkedinOutlined />
                                        </a>
                                    </div>
                                </div>
                            }*/}
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Speaker;