import React from 'react'
import CurrencyFormat from 'react-currency-format';

class PriceFormatted extends React.PureComponent {
	render() {
		const { price } = this.props
		return price && 
			<>
				<span style={{marginRight: '7px'}}>Rs</span>
				<CurrencyFormat value={Number(price)} displayType={'text'} thousandSeparator={true} />
			</>
	}
}

export default PriceFormatted
