import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Zoom} from 'react-reveal';
import Speaker from './Speaker'
import { Tabs } from 'antd';
import ScheduleItem from './ScheduleItem'
import { agendaDayOne, agendaDayTwo } from './constants'

const { TabPane } = Tabs;

const { Title, Paragraph } = Typography;

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.schedule}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>Conference Schedule</h1>
                            <h2>Below you'll find the schedule for Freelance Fest 2022. We'll keep this page regularly updated with all new speakers and sessions, so be sure to keep checking in!</h2>
                        </div>
                        <div className={styles.agendaContainer}>
                            <Tabs defaultActiveKey="1" className={styles.tabs}>
                                <TabPane tab={<span className={styles.tabPaneHead}>August 16th, 2022</span>} key="1" className={styles.tabPane}>
                                    {agendaDayOne.map(item => {
                                        return (
                                            <ScheduleItem
                                                startTime={item.startTime}
                                                endTime={item.endTime}
                                                title={item.topicTitle}
                                                description={item.topicDescription}
                                            />
                                        )
                                    })}
                                </TabPane>
                                <TabPane tab={<span className={styles.tabPaneHead}>August 17th, 2022</span>} key="2" className={styles.tabPane}>
                                    {agendaDayTwo.map(item => {
                                        return (
                                            <ScheduleItem
                                                startTime={item.startTime}
                                                endTime={item.endTime}
                                                title={item.topicTitle}
                                                description={item.topicDescription}
                                            />
                                        )
                                    })}
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
