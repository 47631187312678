import React from 'react';
import {Row, Col, Typography, Button, Divider} from 'antd';
import ImageTextCard from '../ImageTextCard';
import {IMAGE_URL, SIGN_UP} from '../../helpers/constants';
import styles from '../../styles/freelance-fest/general.module.css'
import {Link} from 'react-router-dom';
import {Fade, Slide} from 'react-reveal';
import {Zoom} from 'react-reveal';
import Package from './Package'
import { Collapse } from 'antd';
import { faqs } from './constants'

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const HomeAbout = ({sectionId}) => {
    return (
        <section id={sectionId} className={`${styles.section} ${styles.faqs}`}>
            <Row className='contained-row' justify="space-between">
                <Row justify="space-between">
                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
                        <div className={styles.section_title_container}>
                            <h1>FAQs</h1>
                            <h2>The Freelance Fest has a variety of ticket types to suit the variety of designers out there. Find out the best option for you and your team!</h2>
                        </div>
                        <Zoom left>
                            <div className={styles.qaPanels}>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                                    {faqs.map(faq => {
                                        return (
                                            <Panel className={styles.question} header={faq.question} key={faq.id}>
                                                <p className={styles.answer}>{faq.answer}</p>
                                            </Panel>
                                        )
                                    })}
                                </Collapse>
                            </div>
                        </Zoom>
                    </Col>
                </Row>
            </Row>
        </section>
    );
};

export default HomeAbout;
