import React from 'react';
import pafla from '../../api/pafla'
import Dashboard from '../../components/dashboard/Main';
import EventCard from '../../components/EventCard';
import SectionHeader from '../../components/dashboard/SectionHeader';
import SectionBody from '../../components/dashboard/SectionBody';
import { Row } from 'antd';
import styles from '../../styles/event.module.css'
import { Helmet } from "react-helmet"


class Events extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            events: null,
            loading: true,
        }
    }

    async componentDidMount() {
        
        await pafla.get('/events')
        .then((response) => {
            this.setState({loading: false})
            this.setState({events: response.data.data})
        })
    }


    render() {
        const { loading, events } = this.state
        return (
            <Dashboard loading={loading}>                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Events - Pakistan Freelancers Association</title>
                </Helmet>
                <Row>   
                    <SectionBody>                        
                        <SectionHeader>
                            <h2>Events</h2>
                        </SectionHeader>
                        <div className={`card ${styles.events} ${styles.card_items}`}>
                            {events && 
                                Object.entries(events).map(([key, event], i) => {
                                    const sd = (event && event.start_date) && new Date(event.start_date)
                                    const sdf = (event && event.start_date) && new Intl.DateTimeFormat('en', { 
                                        year: 'numeric', 
                                        month: 'long', 
                                        day: '2-digit', 
                                        hour: "numeric",
                                        minute: "numeric"
                                    }).format(sd);
                                    const link = event.landing_page_link ? event.landing_page_link : `event/${event.slug}/${event.id}`
                                    return (
                                        <EventCard
                                            classes={styles.eventCard}
                                            key={event.id}
                                            title={event.title}
                                            date={sdf}
                                            text={`${event.excerpt}`}
                                            link={link}
                                            venue={event.venue}
                                            image={`${process.env.REACT_APP_STORAGE_URL}${event.image}`}
                                            bodyClasses={styles.bodyclass}
                                        />
                                    )
                                })
                            }

                        </div>
                    </SectionBody>
                </Row>
            </Dashboard>
        );
    }
}

export default Events;
        