import React from 'react'
import { nput, Select, Typography } from 'antd'
import styles from '../../styles/dashboard.module.css'
import { withRouter } from 'react-router'
import { Collapse } from 'antd';
import PriceFormatted from '../PriceFormatted'
const { Panel } = Collapse;

class LoginForm extends React.Component {

    calculateDiscount = () => {
        const { selectedPackage } = this.props
        let discount = 0;

        if(selectedPackage) {
            if (selectedPackage.discounted_price) {
                discount = selectedPackage.price - selectedPackage.discounted_price
            }
        }
        return discount

    }

    calculateTotal = () => {
        const { selectedPackage } = this.props
        let total = 0;

        if(selectedPackage) {
            if (selectedPackage.discounted_price) {
                total = selectedPackage.discounted_price
            } else {
                total = selectedPackage.price
            }
        }
        return total

    }
   
    render() {
        const { selectedPackage } = this.props
        return (
            <>
                {selectedPackage &&
                    <div className={`${styles.selectedEventContainer} ${styles.desktop}`}>
                         <div className={styles.ticketHead}>
                             <h3>Order Totals</h3>
                             <hr/>
                         </div>
                         <div className={styles.eventeventTotalHeadCol}>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Subtotal</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={selectedPackage.price}/></span>
                             </div>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Discount</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateDiscount()}/></span>
                             </div>
                             <hr/>
                             <div className={styles.eventTotal}>                        
                                 <span className={styles.eventTotalHead}>Total</span>
                                 <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateTotal()}/></span>
                             </div>
                         </div>
                    </div>
                }

                {selectedPackage &&
                    <Collapse expandIconPosition="right" className={`${styles.selectedEventContainer} ${styles.mobile}`} ghost accordion>
                        <Panel header="Order Totals" key="1">
                            <div className={styles.eventeventTotalHeadCol}>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Subtotal</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={selectedPackage.price}/></span>
                                 </div>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Discount</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateDiscount()}/></span>
                                 </div>
                                 <hr/>
                                 <div className={styles.eventTotal}>                        
                                     <span className={styles.eventTotalHead}>Total</span>
                                     <span className={styles.eventTotalInfo}><PriceFormatted price={this.calculateTotal()}/></span>
                                 </div>
                             </div>
                        </Panel>
                    </Collapse>
                }
            </>
        )
    }
}


export default withRouter(LoginForm);
