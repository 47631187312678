import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel'
import styles from '../styles/event.module.css';
import { Row, Typography } from 'antd';
import EventRegisterForm from '../components/auth/event/register';
import { Helmet } from "react-helmet"
import SelectedEvent from '../components/auth/event/SelectedEvent'
import pafla from '../api/pafla'
import { IMAGE_URL } from '../helpers/constants';

const MemberSignup = (props) => {

    const [eventPackage, seteventPackage] = useState(null)

    const { packageId, eventId } = props.match.params

    useEffect(() => {
        ReactPixel.pageView()

        if (!eventPackage) {
            geteventPackage(props)
        }
    });

    const geteventPackage = async (props) => {
        if (packageId) {
            await pafla.get(`packages/${packageId}`)
                .then((response) => { 
                    seteventPackage(response.data[0])
                })
        }
    }

    return (
        <div className={styles.sign_up}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ticket Booking - Pakistan Freelancers Association</title>
            </Helmet>
            <div className={styles.eventTickerHeader}>
                <img src={`${IMAGE_URL}logo.svg`}/>
            </div>
            <div className={styles.eventRegister}>
                <div className={styles.eventRegisterForm}>
                    <EventRegisterForm selectedEventId={eventId} selectedPackage={eventPackage}/>
                </div>
                <div className={styles.eventDetailsWidget}>
                    <SelectedEvent selectedPackage={eventPackage}/>
                </div>
            </div>
        </div>
    );
};

export default MemberSignup;
