import React from 'react'
import { Form, Row, Col, Space, notification, Radio, Modal } from 'antd'
import { CheckOutlined } from '@ant-design/icons';
import styles from '../../styles/dashboard.module.css'
import pafla from '../../api/pafla'
import { withRouter } from 'react-router'
import FormButton from '../auth/FormButton'

const { confirm } = Modal;

const layout = {
    wrapperCol: {
        lg: {span: 12, offset: 6},
        xs: {span: 16, offset: 0},
        md: {span: 16, offset: 4},
        sm: {span: 16, offset: 4}
    },
}

class EventRegisterForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formLoading: null,
            paymentMethod: 0,
            grand_total: null,
            oneBill: true
        }

    }

    redirect = (url) => {
        window.location.href = url;
        return false;
    }

    onFinish = (values) => {
        const { selectedPackage } = this.props
        const { payment_method } = values

        this.setState({formLoading: true})

        if (selectedPackage) {
            pafla.post(`user/membership`, {package_id: selectedPackage.id, payment_method: payment_method})
            .then((response) => {
                if (!response.data.error) {

                    /*ReactPixel.track('Purchase',
                      {
                        content_ids: ['2715521342086822'],
                        content_type: 'product',
                        eventref: 'fb_oea',
                        currency: 'PKR',
                        num_items: 1,
                        value: response.data.data.grand_total
                      }
                    );*/

                    if (payment_method === 3) {
                        this.redirect(response.data.data)
                    } else {
                        this.props.history.push(`/order/onebill?id=${response.data.data.order.id}`)
                    }                                       

                } else {
                    notification.error({
                        message: 'Error',
                        description: response.data.message                     
                    });
                }
                this.setState({formLoading: false})
            })
            .catch((error) => {
                this.setState({formLoading: false})
            })
        }
    }

    showConfirm = (values) => {
        const { selectedPackage } = this.props
        const { discounted_price, price, name } = selectedPackage
        const gd = discounted_price ? discounted_price : price
        confirm({
            title: 'Please confirm your order',
            icon: <CheckOutlined />,
            content: `You're about to buy the ${name} membership package for PKR ${gd}. Please confirm to be redirected to the payment page`,
            className: 'mystyle',
            centered: true,
            onOk: () => {
                this.onFinish(values)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    render() {
        return (
            <Form
                {...layout}
                name="normal_login"
                className={styles.event_register_form}
                onFinish={this.showConfirm}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 22 }}
                layout="vertical"
                initialValues={{
                    "payment_method": 3
                }}
            >

                <div className={styles.ticketHead}>
                    <h3>Payment Method</h3>
                    <hr/>
                </div>

                <Row gutter={0}>
                    <Col xs={24} md={24}>
                        <Form.Item 
                            name="payment_method" 
                            rules={[{ required: true, message: 'Please select your payment method' }]}
                            value={this.state.paymentMethod}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={3} onClick={this.prepareRequest}>PAFLA Pay (Credit/Debit Card)</Radio>
                                    <Radio value={5} onClick={this.prepareRequest}>PAFLA Pay (1Bill)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={0}>
                    <Col xs={24} md={24}>
                        <Form.Item>
                            <FormButton label="Order now" formLoading={this.state.formLoading}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}


export default withRouter(EventRegisterForm);
