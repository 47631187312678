import React from 'react';
import { notification, Modal, Row, Button, Alert } from 'antd';
import pafla from '../../api/pafla';
import styles from '../../styles/event.module.css';
import quizStyles from '../../styles/quizStyles.module.css';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import Dashboard from '../../components/dashboard/Main';
import SectionBody from '../../components/dashboard/SectionBody';

class CourseCertify extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			course: null,
			loading: true,
			ticketURL: null,
			showConfirm: false,
			post_program_questionnaire_link: null,
		};
	}

	async componentDidMount() {
		const { courseId } = this.props.match.params;

		await pafla.get(`/courses/${courseId}/quiz-questionnaire`).then((response) => {
			this.setState({ course: response.data.data });
			this.setState({ loading: false });
			this.setState({ post_program_questionnaire_link: response.data.data.post_program_questionnaire_link });
		});
	}

	checkQuizEligibility = async () => {
		const { course } = this.state;
		this.setState({ loading: true });
		await pafla.get(`/courses/${course.id}/check-quiz-eligibility`).then((response) => {
			this.setState({ loading: false });
			if (response.data.data.eligible) {
				this.showModal();
			} else {
				notification.error({
					message: 'Error',
					description: response.data.message,
					duration: null,
				});
			}
		});
	};

	showModal = () => {
		this.setState({ showConfirm: true });
	};

	handleCancel = () => {
		this.setState({ showConfirm: false });
	};

	handleOk = () => {
		this.props.history.push(`/dashboard/${this.state.course.id}/start-quiz/`);
	};

	openFeedbackForm = async () => {
		const { post_program_questionnaire_link } = this.state;
		if (post_program_questionnaire_link) {
			window.open(post_program_questionnaire_link, '_blank', 'noreferrer');
		}
	};

	render() {
		const { course, loading, post_program_questionnaire_link } = this.state;
		const sd = course && course.start_date && new Date(course.start_date);
		return (
			<Dashboard loading={loading} completeProfileCheck={false}>
				<Row>
					<SectionBody width="24">
						{!loading && (
							<>
								{course && post_program_questionnaire_link && (
									<Alert
										message={
											<>
												Your feedback is invaluable. Before the quiz, please <u>click here</u> to complete our quick questionnaire to help us improve
												future sessions for you!
											</>
										}
										type="info"
										showIcon
										onClick={this.openFeedbackForm}
										className={`${quizStyles.quizResultFeedbackForm}`}
										style={{ maxWidth: 'none', marginTop: '10px', marginBottom: '-10px' }}
									/>
								)}
								<div dangerouslySetInnerHTML={{ __html: course.quiz_questionnaire.description }} />
								<table className={quizStyles.quizTable}>
									<tr>
										<th>Quiz Detail</th>
										<th>Information</th>
									</tr>
									<tr>
										<td>Allowed Attempts</td>
										<td>
											{course.quiz_attempts}/{course.quiz_allowed_attempts}
										</td>
									</tr>
									<tr>
										<td>Total Questions</td>
										<td>{course.quiz_questions}</td>
									</tr>
									<tr>
										<td>Total Score</td>
										<td>{course.quiz_total_score}</td>
									</tr>
									<tr>
										<td>Passing Score</td>
										<td>{course.quiz_passing_score}</td>
									</tr>
									<tr>
										<td>Passing Percentage</td>
										<td>{course.quiz_passing_percentage}%</td>
									</tr>
								</table>
								<div className={`${quizStyles.quizBtnContainer} ${styles.eventDetail} ${styles.noBorder}`}>
									<span
										style={{ cursor: 'pointer' }}
										className={`btn_default ${styles.event_register_btn_orange}`}
										onClick={this.checkQuizEligibility}
									>
										Start Quiz
									</span>
								</div>
								<Modal
									open={this.state.showConfirm}
									title="Get Ready"
									onOk={this.handleOk}
									onCancel={this.handleCancel}
									footer={[
										<Button key="back" onClick={this.handleCancel}>
											Do it Later
										</Button>,
										<Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
											Start Quiz
										</Button>,
									]}
									centered={true}
									confirmLoading={true}
								>
									<p>
										Get ready! By clicking the "Start Quiz" button below, you will begin your quiz. Please ensure you have at least one hour of
										uninterrupted time and a stable internet connection.
									</p>
								</Modal>
							</>
						)}
					</SectionBody>
				</Row>
			</Dashboard>
		);
	}
}

export default withRouter(CourseCertify);
