import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({size = '50', margin = '200px auto', color = '#00c087'}) => {
    return (
        <div style={{width:"100%",textAlign: "center"}}>
            <Spin 
        	   size="large"
        	   indicator={
                    <LoadingOutlined 
                        style={{ 
                            fontSize: `${size}`, 
                            margin: `${margin}`, 
                            color: `${color}`
                        }}
                        spin
                    />
                }
            />
        </div>
    );
};

export default Loader;
