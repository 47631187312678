import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import MemberCardHeader from '../components/MemberCardPublicHeader'; 
import MemberCardOverview from '../components/MemberCardPublicOverview';
import styles from '../styles/member.module.css'

const PeerCard = ({peer}) => {
    return (
        <Link className={styles.memberCardPublic} to={`/member/${peer.member_id}`}>
            <MemberCardHeader 
                avatar={peer.avatar} 
                name={peer.name}
                title={peer.title}
                city={peer.city}
                country={peer.country}                        
                rate={peer.hourly_rate}
            />
            <MemberCardOverview 
                bio={peer.bio}
                skills={peer.skills}
                platforms={peer.platforms}
            />
        </Link>
    );
};

export default PeerCard;
