import React, { useEffect, useState, useRef } from "react";
import { Col, Divider, Row } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import PublicPeer from "../components/PublicPeer";
import Loader from "../components/Loader";
import pafla from "../api/pafla";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const MemberSignup = (props) => {
    const peersRef = useRef(null);
    const [peers, setPeers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [skill, setSkill] = useState([]);
    const [hourlyRate, sethourlyRate] = useState(null);
    const [nextPage, setNextPage] = useState(2);
    const [hasMorePeers, sethasMorePeers] = useState(true);

    useEffect(() => {
        if (!peers && (skill === undefined || skill.length == 0)) {
            getPeers(props);
        } else {
            getPeersbyAttributes();
        }
    }, [skill, hourlyRate]);

    const getPeers = async (props) => {
        await pafla.get(`public-peers`).then((response) => {
            let newPeers = response.data.data.items;

            setPeers(newPeers);

            setLoading(false);
        });
    };

    const getMorePeers = async (props) => {
        await pafla
            .get(`public-peers`, {
                params: {
                    skills: skill.join("+"),
                    hourly_rate: hourlyRate,
                    page: nextPage,
                },
            })
            .then((response) => {
                let newPeers = response.data.data.items;
                let { current_page, last_page } = response.data.data.pagination;

                if (nextPage > 1) {
                    setPeers((prevPeers) => [...prevPeers, ...newPeers]);
                } else {
                    setPeers(newPeers);
                }

                if (parseInt(current_page) === parseInt(last_page)) {
                    sethasMorePeers(false);
                } else {
                    setNextPage((nextPage) => nextPage + 1);
                }

                setLoading(false);
            });
    };

    const setPeersbySkills = (selected, selectedSkill) => {
        if (selected) {
            setSkill([...skill, selectedSkill]);
        } else {
            setSkill(skill.filter((value) => value !== selectedSkill));
        }
    };

    const setPeersbyHourlyRate = (selected, selectedRate) => {
        sethourlyRate(selectedRate);
    };

    const getPeersbyAttributes = async () => {
        await pafla
            .get(`peers`, {
                params: {
                    skills: skill.join("+"),
                    hourly_rate: hourlyRate,
                    page: 1,
                },
            })
            .then((response) => {
                let newPeers = response.data.data.items;
                setPeers(newPeers);
                setLoading(false);
                setNextPage(2);
            });
    };

    return (
        <Layout>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Our Voice - Pakistan Freelancers Association</title>
            </Helmet>
            <section id='main'>
                <Row justify='start'>
                    <Col
                        xs={{ span: 22 }}
                        md={{ span: 22 }}
                        style={{ margin: "0 auto" }}
                    >
                        {peers && (
                            <InfiniteScroll
                                dataLength={peers.length} //This is important field to render the next data
                                next={getMorePeers}
                                hasMore={hasMorePeers}
                                loader={<Loader margin='20px auto' />}
                            >
                                <Row gutter={{ xs: 0, md: 16, lg: 30 }}>
                                    {peers?.map((peer) => {
                                        return (
                                            <Col
                                                className='gutter-row'
                                                xs={{ span: 24 }}
                                                md={{ span: 8 }}
                                            >
                                                <PublicPeer
                                                    key={peer.id}
                                                    peer={peer}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </InfiniteScroll>
                        )}
                    </Col>
                </Row>
            </section>
        </Layout>
    );
};

export default MemberSignup;
