import React from 'react';
import styles from '../styles/signUp.module.css';
import { Col, Row, Typography} from 'antd';
import {HOME, IMAGE_URL} from '../helpers/constants';
import {Link} from 'react-router-dom';
import RegisterForm from '../components/auth/RegisterForm';
import LoginLeft from '../components/auth/LoginLeft';
import { Helmet } from "react-helmet"


const {Title, Text} = Typography;
const MemberSignup = props => {

    const setReferrer = () => {
        const { location } = props

        if (location) {
            if (location.state) {
                if (location.state.referrer) {
                    return location.state.referrer
                }
            }
        }
    }

    return (
        <div className={styles.sign_up}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Register - Pakistan Freelancers Association</title>
            </Helmet>
            <Row style={{width: '100%'}}>
                <LoginLeft/>
                <Col xs={{span: 24}} md={{span: 12}} className={styles.sign_up_left}>

                    <Link to={HOME}>
                        <img src={`${IMAGE_URL}logo.svg`} alt="" className={styles.auth_logo}/>
                    </Link>
                    <div className={styles.sign_up_form}>
                        <Title level={2} className={styles.heading}>Join PAFLA</Title>
                        {/*<Avatar.Group className={styles.avatar_group}>
                            {avatars.map(av => <Avatar
                                key={av}
                                size={50}
                                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
                            )}
                        </Avatar.Group>*/}
                        <Text>Become a Member of PAFLA to get excusive perks</Text>
                        <br/>
                        <br/>
                        <Text strong>
                            Already a member?
                            <Link to={{
                                    pathname: "/login",
                                    state: { referrer: setReferrer() }
                                }} className="text-green"> Sign In </Link>
                        </Text>
                        <RegisterForm/>
                    </div>

                </Col>
            </Row>
        </div>
    );
};

export default MemberSignup;
