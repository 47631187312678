import React from 'react';
import ReactPixel from 'react-facebook-pixel'
import Layout from '../../components/FreelanceFestGilgit/Layout'
import {IMAGE_URL} from '../../helpers/constants'
import Banner from '../../components/FreelanceFestGilgit/Banner'
import Overview from '../../components/FreelanceFestGilgit/Overview'
import Speakers from '../../components/FreelanceFestGilgit/Speakers'
import Schedule from '../../components/FreelanceFestGilgit/Schedule'
import Tickets from '../../components/FreelanceFestGilgit/Tickets'
import FAQs from '../../components/FreelanceFestGilgit/FAQs'
import Timer from '../../components/FreelanceFestGilgit/Timer'
import Venue from '../../components/FreelanceFestGilgit/Venue'
import Sponsors from '../../components/FreelanceFestGilgit/Sponsors'
import { Helmet } from "react-helmet"
import { Button } from 'antd'
import { Fade } from 'react-reveal'

class AboutUs extends React.Component {


    componentDidMount() {
        ReactPixel.track('ViewContent',
            {
                content_ids: ['2715521342086822'],
                eventref: 'fb_oea'
            }
        )
    }

    render() {
        return (
            <Fade center delay={200}>
                <Layout>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Freelance Fest Gilgit 2022 - A leap to the future</title>
                    </Helmet>
                    <Banner
                        title="The largest conference for freelancers in Pakistan"
                        text="Join us at the year’s largest conference where the world’s 4th fastest growing gig-economy gathers to connect with industry leaders and mentors"
                        image={`${IMAGE_URL}freelance-fest/logo.png`}
                        /*button={<Button type="primary" className="btn_default event-register-btn mb-5">
                            <a href='#packages'>Register Now</a>
                        </Button>}*/
                        width={[9, 14]}
                    />
                    <Overview sectionId="overview"/>
                    {/*<Timer/>*/}
                    <Speakers sectionId="speakers"/>
                    <Schedule sectionId="schedule"/>
                    <Venue sectionId="venue"/>
                    {/*<Tickets sectionId="packages"/>*/}
                    <Sponsors sectionId="sponsors"/>
                    <FAQs sectionId="faqs"/>
                </Layout>
            </Fade>
        )
    }
}

export default AboutUs
