import React, { useState, useEffect, useRef } from 'react';
import {
    PushpinOutlined
} from '@ant-design/icons';

import { Avatar, Divider, Typography } from 'antd';
import styles from '../styles/member.module.css'
import Hourlyrate from './Hourlyrate'

const { Text } = Typography;

const MemberProfile = (props) => {

    return (
        <>
            <div className={styles.memberProfileHeaderPublic}>
                <div className={styles.avatar}>
                    <Avatar
                        src={props.avatar}
                        size={{ xs: 80, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    />
                </div>
                <div className={styles.detail}>
                    <h2>{props.name}</h2>
                    <Text type="h3" ellipsis={true} className={styles.memberTitle}>{props.title}</Text>
                    <h5><PushpinOutlined /> {props.city}, {props.country}</h5>
                    <h3><Hourlyrate rate={props.rate}/></h3>
                </div>
            </div>
        </>
    );
};

export default MemberProfile;
