import React, { useState, useRef } from 'react';
import { Divider, Tag, Typography } from 'antd';
import { Markup } from 'interweave';
import styles from '../../styles/member.module.css'

const { Paragraph } = Typography;

const MemberProfile = (props) => {

    return (
        <>
            <div className={styles.memberEducation}>
                <div className={styles.overviewHead}>
                    <h2>Education</h2>
                </div>
                <p className={styles.institute}>{props.institute}</p>
                <p className={styles.study}>{props.degree} - {props.field_of_study}</p>
            </div>
            <Divider/>
        </>
    );
};

export default MemberProfile;
